<template>
  <div
    class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px"
  >
    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(1)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#status-1"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="InvoiceCounter"
        >
          {{ InvoiceCounter[1] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Новые</span
      >
    </div>

    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(2)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#nav-masters"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="InvoiceCounter"
        >
          {{ InvoiceCounter[2] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-2 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Выставлен</span
      >
    </div>

    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(3)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-ok"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="InvoiceCounter"
        >
          {{ InvoiceCounter[3] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >Оплачен</span
      >
    </div>

    <div
      class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
      @click="filterStatus(4)"
    >
      <div
        class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
      >
        <svg
          class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
        <h2
          class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300"
          v-if="InvoiceCounter"
        >
          {{ InvoiceCounter[4] }}
        </h2>
        <svg
          class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-11 group-hover:w-full group-hover:h-full transition-all duration-300"
        >
          <use xlink:href="@/assets/i.svg#bg-romb"></use>
        </svg>
      </div>
      <span
        class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
        >ОТКАЗЫ</span
      >
    </div>
  </div>

  <div class="pb-4 w-full flex flex-wrap items-center">
    <h2
      class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4"
    >
      <span class="w-full text-lg leading-none text-teal-700">
        {{ this.InvoiceList?.length }}
      </span>
    </h2>

    <div class="flex-2_0 mr-15px w-[200px] my-4">
      <input
        type="text"
        class="h-9 w-[100%] px-3 py-2 text-sm bg-slate-50"
        placeholder="Название"
        v-model="s.findme"
        @input="findInvoice(s)"
      />
    </div>

    <select
      class="my-2 px-4 h-9 min-w-[11rem] w-[11rem] mr-4 bg-slate-50"
      @change="findInvoice(s)"
      v-model="s.parent_id"
    >
      <option disabled="" selected="" value="">Направление</option>
      <option value="1">Тахографы</option>
      <option value="2">Мониторинг</option>
      <option value="3">Карты</option>
      <option value="4">Пропуски</option>
      <option value="5">Обучение</option>
    </select>

    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[9rem] w-[9rem] mr-4"
      type="date"
      v-model="s.start"
      @change="findInvoice(s)"
    />

    <input
      class="h-9 px-3 my-2 text-sm bg-slate-50 outline-none min-w-[9rem] w-[9rem] mr-4"
      type="date"
      v-model="s.end"
      @change="findInvoice(s)"
    />

    <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
      <select
        class="my-2 px-4 h-9 min-w-[11rem] w-[11rem] mr-4 bg-slate-50"
        @change="findInvoice(s)"
        v-model="s.manager"
      >
        <option disabled="" selected="" value="">Менеджер</option>
        <template v-for="m in selectManagers" :key="m">
          <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
        </template>
      </select>
    </template>

    <div class="flex py-4">
      <button class="v-btn bg-gray-500 hover:bg-gray-400" v-if="s.clear">
        <span class="v-btn_text" @click="Clear">Сброс</span>
      </button>
    </div>
  </div>
  <select v-model="itemsInPage" style="padding: 7px">
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <Paginator :count="pageCount" v-model="page" />

  <div
    v-if="InvoiceList?.length > 0"
    class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto"
  >
    <div class="grid grid-cols-17 h-10 v-bbgray px-4 uppercase items-center">
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        №
      </div>
      <div
        class="font-latobold cursor-pointer text-center hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        СОЗДАН
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-3"
      >
        Клиент
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-1"
      >
        Направление
      </div>
      <div
        class="font-latobold cursor-pointer text-center hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        ожидается<br />оплата
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        МЕНЕДЖЕР
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px] col-span-2"
      >
        СОЗДАТЕЛЬ
      </div>
      <div
        class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]"
      >
        СУММА
      </div>
    </div>
    <template v-for="item in PaginatedInvoiceList" :key="item">
      <a
        class="grid grid-cols-17 px-4 py-2 gap-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1 max-2xl:grid-cols-order_m max-2xl:auto-rows-[50px]"
        :class="'status-' + item.status"
        @click="showInvoice(item)"
      >
        <div class="flex w-28">
          <template v-if="item.violate || item.status == 4">
            <svg class="w-5 h-5 mr-2" :class="'fill-status-11'">
              <use xlink:href="@/assets/i.svg#status-11"></use>
            </svg>
          </template>
          <template v-else>
            <div class="w-5 h-5 mr-2">
              <svg
                v-if="item.status == 1"
                class="w-full h-full"
                :class="'fill-status-' + item.status"
              >
                <use xlink:href="@/assets/i.svg#status-1"></use>
              </svg>
              <svg
                v-if="item.status == 2"
                class="w-full h-full"
                :class="'fill-status-' + item.status"
              >
                <use xlink:href="@/assets/i.svg#nav-masters"></use>
              </svg>
              <svg
                v-if="item.status == 3"
                class="w-full h-full"
                :class="'fill-status-6'"
              >
                <use xlink:href="@/assets/i.svg#ico-ok"></use>
              </svg>
              <svg
                v-if="item.status == 4"
                class="w-full h-full fill-white"
                :class="'fill-status-' + item.status"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </div>
          </template>
          {{ item.invoice_number }}
        </div>

        <div class="flex justify-center col-span-2">
          <div v-if="item.pay_wait" class="flex items-center">
            <svg class="fill-gray-400 w-2 h-2 mr-1 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <div class="text-gray-400 text-xs">
              {{ moment(item.created_at).format("DD.MM.YYYY") }}
            </div>
          </div>
        </div>

        <div class="text-black flex w-full h-full items-center col-span-3">
          <span
            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
            :class="{
              'bg-status-1': item.status == 1,
              'bg-status-2': item.status == 2,
              'bg-status-6': item.status == 3,
              'bg-status-11': item.status == 4,
            }"
          >
            {{ item.cname?.slice(0, 1) }}
          </span>
          <div class="flex flex-col justify-center overflow-hidden pr-4">
            <span
              class="text-base font-semibold break-all max-h-10 leading-5"
              >{{ item.cname }}</span
            >
          </div>
        </div>

        <div
          class="flex flex-col font-semibold text-black text-xs tex-left max-h-16 col-span-1"
        >
          <span v-if="item.parent_id == 1">Тахографы</span>
          <span v-if="item.parent_id == 2">Мониторинг</span>
          <span v-if="item.parent_id == 3">Карты</span>
          <span v-if="item.parent_id == 4">Пропуски</span>
          <span v-if="item.parent_id == 5">Обучение</span>
        </div>

        <div class="flex justify-center col-span-2">
          <div v-if="item.pay_wait" class="flex items-center">
            <svg class="fill-gray-400 w-2 h-2 mr-1 rotate-45">
              <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
            <div class="text-gray-400 text-xs">
              {{ moment(item.pay_wait).format("DD.MM.YYYY") }}
            </div>
          </div>
        </div>

        <template v-if="!['Manager', 'Logist'].includes(role)">
          <div
            v-if="item.mname"
            class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
          >
            <div
              class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
              :class="'bg-status-' + item.status"
            >
              <IcoPic :pic="item.mpicture" :name="item.mname" />
            </div>
            <div class="flex flex-col justify-center overflow-hidden">
              <span class="text-[10px] text-gray-400 leading-none uppercase"
                >Менеджер</span
              >
              <span class="text-black overflow-hidden">{{ item.mname }}</span>
            </div>
          </div>
          <div
            v-else
            class="flex justify-start items-center col-span-2 max-m:col-span-2"
          >
            <span
              class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
            >
              <svg class="w-4 h-4 fill-white">
                <use xlink:href="@/assets/i.svg#ico-avatar"></use>
              </svg>
            </span>
          </div>
          <div
            v-if="item.wname"
            class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
          >
            <div
              class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden"
              :class="'bg-status-' + item.status"
            >
              <IcoPic :pic="item.wpicture" :name="item.wname" />
            </div>
            <div class="flex flex-col justify-center overflow-hidden">
              <span class="text-[10px] text-gray-400 leading-none uppercase"
                >Создатель</span
              >
              <span class="text-black overflow-hidden">{{ item.wname }}</span>
            </div>
          </div>

          <div
            v-else
            class="flex justify-start items-center col-span-2 max-m:col-span-2"
          >
            <span
              class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2"
            >
              <svg class="w-4 h-4 fill-white">
                <use xlink:href="@/assets/i.svg#ico-avatar"></use>
              </svg>
            </span>
          </div>
        </template>

        <div class="flex flex-col text-black text-xs tex-left max-h-16">
          <span v-if="item.sum">{{ item.sum }} ₽</span>
          <span v-else>не указано</span>
        </div>
      </a>
    </template>
  </div>
  <div v-else-if="s.loader" class="absolute top-60 left-0 flex-cc w-full">
    <img src="https://api.tahocrm.ru/images/loader.svg" />
  </div>
  <div
    v-else
    class="absolute top-60 left-0 flex-cc w-full text-slate-300 text-2xl text-center"
  >
    Нет данных
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Paginator from "@/components/ui/Paginator";

export default {
  name: "InvoiceList",

  components: {
    // LineIco
    Paginator,
  },

  data() {
    return {
      moment: moment,
      direction: { 1: "ВХОДЯЩИЙ", 2: "ИСХОДЯЩИЙ" },
      type: { 1: "ПЕРВИЧНЫЙ", 2: "ВТОРИЧНЫЙ" },
      detail: {
        101: 'Задача "Как дела"',
        102: 'Задача "Пролонгация"',
        103: "Контроль качества",
        104: "Агент-сотрудник",
      },
      channel: {
        1: "Интернет",
        2: "Рекомендация",
        3: "Агент",
        4: "Обзвон новой базы",
        15: "Обзвон новой базы",
        21: "Вторичная клиентская база",
        22: "Рассылка",
        23: "Агент-сотрудник",
        31: "Вторичная клиентская база",
      },
      page: 0,
      itemsInPage: 10,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "InvoiceList",
      "InvoiceCounter",
      "countInvoices",
      "selectManagers",
      "selectMasters",
    ]),
    PaginatedInvoiceList() {
      return this.InvoiceList.slice(
        this.page * this.itemsInPage,
        (this.page + 1) * this.itemsInPage
      );
    },
    pageCount() {
      return Math.ceil((this.InvoiceList?.length ?? 0) / this.itemsInPage);
    },
  },

  methods: {
    ...mapActions([
      "getInvoices",
      "addLead",
      "setLead",
      "showInvoice",
      "findInvoice",
      "statusInvoice",
      "find",
      "setSettings",
      "clearSettings",
      "fillInvoices",
    ]),

    filterStatus(status) {
      this.s.status = status;
      this.s.claim = "";
      this.s.doc = "";
      this.setSettings(this.s);
      this.statusInvoice(this.s);
    },

    filterPayStatus() {
      this.s.pay_status = 1;
      this.findInvoice(this.s);
    },

    filterClaim() {
      this.s.status = "";
      this.s.claim = 1;
      this.setSettings(this.s);
      this.statusInvoice(this.s);
    },

    filterDoc() {
      this.s.status = "";
      this.s.doc = 1;
      this.setSettings(this.s);
      this.statusInvoice(this.s);
    },

    filterTrans() {
      this.s.status = "";
      this.s.trans = 1;
      this.setSettings(this.s);
      this.statusInvoice(this.s);
    },

    Clear() {
      this.s.status = "";
      this.s.parent_id = "";
      this.s.manager = "";
      this.s.start = "";
      this.s.end = "";

      this.clearSettings();
      this.fillInvoices();
      this.getInvoices();
    },
  },

  mounted() {
    this.getInvoices();
  },
};
</script>

<style>
.bg-status-8 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 72 126 / var(--tw-bg-opacity));
}
.grid-cols-17 {
  grid-template-columns: repeat(14, minmax(0, 1fr));
}

@media (max-width: 1441px) {
  .font-semibold {
    font-size: 13px;
  }
}
</style>

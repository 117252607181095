<template>
  <template v-if="order?.id > 0">
    <OrderHistory />

    <div class="flex max-xl:flex-col">
      <div class="w-[400px] my-4 max-xl:mx-auto max-xl:max-w-full">
        <div
          v-if="order.pay == 1"
          class="mb-2 bg-status-1 text-white text-xl rounded-[4px] text-center p-2 font-latobold"
        >
          ВЗЯТЬ НАЛИЧНЫЕ
        </div>

        <div class="bg-white rounded-[4px]">
          <div class="p-4 v-bbgray">
            <a
              v-if="order.claim?.iid"
              class="mb-4 bg-black text-white text-center p-2 rounded-[4px] block cursor-pointer text-xl font-latobold hover:text-green-400 transition-colors duration-150"
              @click="showClaim(order.claim?.iid)"
            >
              РЕКЛАМАЦИЯ № {{ order.claim?.iid }}
            </a>

            <div class="flex items-center relative">
              <OrderStatus :order="order" />

              <template
                v-if="
                  order.sms?.code &&
                  ['Logist', 'Studio', 'Check'].includes(role)
                "
              >
                <div class="flex flex-col ml-4">
                  <span class="text-gray-400 text-[10px] uppercase">СМС</span>
                  <span class="font-latobold text-xl text-green-500">{{
                    order.sms?.code
                  }}</span>
                </div>
              </template>

              <div
                v-if="sts == 8 && order.kk?.status == 8"
                class="absolute top-1 -right-4 h-14 overflow-hidden max-w-[40%] pl-6 pr-4 flex items-center"
                @click="s.popup = 'qcheck'"
              >
                <svg class="w-7 h-7 fill-white z-2 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-rating"></use>
                </svg>
                <span class="text-white z-20 relative text-xl font-latobold">{{
                  order.qcheck
                }}</span>
                <svg
                  class="absolute top-0 -left-8 h-full"
                  :class="'!fill-status-6'"
                >
                  <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                </svg>
              </div>

              <div
                v-if="timer.time"
                class="absolute top-1 -right-4 h-14 overflow-hidden max-w-[50%] pl-6 pr-4 flex items-center"
              >
                <span class="text-white z-20 relative text-lg font-latobold">{{
                  timer.time
                }}</span>
                <svg
                  class="absolute top-0 -left-8 h-full"
                  :class="'!fill-status-' + timer?.color"
                >
                  <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                </svg>
              </div>
            </div>
          </div>

          <div
            v-if="['Logist', 'Studio', 'Check'].includes(role) && sts == 10"
            class="flex p-5 v-bbgray justify-between"
          >
            <button
              class="v-btn bg-gray-500 hover:bg-gray-400 flex items-center"
              @click="DelayOrder(0)"
            >
              <span class="v-btn_text">Нет</span>
            </button>
            <button
              class="v-btn bg-red-500 hover:bg-red-400 flex items-center"
              @click="DelayOrder(1)"
            >
              <span class="v-btn_text">Срочно</span>
            </button>
            <button
              class="v-btn bg-orange-500 hover:bg-orange-400 flex items-center"
              @click="DelayOrder(2)"
            >
              <span class="v-btn_text">Менеджер</span>
            </button>
            <button
              class="v-btn bg-green-500 hover:bg-green-400 flex items-center"
              @click="DelayOrder(3)"
            >
              <span class="v-btn_text">Клиент</span>
            </button>
          </div>

          <div class="flex p-5 v-bbgray">
            <div class="flex flex-col w-full">
              <p class="text-gray-400">НОМЕР СЧЕТА В CRM</p>
              <input
                type="text"
                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                v-model="order.number_crm"
                :class="{ 'binvoice-rose-300': !order.code }"
                @change="setOrderCRMNumber(order)"
              />
            </div>
          </div>

          <template v-if="['Manager', 'Logist', 'Studio'].includes(role)">
            <div class="flex p-5 v-bbgray">
              <div class="flex flex-col w-4/5 mr-2">
                <p class="text-gray-400">ЗАКАЗ 1C</p>
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  v-model="order.code"
                  :class="{ 'border-rose-300': !order.code }"
                  @change="setOrderCode(order)"
                />
              </div>
              <div class="flex flex-col w-4/5 ml-2">
                <p class="text-gray-400">СЧЕТ 1C</p>
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  v-model="order.codebill"
                  :class="{ 'border-rose-300': !order.codebill }"
                  @change="setOrderCodebill(order)"
                />
              </div>
            </div>
            <div class="flex p-5 v-bbgray">
              <div class="flex flex-col w-4/5 mr-2">
                <p class="text-gray-400">Реализация 1С</p>
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  v-model="order.s_one_realization"
                  :class="{ 'border-rose-300': !order.s_one_realization }"
                  @change="
                    setOrderField({
                      order: order,
                      field: 's_one_realization',
                      value: order.s_one_realization,
                    })
                  "
                />
              </div>
              <div class="flex flex-col w-4/5 ml-2">
                <p class="text-gray-400">Списание 1С</p>
                <input
                  type="text"
                  class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                  v-model="order.s_one_write_off"
                  :class="{ 'border-rose-300': !order.s_one_write_off }"
                  @change="
                    setOrderField({
                      order: order,
                      field: 's_one_write_off',
                      value: order.s_one_write_off,
                    })
                  "
                />
              </div>
            </div>
          </template>

          <div class="flex p-5 v-bbgray">
            <div class="flex items-center">
              <svg class="min-w-5 w-5 h-5 fill-gray-400 rotate-45 mr-2">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
              <div class="flex flex-col">
                <span class="text-gray-400 text-[10px] uppercase">СОЗДАН</span>
                <span class="font-latobold text-base text-gray-700">{{
                  moment(order.create_at).format("DD.MM.YYYY в HH:mm")
                }}</span>
              </div>
            </div>
          </div>

          <div class="p-5">
            <div
              v-if="order.address && order.zone_id && !editAddressMode"
              class="flex flex-col relative group"
            >
              <div
                class="absolute top-1/2 -translate-y-1/2 -translate-x-3"
                title="изменить"
              >
                <a
                  class="group cursor-pointer"
                  @click="
                    editAddressMode = true;
                    toggleBox('no');
                  "
                >
                  <svg class="fill-gray-400 w-4 h-4 hover:fill-blue-500">
                    <use xlink:href="@/assets/i.svg#ico-address"></use>
                  </svg>
                </a>
              </div>
              <div class="flex items-center">
                <div class="flex flex-col ml-4">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >Адрес выезда</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    order.address
                  }}</span>
                </div>
              </div>
              <div class="flex items-center mt-4">
                <div class="flex flex-col ml-4">
                  <span class="text-gray-400 text-[10px] uppercase"
                    >Геозона</span
                  >
                  <span class="font-latobold text-base text-gray-700">{{
                    selectZones.find((zone) => zone.id === order.zone_id)
                      ?.name ?? "неизвестная зона"
                  }}</span>
                </div>
              </div>
              <div class="flex items-center">
                <div class="flex flex-row ml-4">
                  <button
                    @click="
                      editAddressMode = true;
                      toggleBox('no');
                    "
                    class="v-btn v-btn_text mr-5 v-btn--sm bg-green-500 hover:bg-green-300 ml-auto"
                  >
                    изменить
                  </button>
                  <button
                    @click="delAddress"
                    class="v-btn v-btn_text v-btn--sm bg-red-500 hover:bg-red-300 ml-auto"
                  >
                    удалить
                  </button>
                </div>
              </div>
              <div
                v-if="
                  [
                    'Manager',
                    'Logist',
                    'Studio',
                    'Check',
                    'Assistant',
                  ].includes(role)
                "
                class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
              >
                <a
                  v-if="false"
                  class="group cursor-pointer"
                  @click="delAddress"
                  title="удалить"
                >
                  <svg
                    class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                </a>
              </div>
            </div>

            <div v-else-if="order.box" class="flex relative group">
              <div class="flex items-center">
                <svg class="w-5 h-5 fill-gray-400 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-address"></use>
                </svg>
                <div class="flex flex-col">
                  <span class="text-gray-400 text-[10px] uppercase">Бокс</span>
                  <span class="font-latobold text-base">{{
                    order.mybox?.name
                  }}</span>
                </div>
              </div>

              <div
                v-if="
                  [
                    'Manager',
                    'Logist',
                    'Studio',
                    'Check',
                    'Assistant',
                  ].includes(role)
                "
                class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
              >
                <a class="group cursor-pointer" @click="delAddress">
                  <svg
                    class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                </a>
              </div>
            </div>

            <template v-else>
              <template
                v-if="
                  [
                    'Manager',
                    'Logist',
                    'Studio',
                    'Check',
                    'Assistant',
                  ].includes(role)
                "
              >
                <div class="flex justify-around flex-wrap">
                  <div class="v-btn v-btn--sm bg-sky-400 hover:bg-sky-300">
                    <span
                      class="v-btn_text flex items-center"
                      @click="toggleBox('no')"
                      >На выезде</span
                    >
                  </div>
                  <div class="v-btn v-btn--sm bg-sky-400 hover:bg-sky-300">
                    <span
                      class="v-btn_text flex items-center"
                      @click="toggleBox('yes')"
                      >В боксе</span
                    >
                  </div>
                </div>

                <template v-if="isBox == 'no'">
                  <div class="flex items-center w-full mt-4">
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Адрес выезда
                    </p>
                    <div class="relative w-full">
                      <input
                        type="text"
                        class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                        :class="{ '!border-rose-300': !order.address }"
                        v-model="order.address"
                        @change="setAddress(order.address)"
                      />
                    </div>
                  </div>
                  <div class="flex items-center w-full mt-4">
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Геозона
                    </p>
                    <select
                      @change="setZone(order.zone_id)"
                      class="h-9 px-3 my-2 bg-slate-50 w-8/8 mr-4"
                      v-model="order.zone_id"
                    >
                      <option value="0">Без зоны</option>
                      <option
                        v-for="zone in selectZones"
                        :value="zone.id"
                        :key="zone.id"
                      >
                        {{ zone.name }}
                      </option>
                    </select>
                  </div>
                  <div v-if="editAddressMode" class="mt-5">
                    <button
                      @click="editAddressMode = false"
                      class="v-btn v-btn_text mx-auto v-btn--sm bg-blue-500 hover:bg-blue-300"
                    >
                      применить
                    </button>
                  </div>
                </template>

                <template v-if="isBox == 'yes'">
                  <div class="flex items-center w-full mt-4">
                    <p
                      class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                    >
                      Выберите бокс
                    </p>
                    <div class="relative w-full">
                      <input
                        type="text"
                        class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                        :class="{ '!border-rose-500': !order.box }"
                      />
                      <div
                        v-if="selectBoxes.length"
                        class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-50"
                      >
                        <div
                          class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                          v-for="box in selectBoxes"
                          :key="box"
                          @click="setBox(box)"
                        >
                          <span class="text-xs">{{ box.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>

              <div v-else class="flex items-center">
                <svg class="min-w-5 w-5 h-5 fill-gray-400">
                  <use xlink:href="@/assets/i.svg#ico-address"></use>
                </svg>
                <div class="flex flex-col ml-4">
                  <span class="text-red-500 text-[10px] uppercase"
                    >Адрес не указан!</span
                  >
                </div>
              </div>
            </template>
          </div>

          <!-- <div class="cont bb">
						<div class="flx flx-sb">
							<p v-if="order.sum == 0" class="b">ИТОГО 0</p>
							<div v-else class="preview p0">
								<span><svg class="mr10 svg20"><use xlink:href="@/assets/i.svg#pay-2"></use></svg></span>
								<h1>ИТОГО: {{ order.sum }} &#8381;</h1>
							</div>
						</div>
					</div> -->
        </div>

        <div class="rounded-[4px] bg-white mt-4 relative order-conn">
          <template v-if="order.client_id != null">
            <template v-if="order.client?.loader">
              <div class="flx flx-c">
                <img src="https://api.tahocrm.ru/images/loader.svg" />
              </div>
            </template>
            <template v-else>
              <div class="flex relative group miw-w-full p-5 v-bbgray">
                <a
                  class="flex items-center cursor-pointer group max-w-[75%]"
                  @click="showThisClient()"
                >
                  <div
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                  >
                    <span class="w-6 h-6 flex-cc">
                      <IcoPic
                        :pic="order.client?.picture"
                        :name="order.client?.name"
                      />
                    </span>
                  </div>
                  <div
                    class="flex flex-col justify-center overflow-hidden pr-8"
                  >
                    <span
                      class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                      >Клиент</span
                    >
                    <span
                      class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                      >{{ order.client?.name }}</span
                    >
                  </div>
                </a>

                <!-- <a v-if="order.client?.phone" class="rounded-full absolute top-1/2 -translate-y-1/2 right-6 w-7 h-7 hover:w-36 overflow-hidden flex items-center bg-green-500 transition-all duration-300 cursor-pointer">
                                    <svg class="w-4 h-4 ml-[6px] fill-white">
                                        <use xlink:href="@/assets/i.svg#ico-phone"></use>
                                    </svg>
                                    <span class="absolute left-7 p-1 text-white">{{ order.client?.phone }}</span>
                                </a> -->

                <div
                  v-if="
                    [
                      'Manager',
                      'Logist',
                      'Studio',
                      'Check',
                      'Assistant',
                    ].includes(role) && [1, 2, 3, 10].includes(sts)
                  "
                  class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="cursor-pointer" @click="dClient()">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <div class="p-5">
              <div
                v-if="
                  [
                    'Manager',
                    'Logist',
                    'Studio',
                    'Check',
                    'Assistant',
                  ].includes(role)
                "
                class="flex items-center w-full"
              >
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  Выберите клиента
                </p>
                <div class="flex-1 relative">
                  <input
                    type="text"
                    v-model.trim="text_client"
                    class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    @click="findOrderClient(text_client)"
                    @input="findOrderClient(text_client)"
                  />
                  <div
                    class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10 hidden"
                    :class="{ '!block': selectOrderClients?.length > 0 }"
                  >
                    <div
                      v-for="cl in selectOrderClients"
                      :key="cl"
                      @click="pickClient(cl)"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ cl.name }}</span>
                      <span class="text-xs">{{ cl.iid }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <p v-else class="text-rose-400 leading-none text-center">
                Клиент не добавлен!
              </p>
            </div>
          </template>

          <template v-if="order.client_id != null">
            <div class="p-5 v-bbgray flex items-center group relative">
              <template v-if="order.company_id != null">
                <template v-if="order.company?.loader">
                  <div class="flex">
                    <img src="https://api.tahocrm.ru/images/loader.svg" />
                  </div>
                </template>
                <template v-else>
                  <a
                    class="flex cursor-pointer group"
                    @click="showСompanyfromOrder(order.company)"
                  >
                    <div
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                    >
                      <span class="w-6 h-6 flex-cc">
                        <IcoPic
                          :pic="order.company?.picture"
                          :name="order.company?.name"
                        />
                      </span>
                    </div>
                    <div
                      class="flex flex-col justify-center overflow-hidden pr-8"
                    >
                      <span
                        class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                        >Компания</span
                      >
                      <span
                        class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                        >{{ order.company?.secondname }}
                        {{ order.company?.name }}</span
                      >
                    </div>
                  </a>
                  <div
                    v-if="
                      [
                        'Manager',
                        'Logist',
                        'Studio',
                        'Check',
                        'Assistant',
                      ].includes(role) &&
                      order.company_id &&
                      [1, 2, 3, 10, 11].includes(sts)
                    "
                    class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                  >
                    <a class="group cursor-pointer" @click="delOrderCompany()">
                      <svg
                        class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                    </a>
                  </div>
                </template>
              </template>
              <template v-else>
                <div
                  v-if="
                    [
                      'Manager',
                      'Logist',
                      'Studio',
                      'Check',
                      'Assistant',
                    ].includes(role) &&
                    selectOrderCompanies &&
                    selectOrderCompanies.length > 0
                  "
                  class="flex items-center w-full"
                >
                  <p
                    class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                  >
                    Выберите компанию
                  </p>
                  <div class="relative w-full">
                    <input
                      type="text"
                      v-model.trim="text_company"
                      class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      @input="findCompany()"
                      @click="findCompany()"
                    />
                    <div
                      v-if="companyList && companyList.length > 0"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="co in companyList"
                        :key="co"
                        @click="pickCompany(co)"
                        class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ co.secondname }} {{ co.name }}</span>
                        <span class="text-xs">{{ co.position }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p v-else class="text-rose-500 text-sm">Компания не выбрана!</p>
              </template>
            </div>

            <div class="p-5 v-bbgray flex items-center group relative">
              <template v-if="order.contact_id != null">
                <template v-if="order.contact?.loader">
                  <div class="flex">
                    <img src="https://api.tahocrm.ru/images/loader.svg" />
                  </div>
                </template>
                <template v-else>
                  <a
                    class="flex cursor-pointer group"
                    @click="showThisContact()"
                  >
                    <div
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                    >
                      <span class="w-6 h-6 flex-cc">
                        <IcoPic
                          :pic="order.contact?.picture"
                          :name="order.contact?.name"
                        />
                      </span>
                    </div>
                    <div
                      class="flex flex-col justify-center overflow-hidden pr-8"
                    >
                      <span
                        class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                        >Контакт</span
                      >
                      <span
                        class="font-latobold text-base cursor-pointer group-hover:text-green-500 transition-colors duration-300"
                        >{{ order.contact?.secondname }}
                        {{ order.contact?.name }}</span
                      >
                    </div>
                  </a>
                  <template v-if="order.contact?.phone">
                    <a
                      class="rounded-full absolute top-1/2 -translate-y-1/2 right-16 w-7 h-7 hover:w-40 overflow-hidden flex items-center bg-green-500 transition-all duration-300"
                      :href="'tel:' + order.contact.phone"
                    >
                      <svg class="w-4 h-4 ml-[6px] fill-white">
                        <use xlink:href="@/assets/i.svg#ico-phone"></use>
                      </svg>
                      <span class="absolute left-7 p-1 text-white">{{
                        order.contact?.phone
                      }}</span>
                    </a>
                  </template>
                  <div
                    v-if="
                      [
                        'Manager',
                        'Logist',
                        'Studio',
                        'Check',
                        'Assistant',
                      ].includes(role) &&
                      order.contact_id &&
                      [1, 2, 3, 10, 11, 12, 13].includes(sts)
                    "
                    class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                  >
                    <a class="group cursor-pointer" @click="delOrderContact()">
                      <svg
                        class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                    </a>
                  </div>
                </template>
              </template>
              <template v-else>
                <div
                  v-if="
                    [
                      'Manager',
                      'Logist',
                      'Studio',
                      'Check',
                      'Assistant',
                    ].includes(role) &&
                    selectOrderContacts &&
                    selectOrderContacts.length > 0
                  "
                  class="flex items-center w-full"
                >
                  <p
                    class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                  >
                    Выберите контакт
                  </p>
                  <div class="relative w-full">
                    <input
                      type="text"
                      v-model.trim="text_contact"
                      class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      @input="findContact()"
                      @click="findContact()"
                    />
                    <div
                      v-if="contactList && contactList.length > 0"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="co in contactList"
                        :key="co"
                        @click="pickContact(co)"
                        class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ co.secondname }} {{ co.name }}</span>
                        <span class="text-xs">{{ co.position }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p v-else class="text-rose-500 text-sm">Контакт не добавлен!</p>
              </template>
            </div>

            <div class="p-5 v-bbgray flex items-center group relative">
              <template v-if="order.ts_id">
                <template v-if="order.ts?.loader">
                  <div class="flx flx-c">
                    <img src="https://api.tahocrm.ru/images/loader.svg" />
                  </div>
                </template>
                <template v-else>
                  <a class="flex cursor-pointer group">
                    <div
                      class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center mr-4 uppercase"
                    >
                      <span class="text-xl text-white">{{
                        order.ts?.mark?.slice(0, 1)
                      }}</span>
                    </div>
                    <div
                      class="flex flex-col justify-center overflow-hidden pr-8"
                    >
                      <span
                        class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                        >{{ order.ts?.mark }}</span
                      >
                      <span
                        class="text-slate-600 w-max rounded-sm v-tsnum px-2 text-base v-gray font-latoblod-serif"
                        >{{ order.ts?.num }}</span
                      >
                    </div>
                  </a>

                  <div
                    v-if="
                      [
                        'Manager',
                        'Logist',
                        'Studio',
                        'Check',
                        'Assistant',
                      ].includes(role) &&
                      order.ts_id &&
                      [1, 2, 3, 10].includes(sts)
                    "
                    class="absolute top-1/2 -translate-y-1/2 right-2 opacity-0 group-hover:opacity-100 transition-all duration-150"
                  >
                    <a class="group cursor-pointer" @click="delOrderTs">
                      <svg
                        class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                    </a>
                  </div>
                </template>
              </template>
              <template v-else>
                <div
                  v-if="
                    [
                      'Manager',
                      'Logist',
                      'Studio',
                      'Check',
                      'Assistant',
                    ].includes(role) &&
                    selectOrderTs &&
                    selectOrderTs.length > 0
                  "
                  class="flex items-center w-full"
                >
                  <p
                    class="text-gray-400 leading-none w-full max-w-[100px] mr-6"
                  >
                    Выберите ТС
                  </p>
                  <div class="relative w-full">
                    <input
                      type="text"
                      v-model.trim="text_ts"
                      class="bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                      @input="findTs()"
                      @click="findTs()"
                    />
                    <div
                      v-if="tsList && tsList.length > 0"
                      class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                    >
                      <div
                        v-for="ts in tsList"
                        :key="ts"
                        @click="pickTs(ts)"
                        class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                      >
                        <span>{{ ts.mark?.slice(0, 9) }}</span>
                        <span class="text-xs">{{ ts.num }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p v-else class="text-rose-500 text-sm">ТС не добавлено!</p>
              </template>
            </div>
          </template>
        </div>

        <div id="times">
          <template v-if="order.dates">
            <div v-for="d in order.dates" :key="d" class="block cho">
              <template v-if="d.loader">
                <div class="flx flx-c">
                  <img src="https://api.tahocrm.ru/images/loader.svg" />
                </div>
              </template>
              <template v-else>
                <div
                  class="p-5 bg-white order-conn mt-4 relative rounded-[4px]"
                >
                  <div class="cursor-pointer group">
                    <div
                      class="flex items-center justify-around"
                      :class="{ red: needTime > 0 }"
                    >
                      <div class="flex">
                        <svg class="w-5 h-5 fill-gray-400">
                          <use xlink:href="@/assets/i.svg#nav-date"></use>
                        </svg>
                        <p class="font-latobold ml-2">
                          {{ moment(d.date).format("DD.MM.YYYY") }}
                        </p>
                      </div>
                      <div class="flex">
                        <svg class="w-5 h-5 fill-green-500">
                          <use xlink:href="@/assets/i.svg#ico-time"></use>
                        </svg>
                        <p class="font-latobold ml-2">
                          {{ d.start.slice(0, 5) }} - {{ d.end.slice(0, 5) }}
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="
                        d.start &&
                        [
                          'Manager',
                          'Logist',
                          'Studio',
                          'Check',
                          'Assistant',
                        ].includes(role) &&
                        [1, 2, 3, 10].includes(sts)
                      "
                      class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
                    >
                      <a class="group cursor-pointer" @click="delDate(d)">
                        <svg
                          class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                        >
                          <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div
                    v-if="needTime > 0"
                    class="mt-2 text-center text-rose-500"
                  >
                    Увеличьте время на {{ needTime }} минут
                  </div>
                  <div
                    v-if="needTime < 0"
                    class="mt-2 text-center text-cyan-500"
                  >
                    Можно уменьшить время на {{ -1 * needTime }} минут
                  </div>
                </div>
              </template>
            </div>
          </template>

          <template
            v-if="
              ['Manager', 'Logist', 'Studio'].includes(role) &&
              OrderCal?.length > 0
            "
          >
            <div class="p-5 bg-white order-conn mt-4 relative rounded-[4px]">
              <div class="flex">
                <a
                  class="flex items-center cursor-pointer group"
                  @click="s.popup = 'cal'"
                >
                  <svg
                    class="w-5 h-5 fill-green-500 group-hover:fill-green-400 transition-colors duration-300"
                  >
                    <use xlink:href="@/assets/i.svg#nav-date"></use>
                  </svg>
                  <p
                    class="text-green-500 ml-2 group-hover:text-green-400 transition-colors duration-300"
                    v-if="order.dates?.length > 0"
                  >
                    Добавить еще дату/время!
                  </p>
                  <p
                    class="text-green-500 ml-2 group-hover:text-green-400 transition-colors duration-300"
                    v-else
                  >
                    Выбрать дату!
                  </p>
                </a>
              </div>
            </div>
          </template>
        </div>
        <div class="bg-white order-conn mt-4 relative rounded-[4px]">
          <div
            v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)"
            class="flex relative group p-5 v-bbgray"
          >
            <div v-if="order.master_id" class="flex justify-between">
              <div class="flex items-center cursor-pointer group">
                <div
                  class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
                >
                  <span class="w-6 h-6">
                    <IcoPic
                      :pic="order.master?.picture"
                      :name="order.master?.name"
                    />
                  </span>
                </div>
                <div class="flex flex-col justify-center overflow-hidden pr-8">
                  <span
                    class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                    >Инженер</span
                  >
                  <span
                    class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                    >{{ order.master?.name }}
                    {{ order.master?.secondname }}</span
                  >
                </div>
              </div>
              <div
                v-if="canDelMaster"
                class="absolute top-1/2 -translate-y-1/2 right-8 opacity-0 group-hover:opacity-100 transition-all duration-150"
              >
                <a class="group cursor-pointer" @click="delMaster()">
                  <svg
                    class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                  >
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                  </svg>
                </a>
              </div>
            </div>

            <template v-else>
              <div class="flex items-center w-full">
                <p class="text-gray-400 leading-none w-full max-w-[100px] mr-6">
                  Инженер
                </p>
                <div class="relative w-full">
                  <input
                    type="text"
                    v-model.trim="text_master"
                    class="bg-white h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    @input="findMaster()"
                    @click="findMaster()"
                  />
                  <hr />
                  <div
                    v-if="masterList.length"
                    class="absolute w-full top-full bg-white left-0 border-2 border-green-800 border-t-0 z-10"
                  >
                    <div
                      @click="setMaster({ uid: 1 })"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span class="font-latobold">Отдать подрядчикам</span>
                    </div>
                    <div
                      v-for="m in masterList"
                      :key="m"
                      @click="setMaster(m)"
                      class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                    >
                      <span>{{ m.secondname }} {{ m.name }}</span>
                      <span class="text-xs">{{ m.uid }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div v-if="order.client?.manager_id" class="p-5 v-bbgray">
            <div class="flex items-center cursor-pointer group">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden"
              >
                <span class="w-6 h-6 opacity-30">
                  <IcoPic
                    :pic="order.client?.manager?.picture"
                    :name="order.client?.manager?.name"
                  />
                </span>
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-300 leading-none uppercase mb-1"
                  >ОСНОВНОЙ МЕНЕДЖЕР</span
                >
                <span class="font-latobold text-base text-gray-300"
                  >{{ order.client?.manager?.name }}
                  {{ order.client?.manager?.secondname }}</span
                >
              </div>
            </div>
          </div>

          <div v-if="order.manager" class="p-5 v-bbgray">
            <div class="flex items-center cursor-pointer group">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
              >
                <span class="w-6 h-6">
                  <IcoPic
                    :pic="order.manager?.picture"
                    :name="order.manager?.name"
                  />
                </span>
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >МЕНЕДЖЕР ЗАКАЗА</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                  >{{ order.manager?.name }}
                  {{ order.manager?.secondname }}</span
                >
              </div>
            </div>
          </div>

          <div v-if="order.logist_id" class="p-5 v-bbgray">
            <div class="flex items-center cursor-pointer group">
              <div
                class="min-w-[1.5rem] w-11 h-11 rounded-full mr-4 overflow-hidden bg-green-500"
              >
                <span class="w-6 h-6">
                  <IcoPic
                    :pic="order.logist?.picture"
                    :name="order.logist?.name"
                  />
                </span>
              </div>
              <div class="flex flex-col justify-center overflow-hidden pr-8">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >Логист</span
                >
                <span
                  class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                  >{{ order.logist?.name }} {{ order.logist?.secondname }}</span
                >
              </div>
            </div>
          </div>

          <!-- <div class="cont flx">
						<template v-if="order.marketing">
							<div class="preview cox60 col80 p0">
								<span class="avatardel">{{ order.marketing.slice(0, 1) }}</span>
								<div>
									<sup v-if="order.marketing.slice(0, 1) == 1">Маркетинг: Первичный</sup>
									<sup v-if="order.marketing.slice(0, 1) == 2">Маркетинг: Вторичный</sup>
									{{ marketing[order.marketing] }}
								</div>
							</div>
							<div v-if="order.marketing && [1, 2, 3, 10].includes(sts)" class="flx flx-end hov">
								<a class="flx" @click="delMarket()"
									><svg class="f-gray f-delet"><use xlink:href="@/assets/i.svg#ico-no"></use></svg
								></a>
							</div>
						</template>
						<template v-else>
							<div v-if="['Manager', 'Studio'].includes(role)" class="cox100 p0 fln flx flx-sb">
								<p class="c-gray cox30 p0">Маркетинг</p>
								<div class="select cox70 p0">
									<input
										type="text"
										v-model.trim="find"
										class="cox100"
										@input="findMarket()"
										@click="findMarket()"
									/>
									<div v-if="marketList.length" class="list db">
										<div v-for="market in marketList" :key="market" @click="setMarket(market)">
											{{ market.name }}
										</div>
									</div>
								</div>
							</div>
							<p v-else class="b c-red">Способ привлечения не указан</p>
						</template>
					</div> -->
        </div>
      </div>

      <div class="flex flex-col ml-4 mr-12 flex-1 mb-4 max-l:mx-0">
        <div
          v-if="
            ['Manager', 'Logist', 'Studio', 'Check', 'Assistant'].includes(
              role
            ) && showGarland == true
          "
          class="flex justify-between border-t-2 border-gray-400 w-[90%] mt-10 mb-1 mx-auto max-m:hidden"
        >
          <!-- <div
            class="flex flex-col items-center -mt-6"
            @click="showLeadById(order.lead_id)"
          >
            <span
              class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#nav-clients"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ЛИД</span
            >
          </div> -->

          <!-- <div class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 bg-green-500 flex items-center justify-center"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#status-1"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ЗАКАЗ</span
            >
          </div> -->

          <div class="flex flex-col items-center -mt-6 -ml-6 relative">
            <span
              @click="toggleGarl('showTouches')"
              class="rounded-full h-11 w-11 flex items-center justify-center"
              :class="{
                '!bg-status-5': newGarland.touches.length > 0,
                '!bg-gray-400': newGarland.touches.length == 0,
              }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#nav-clients"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >КАСАНИЕ</span
            >

            <div
              v-if="showTouches && newGarland.touches.length > 0"
              class="absolute-list"
            >
              <p class="font-latobold text-base text-gray-700">Касания</p>
              <a
                v-for="touch in newGarland.touches"
                :key="touch"
                @click="goToTouch(touch.id)"
                >№{{ touch.id }}</a
              >
            </div>
          </div>
          <div class="flex flex-col items-center -mt-6 relative">
            <span
              @click="toggleGarl('showLeads')"
              class="rounded-full h-11 w-11 bg-gray-400 flex items-center justify-center"
              :class="{
                '!bg-status-5': newGarland.leads.length > 0,
                '!bg-gray-400': newGarland.leads.length == 0,
              }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#status-1"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ЛИД</span
            >
            <div
              v-if="showLeads && newGarland.leads.length"
              class="absolute-list"
            >
              <p class="font-latobold text-base text-gray-700">
                Лиды из касания
              </p>
              <a
                @click="goToLead(lead.id)"
                v-for="lead in newGarland.leads"
                :key="lead"
                >№{{ lead.id }}</a
              >
            </div>
          </div>

          <div class="flex flex-col items-center -mt-6 relative">
            <span
              @click="toggleGarl('showZayavka')"
              class="rounded-full h-11 w-11 flex items-center justify-center"
              :class="{
                '!bg-status-5': newGarland.bids.length > 0,
                '!bg-gray-400': newGarland.bids.length == 0,
              }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#doc"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ЗАЯВКА</span
            >
            <div
              v-if="showZayavka && newGarland.bids.length"
              class="absolute-list"
            >
              <p class="font-latobold text-base text-gray-700">
                Заявки из касания
              </p>
              <a
                v-for="bid in newGarland.bids"
                @click="goToBid(bid.id)"
                :key="bid"
                >№{{ bid.id }}</a
              >
            </div>
          </div>

          <div class="flex flex-col items-center -mt-6 relative">
            <span
              @click="toggleGarl('showSchet')"
              class="rounded-full h-11 w-11 flex items-center justify-center"
              :class="{
                '!bg-status-5': newGarland.invoices.length > 0,
                '!bg-gray-400': newGarland.invoices.length == 0,
              }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#doc"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >СЧЕТ CRM</span
            >

            <div
              v-if="showSchet && newGarland.invoices.length"
              class="absolute-list"
            >
              <p class="font-latobold text-base text-gray-700">
                Счета из касания
              </p>
              <a
                @click="goToInvoice(invoice.id)"
                v-for="invoice in newGarland.invoices"
                :key="invoice"
                >№{{ invoice.id }}</a
              >
            </div>
          </div>

          <div v-if="anketa == 1" class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{ '!bg-status-5': order.act > 1 }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#doc"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ДОК-ТЫ</span
            >
          </div>

          <div v-if="anketa == 1" class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{
                '!bg-status-5': order.act == 8,
                '!bg-status-7': order.act == 7,
                '!bg-status-10': order.act == 3,
              }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#doc"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >АНКЕТА</span
            >
          </div>

          <div class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{ '!bg-status-5': order.codebill?.length }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#pay-2"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >СЧЕТ 1С</span
            >
          </div>

          <div class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{ '!bg-status-5': order.pay_status == 6 }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#pay-1"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ОПЛАЧЕН</span
            >
          </div>

          <div
            v-if="[10].includes(sts)"
            class="flex flex-col items-center -mt-6"
          >
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{ '!bg-status-5': sts == 10 }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#ico-time"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ОТЛОЖЕН</span
            >
          </div>

          <div class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{ '!bg-status-5': [3, 4, 5, 6, 7, 8].includes(sts) }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#status-3"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >НАЗНАЧЕН</span
            >
          </div>

          <div class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{ '!bg-status-5': [6, 7, 8].includes(sts) }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#status-6"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >ВЫПОЛНЕН</span
            >
          </div>

          <div class="flex flex-col items-center -mt-6">
            <span
              class="rounded-full h-11 w-11 flex items-center justify-center bg-gray-400"
              :class="{ '!bg-status-5': sts == 8 }"
            >
              <svg class="w-5 h-5 fill-white">
                <use xlink:href="@/assets/i.svg#status-8"></use>
              </svg>
            </span>
            <span class="text-[10px] text-gray-400 leading-none uppercase mt-1"
              >КК</span
            >
          </div>
        </div>

        <div
          v-if="['Studio', 'Director'].includes(role) && penaltys != null"
          class="p-5 bg-white mt-4 rounded-[4px] flex justify-between height-penaltys"
        >
          <div class="flex w-full justify-between">
            <div
              v-for="penalty in penaltys"
              :key="penalty.id"
              class="relative cursor-pointer text-center uppercase group w-28 h-28 mx-auto transition-all duration-300 ease-in-out"
              @click="click"
            >
              <div
                class="overflow-hidden w-full h-full transition duration-300 ease-in-out"
              >
                <svg
                  class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto transition-all duration-300"
                >
                  <use
                    :xlink:href="require('@/assets/i.svg') + '#' + penalty.ico"
                  ></use>
                </svg>
                <h2
                  class="text-2xl z-2 relative mt-1 text-white transition-all duration-300"
                >
                  {{ showNum(penalty.fact) }}
                </h2>
                <svg
                  class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 transition-all duration-300"
                  :class="penalty.back"
                >
                  <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
              </div>
              <div
                class="duration-300 left-0 w-full opacity-100 text-status-20 font-latobold"
              >
                <p class="label-shit">{{ penalty.label }}</p>
                <p
                  class="sign-shit"
                  :class="showSign(penalty.fact - penalty.plan)"
                >
                  {{ showNum(penalty.fact - penalty.plan) }} <span>дн.</span>
                </p>
                <p class="plan-shit">
                  ПЛАН: {{ showNum(penalty.plan) }} <span>дн.</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="['Studio', 'Director'].includes(role) && penaltys == null"
          class="p-5 bg-white mt-4 rounded-[4px] flex justify-between"
        >
          <div class="flex items-center">
            <svg class="w-5 h-5 mr-4">
              <use xlink:href="@/assets/i.svg#ico-timer"></use>
            </svg>
            <div class="text-base font-latobold">Узнать длину сделки</div>
          </div>
          <button
            @click="getPenaltys()"
            class="v-btn v-btn--sm bg-orange-500 hover:bg-orange-400 ml-auto"
          >
            <span class="v-btn_text flex items-center">
              <span>Длина сделки</span>
            </span>
          </button>
        </div>

        <div class="bg-white rounded-[4px] mt-4">
          <template v-if="order.prices">
            <div
              v-for="p in order.prices"
              :key="p"
              class="flex p-5 items-center v-bbgray justify-between"
            >
              <template v-if="p.loader">
                <div class="flex">
                  <img src="https://api.tahocrm.ru/images/loader.svg" />
                </div>
              </template>
              <template v-else>
                <template
                  v-if="
                    ['Manager', 'Logist', 'Studio', 'Finance'].includes(role)
                  "
                >
                  <div class="max-w-[50%] w-full flex items-center">
                    <span
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4 overflow-hidden"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#nav-service"></use>
                      </svg>
                    </span>
                    <span class="text-base font-latobold">{{
                      p.price.name
                    }}</span>
                  </div>
                  <div class="flex items-center">
                    <svg class="w-5 h-5 mr-4">
                      <use xlink:href="@/assets/i.svg#ico-timer"></use>
                    </svg>
                    <div class="text-base font-latobold">
                      {{ p.price.time }}
                    </div>
                  </div>

                  <div class="text-base font-latobold">
                    {{ p.sum }}&nbsp;&#8381;
                  </div>

                  <template v-if="[1, 2, 3, 4, 10].includes(sts)">
                    <div class="flex">
                      <!-- <a
                        class="mr-4 group cursor-pointer"
                        @click="(s.popup = 'price'), (editPrice = p)"
                      >
                        <svg
                          class="fill-gray-400 w-4 h-4 group-hover:fill-green-500 transition-colors duration-300"
                        >
                          <use xlink:href="@/assets/i.svg#ico-edit"></use>
                        </svg>
                      </a> -->

                      <a class="group cursor-pointer" @click="delOrderPrice(p)">
                        <svg
                          class="fill-gray-400 w-4 h-4 group-hover:fill-rose-500 transition-colors duration-300"
                        >
                          <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                      </a>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="flex items-center">
                    <span
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4 overflow-hidden"
                    >
                      <svg class="w-5 h-5 fill-white">
                        <use xlink:href="@/assets/i.svg#nav-service"></use>
                      </svg>
                    </span>
                    <span class="text-base font-latobold">{{
                      p.price.name
                    }}</span>
                  </div>
                </template>
              </template>
            </div>
          </template>

          <template
            v-if="
              ['Manager', 'Logist', 'Studio', 'Check', 'Assistant'].includes(
                role
              ) && [1, 2, 3, 10, 12, 13].includes(sts)
            "
          >
            <div class="p-5 v-bbgray flex items-center">
              <p class="text-gray-400 leading-none max-w-[25%] w-full">
                Добавить услугу
              </p>
              <div class="flex flex-1 h-9 relative">
                <input
                  type="text"
                  v-model.trim="text_price"
                  class="w-full bg-white v-bgray"
                  @input="findPrice"
                  @click="findPrice"
                />

                <div
                  v-if="priceList.length"
                  class="absolute max-h-[350px] overflow-y-auto w-full top-full bg-white left-0 border-2 border-green-800 border-t-0"
                >
                  <div
                    v-for="p in priceList"
                    :key="p.id"
                    @click="pickPrice(p)"
                    class="px-4 py-2 flex justify-between hover:text-green-500 cursor-pointer hover:shadow-md transition-all duration-300"
                  >
                    <span>{{ p.name }}</span>
                    <span class="text-xs">{{ p.sum }}₽</span>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="order.codebill">
              <template v-if="order.pay > 0">
                <template v-if="order.pay_status == 6">
                  <div class="flex p-5 items-center v-bbgray justify-between">
                    <p class="text-gray-400 leading-none max-w-[25%] w-full">
                      Дата оплаты
                    </p>
                    <span
                      v-if="order.pay_date"
                      class="text-base font-latobold mr-8"
                    >
                      {{ moment(order.pay_date).format("DD.MM.YYYY") }}
                    </span>

                    <span class="text-base font-latobold" v-if="order.pay == 1"
                      >Наличные взять</span
                    >
                    <span class="text-base font-latobold" v-if="order.pay == 2">
                      Наличные предоплата
                    </span>
                    <span class="text-base font-latobold" v-if="order.pay == 4"
                      >Безнал предоплата</span
                    >
                    <span class="text-base font-latobold" v-if="order.pay == 7"
                      >С отсрочкой</span
                    >
                  </div>
                </template>
                <template v-else>
                  <div class="flex p-5 items-center v-bbgray justify-between">
                    <p class="text-gray-400 leading-none max-w-[25%] w-full">
                      Дата ожидаемой оплаты
                    </p>
                    <span
                      v-if="order.pay_wait"
                      class="text-base font-latobold mr-8"
                    >
                      {{ moment(order.pay_wait).format("DD.MM.YYYY") }}
                    </span>

                    <span class="text-base font-latobold" v-if="order.pay == 1"
                      >Наличные взять</span
                    >
                    <span class="text-base font-latobold" v-if="order.pay == 2">
                      Наличные предоплата
                    </span>
                    <span class="text-base font-latobold" v-if="order.pay == 4"
                      >Безнал предоплата</span
                    >
                    <span class="text-base font-latobold" v-if="order.pay == 7"
                      >С отсрочкой</span
                    >

                    <!-- <a class="group cursor-pointer" @click="setOrderPay(0)">
                      <svg
                        class="fill-gray-400 w-4 h-4 group-hover:fill-rose-500 transition-colors duration-300"
                      >
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                      </svg>
                    </a> -->
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="flex px-5 py-3 items-center max-l:flex-wrap">
                  <div class="flex justify-center flex-wrap max-l:w-full">
                    <p
                      class="text-base font-latobold text-rose-500 py-2 leading-none w-full"
                    >
                      Оплата не выбрана
                    </p>
                    <!-- <a
                      class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                      :class="{
                        '!bg-gray-500 hover:!bg-gray-400': order.pay != 1,
                      }"
                      @click="setOrderPay(1)"
                    >
                      <span class="v-btn_text flex items-center">
                        <svg class="fill-white w-16px h-16px mr-3">
                          <use xlink:href="@/assets/i.svg#pay-1"></use>
                        </svg>
                        <span>Наличные взять</span>
                      </span>
                    </a>
                    <a
                      class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                      :class="{
                        '!bg-gray-500 hover:!bg-gray-400': order.pay != 4,
                      }"
                      @click="setOrderPay(4)"
                    >
                      <span class="v-btn_text flex items-center">
                        <svg class="fill-white w-16px h-16px mr-3">
                          <use xlink:href="@/assets/i.svg#pay-2"></use>
                        </svg>
                        <span>Безнал предоплата</span>
                      </span>
                    </a>
                    <a
                      class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                      :class="{
                        '!bg-gray-500 hover:!bg-gray-400': order.pay != 2,
                      }"
                      @click="setOrderPay(2)"
                    >
                      <span class="v-btn_text flex items-center">
                        <svg class="fill-white w-16px h-16px mr-3">
                          <use xlink:href="@/assets/i.svg#pay-1"></use>
                        </svg>
                        <span>Наличные предоплата</span>
                      </span>
                    </a>
                    <a
                      class="w-max v-btn v-btn--md bg-green-500 hover:bg-green-400 m-2"
                      :class="{
                        '!bg-gray-500 hover:!bg-gray-400': order.pay != 7,
                      }"
                      @click="setOrderPay(7)"
                    >
                      <span class="v-btn_text flex items-center">
                        <svg class="fill-white w-16px h-16px mr-3">
                          <use xlink:href="@/assets/i.svg#pay-3"></use>
                        </svg>
                        <span>С отсрочкой</span>
                      </span>
                    </a> -->
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>

        <div v-if="order.operation" class="p-5 bg-white rounded-[4px] mt-4">
          <div
            class="flex justify-between cursor-pointer group"
            @click="showOper"
          >
            <a class="flex items-center">
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
              >
                <svg class="fill-white w-5 h-5">
                  <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
              </span>
              <span
                class="text-base font-latobold uppercase group-hover:text-status-1 transition-colors duration-150"
              >
                Заказ-Наряд
              </span>
            </a>
            <div class="flex">
              <ul class="flex flex-wrap">
                <li
                  v-for="i in order.oper?.images"
                  :key="i"
                  class="w-10 h-10 mr-2 overflow-hidden rounded-[4px]"
                >
                  <img
                    class="w-full h-full object-cover"
                    :src="imagePrefix + 'oper/mini/' + i.img"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="p-5 bg-white rounded-[4px] mt-4">
          <div class="flex items-center max-l:flex-wrap">
            <div class="flex items-center max-l:flex-wrap">
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
              >
                <svg class="fill-white w-5 h-5">
                  <use xlink:href="@/assets/i.svg#ico-timer"></use>
                </svg>
              </span>
              <span class="text-base font-latobold uppercase">Задачи: </span>
              <div v-for="item in order.tasks" :key="item" class="preview ml15">
                <!-- <IcoPic :pic="item.person?.picture" :name="item.person?.name" /> -->
                <a
                  class="ml-2 text-base font-latobold cursor-pointer hover:text-green-500 transition-colors duration-300"
                  @click="showTask(item)"
                  >№{{ item.iid }}</a
                >
              </div>
            </div>

            <button
              class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 ml-auto"
            >
              <span
                class="v-btn_text flex items-center"
                @click="addTask({ object: 'order', oid: order.id })"
              >
                <svg
                  class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
                >
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <span>ЗАДАЧА</span>
              </span>
            </button>
          </div>
        </div>
        <template
          v-if="sts == 1 && order.client && order.activation?.status == 2"
        >
          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div
              class="flex items-center max-l:flex-wrap"
              @click="showActivfromOrder(order.activation)"
            >
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-10 flex items-center justify-center mr-4"
              >
                <svg class="fill-white w-5 h-5">
                  <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
              </span>
              <span
                v-if="this.penaltys != null"
                class="text-base font-latobold uppercase"
                >АНКЕТА АКТИВАЦИИ ЖДЕТ ПРОВЕРКИ ({{
                  this.penaltys.mintrans.count_change
                }})</span
              >
              <span v-else class="text-base font-latobold uppercase"
                >АНКЕТА АКТИВАЦИИ ЖДЕТ ПРОВЕРКИ</span
              >
            </div>
          </div>
        </template>

        <template v-if="order.activation?.status == 3">
          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div
              class="flex items-center max-l:flex-wrap"
              @click="showActivfromOrder(order.activation)"
            >
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-10 flex items-center justify-center mr-4"
              >
                <svg class="fill-white w-5 h-5">
                  <use xlink:href="@/assets/i.svg#status-6"></use>
                </svg>
              </span>
              <span
                v-if="this.penaltys != null"
                class="text-base font-latobold uppercase"
                >АНКЕТА ЖДЕТ ОТПРАВКИ В МИНТРАНС ({{
                  this.penaltys.mintrans.count_change
                }})</span
              >
              <span v-else class="text-base font-latobold uppercase"
                >АНКЕТА ЖДЕТ ОТПРАВКИ В МИНТРАНС</span
              >
            </div>
          </div>
        </template>

        <template v-if="order.activation?.status == 4">
          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div
              class="flex items-center max-l:flex-wrap"
              @click="showActivfromOrder(order.activation)"
            >
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-1 flex items-center justify-center mr-4"
              >
                <svg class="fill-white w-5 h-5">
                  <use xlink:href="@/assets/i.svg#ico-mail"></use>
                </svg>
              </span>
              <span
                v-if="this.penaltys != null"
                class="text-base font-latobold uppercase"
                >АНКЕТА В МИНТРАНСЕ ({{
                  this.penaltys.mintrans.count_change
                }})</span
              >
              <span v-else class="text-base font-latobold uppercase"
                >АНКЕТА В МИНТРАНСЕ</span
              >
            </div>
          </div>
        </template>

        <template v-if="order.activation?.status == 7">
          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div
              class="flex items-center max-l:flex-wrap"
              @click="showActivfromOrder(order.activation)"
            >
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-7 flex items-center justify-center mr-4"
              >
                <svg class="fill-white w-5 h-5">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
              </span>
              <span
                v-if="this.penaltys != null"
                class="text-base font-latobold uppercase"
                >АНКЕТА ОТКЛОНЕНА И ТРЕБУЕТ ДОРАБОТКИ ({{
                  this.penaltys.mintrans.count_change
                }})</span
              >
              <span v-else class="text-base font-latobold uppercase"
                >АНКЕТА ОТКЛОНЕНА И ТРЕБУЕТ ДОРАБОТКИ</span
              >
            </div>
          </div>
        </template>

        <template v-if="order.activation?.status == 8">
          <div class="p-5 bg-white rounded-[4px] mt-4">
            <div
              class="flex items-center max-l:flex-wrap"
              @click="showActivfromOrder(order.activation)"
            >
              <span
                class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-8 flex items-center justify-center mr-4"
              >
                <svg class="fill-white w-5 h-5">
                  <use xlink:href="@/assets/i.svg#status-8"></use>
                </svg>
              </span>
              <span
                v-if="this.penaltys != null"
                class="text-base font-latobold uppercase"
                >АНКЕТА ОДОБРЕНА ({{
                  this.penaltys.mintrans.count_change
                }})</span
              >
              <span v-else class="text-base font-latobold uppercase"
                >АНКЕТА ОДОБРЕНА</span
              >
            </div>
          </div>

          <template v-if="order.category == 2">
            <div class="p-5 bg-white rounded-[4px] mt-4">
              <template v-if="parseInt(order.ts?.monitoring) != 2">
                <template v-if="role == 'Master'">
                  <div class="flex flex-wrap">
                    <span
                      class="w-full text-base text-center font-latobold uppercase"
                      >МОНИТОРИНГ</span
                    >
                    <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
                      <label :class="{ 'text-red-500': !order.ts?.sim }"
                        >№ сим карты</label
                      >
                      <input
                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                        type="text"
                        :class="{ 'border-rose-300': !order.ts?.sim }"
                        v-model="order.ts.sim"
                      />
                    </div>
                    <div class="w-1/4 flex flex-col px-4 my-2 max-m:w-full">
                      <label :class="{ 'text-red-500': !order.ts?.operator }"
                        >Оператор</label
                      >
                      <select
                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                        :class="{ 'border-rose-300': !order.ts?.operator }"
                        v-model="order.ts.operator"
                      >
                        <option value="">Выбрать</option>
                        <option :value="'М2М'">М2М</option>
                        <option :value="'БИЛАЙН'">БИЛАЙН</option>
                        <option :value="'МТС'">МТС</option>
                        <option :value="'МЕГАФОН'">МЕГАФОН</option>
                      </select>
                    </div>
                  </div>

                  <div class="flex justify-end">
                    <span
                      class="v-btn v-btn_text mr-4 flex items-center bg-green-500 hover:bg-green-400"
                      @click="
                        onMonitoring({
                          order_id: order.id,
                          car_id: order.ts?.id,
                          operator: order.ts?.operator,
                          sim: order.ts?.sim,
                        })
                      "
                    >
                      <svg
                        class="fill-white w-16px h-16px mr-3 duration-100 transition ease-linear"
                      >
                        <use xlink:href="@/assets/i.svg#ico-ok"></use>
                      </svg>
                      Подключить
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div class="flex items-center max-l:flex-wrap">
                    <span
                      class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-11 flex items-center justify-center mr-4"
                    >
                      <svg class="fill-white w-5 h-5">
                        <use xlink:href="@/assets/i.svg#sputnik"></use>
                      </svg>
                    </span>
                    <span class="text-base font-latobold uppercase"
                      >ТС НЕ НА МОНИТОРИНГЕ</span
                    >
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="flex items-center max-l:flex-wrap">
                  <span
                    class="rounded-full h-11 min-w-[2.75rem] w-11 bg-status-8 flex items-center justify-center mr-4"
                  >
                    <svg class="fill-white w-5 h-5">
                      <use xlink:href="@/assets/i.svg#sputnik"></use>
                    </svg>
                  </span>
                  <span class="text-base font-latobold uppercase"
                    >ТС НА МОНИТОРИНГЕ</span
                  >
                </div>
              </template>
            </div>
          </template>
        </template>

        <template
          v-if="
            order.explain?.status == 1 ||
            (order.explain?.status == 2 &&
              ['Logist', 'Master', 'Studio', 'Check'].includes(role))
          "
        >
          <div class="bg-white p-5 mt-4 rounded-[4px]">
            <div v-if="order.explain?.status == 2" class="flex items-center">
              <div
                class="min-w-[1.5rem] w-7 h-7 rounded-full bg-rose-500 flex items-center justify-center"
              >
                <svg class="w-4 h-4 fill-white">
                  <use xlink:href="@/assets/i.svg#status-11"></use>
                </svg>
              </div>
              <span class="ml-4 text-xs text-gray-800 font-latobold"
                >Причина: {{ order.explain?.info }}</span
              >
            </div>

            <div
              v-if="order.explain?.status == 1 && ['Master'].includes(role)"
              class="cont explain"
            >
              <div class="flex flex-col justify-center">
                <h3 class="text-red-500 text-center">
                  Нарушен регламент, укажите причину
                </h3>
                <!-- <h3 class="text-red-500 text-center">{{ order.explain?.count }}</h3> -->
                <select
                  class="w-full px-4 py-2 bg-white v-bgray rounded-md mb-3"
                  v-model="explaintext"
                >
                  <option value="">Выбрать</option>
                  <option value="клиент опоздал ">клиент опоздал</option>
                  <option value="не работал армп/сайт/арм ">
                    не работал армп/сайт/арм
                  </option>
                  <option value="согласование доп услуги ">
                    согласование доп услуги
                  </option>
                  <option value="долгая активация блока ">
                    долгая активация блока
                  </option>
                  <option value="несколько ТС, давали не по порядку ">
                    несколько ТС, давали не по порядку
                  </option>
                  <option
                    value="инженер забыл нажать на кнопку начать/завершить "
                  >
                    инженер забыл нажать на кнопку начать/завершить
                  </option>
                  <option value="инженер опоздал (ПРОБКИ) ">
                    инженер опоздал (ПРОБКИ)
                  </option>
                  <option value="инженер начал раньше ">
                    инженер начал раньше
                  </option>
                  <option value="не доходят смс ">не доходят смс</option>
                  <option value="Другое: ">Другое</option>
                </select>
                <textarea
                  type="text"
                  class="w-full px-4 py-2 bg-white v-bgray rounded-md"
                  v-model="explaintext"
                ></textarea>
                <button
                  class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 ml-auto mt-3"
                  @click="
                    sendExplain({ id: order.explain.id, text: explaintext })
                  "
                >
                  <span class="v-btn_text flex items-center">
                    <svg class="fill-white w-16px h-16px mr-3">
                      <use xlink:href="@/assets/i.svg#ico-telegram"></use>
                    </svg>
                    <span>ОТПРАВИТЬ</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </template>

        <div v-if="!otkaz" class="p-5 bg-white mt-4 rounded-[4px]">
          <CommentList
            :id="parseInt(order.id)"
            :iid="parseInt(order.iid)"
            type="order"
          />
        </div>

        <div
          v-if="otkaz || order.status == 11"
          class="p-5 bg-white mt-4 rounded-[4px]"
        >
          <div v-if="order.status == 11" class="flex items-center">
            <div
              class="min-w-[1.5rem] w-7 h-7 rounded-full bg-rose-500 flex items-center justify-center mr-4"
            >
              <svg class="w-4 h-4 fill-white">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
            </div>
            <div>
              <span class="blocktext-xs text-gray-800 font-latobold">{{
                cancel[order.cancel_id]
              }}</span>
              <span
                v-if="order.comment"
                class="blocktext-xs text-gray-800 font-latobold block"
                >{{ order.comment }}</span
              >
            </div>
          </div>

          <div v-else class="flex flex-col justify-center">
            <h3 class="text-red-500 text-center">Укажите причину отказа</h3>
            <select
              class="w-full px-4 py-2 bg-white v-bgray rounded-md mb-3"
              v-model="cancel_id"
            >
              <option value="0" disabled selected>Выбрать причину</option>
              <option v-for="(val, key) in cancel" :value="key" :key="val">
                {{ val }}
              </option>
            </select>
            <textarea
              type="text"
              class="w-full px-4 py-2 bg-white v-bgray rounded-md"
              v-model="order.comment"
              placeholder="Комментарий"
            ></textarea>
            <button
              v-if="parseInt(cancel_id) > 0"
              class="v-btn v-btn--sm v-btn--sm bg-green-500 hover:bg-green-400 ml-auto mt-3"
              @click="
                cancelOrder({ cancel_id: cancel_id, comment: order.comment })
              "
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-16px h-16px mr-3">
                  <use xlink:href="@/assets/i.svg#ico-telegram"></use>
                </svg>
                <span>ОТПРАВИТЬ</span>
              </span>
            </button>
          </div>
        </div>

        <div class="flex justify-around mt-4">
          <template v-if="['Manager', 'Logist', 'Studio'].includes(role)">
            <button
              v-if="[1, 3, 4, 6, 8, 10].includes(sts)"
              class="v-btn v-btn--sm bg-amber-500 hover:bg-amber-400"
              @click="copyOrder(order.id)"
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-16px h-16px mr-3">
                  <use xlink:href="@/assets/i.svg#copy"></use>
                </svg>
                <span>Копия</span>
              </span>
            </button>
          </template>

          <template v-if="['Check', 'Studio'].includes(role)">
            <button
              v-if="sts == 8"
              class="v-btn v-btn--sm bg-green-500 hover:bg-green-400"
              @click="addOrderLead()"
            >
              <svg
                class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear"
              >
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
              <span class="v-btn_text">Создать лид</span>
            </button>
          </template>

          <!-- <template v-if="['Manager', 'Logist', 'Studio'].includes(role)">
                        <button
                            v-if="[3, 4, 10, 12, 13].includes(sts)"
                            class="v-btn v-btn--sm bg-sky-500 hover:bg-sky-400 mr-0"
                            @click="NewStatusOrder()"
                        >
                            <span class="v-btn_text flex items-center">
                                <svg class="fill-white w-16px h-16px mr-3">
                                    <use xlink:href="@/assets/i.svg#status-1"></use>
                                </svg>
                                <span>НОВЫЙ</span>
                            </span>
                        </button>
                    </template> -->

          <template
            v-if="
              ['Manager', 'Studio'].includes(role) &&
              anketa > 0 &&
              order.act < 1
            "
          >
            <button
              v-if="[1, 3, 10].includes(sts)"
              class="v-btn v-btn--sm bg-green-500 hover:bg-green-400 mr-0"
              @click="AnketaOrder()"
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-16px h-16px mr-3">
                  <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
                <span>АНКЕТА</span>
              </span>
            </button>
          </template>

          <!-- <template v-if="['Manager', 'Logist', 'Studio'].includes(role)">
                        <button v-if="[1, 2, 3].includes(sts)" class="v-btn bg-orange-400 hover:bg-orange-300" @click="setStatus(10)">
                            <span class="v-btn_text flex items-center">
                                <svg class="fill-white w-16px h-16px mr-3">
                                    <use xlink:href="@/assets/i.svg#status-10"></use>
                                </svg>
                                <span>Отложить</span>
                            </span>
                        </button>
                    </template> -->

          <template
            v-if="['Logist', 'Studio'].includes(role) && [4, 5].includes(sts)"
          >
            <div
              class="v-btn v-btn--sm bg-emerald-600 hover:bg-emerald-500"
              @click="AcceptOrder()"
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-4 h-4 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <span>ЗАВЕРШИТЬ</span>
              </span>
            </div>
          </template>

          <template v-if="canSendOrderToOutsorsers">
            <div
              class="v-btn v-btn--sm bg-emerald-600 hover:bg-emerald-500"
              @click="OrderToOutsorsers()"
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-4 h-4 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <span>отправить подрядчикам</span>
              </span>
            </div>
          </template>

          <template v-if="role == 'Check'">
            <div
              v-if="[6].includes(sts)"
              class="v-btn bg-emerald-600 hover:bg-emerald-500"
              @click="checkOrder()"
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-4 h-4 mr-2">
                  <use xlink:href="@/assets/i.svg#status-8"></use>
                </svg>
                <span>Контроль качества</span>
              </span>
            </div>
          </template>

          <template
            v-if="['Logist', 'Check'].includes(role) && order.r_id == null"
          >
            <button
              v-if="[1, 6, 8].includes(sts)"
              class="v-btn v-btn--sm bg-gray-900 hover:bg-gray-800 flex items-center"
              @click="claimCreate()"
            >
              <svg class="fill-white w-4 h-4 mr-2">
                <use xlink:href="@/assets/i.svg#status-19"></use>
              </svg>
              <span class="v-btn_text">РЕКЛАМАЦИЯ</span>
            </button>
          </template>
          <div
            v-if="(role === 'Logist' || role === 'Studio') && sts > 2"
            class="v-btn v-btn--sm bg-blue-500 hover:bg-pink-400"
            @click="ReassignOrder"
          >
            <span class="v-btn_text">Переназначить</span>
          </div>

          <template
            v-if="
              ['Logist', 'Studio', 'Check'].includes(role) && [5].includes(sts)
            "
          >
            <div
              class="v-btn v-btn--sm bg-pink-500 hover:bg-pink-400"
              @click="DenyOrder()"
            >
              <svg class="fill-white w-4 h-4 mr-2">
                <use xlink:href="@/assets/i.svg#status-7"></use>
              </svg>
              <span class="v-btn_text">На доработку</span>
            </div>
          </template>

          <template v-if="role == 'Master'">
            <button
              v-if="sts == 3"
              class="v-btn v-btn--sm bg-orange-500 hover:bg-orange-400 flex items-center"
              @click="StartOrder()"
            >
              <svg class="fill-white w-4 h-4 mr-2">
                <use xlink:href="@/assets/i.svg#status-4"></use>
              </svg>
              <span class="v-btn_text">НАЧАТЬ</span>
            </button>

            <button
              v-if="sts == 4"
              class="v-btn v-btn--sm bg-rose-500 hover:bg-rose-400 flex items-center"
              @click="StopOrder()"
            >
              <svg class="fill-white w-4 h-4 mr-2">
                <use xlink:href="@/assets/i.svg#status-4"></use>
              </svg>
              <span class="v-btn_text">ОСТАНОВИТЬ</span>
            </button>

            <template v-if="order.contact?.phone && sts == 4">
              <template
                v-if="
                  order.category == 2 &&
                  order.act > 0 &&
                  parseInt(order.ts?.monitoring) != 2
                "
              >
                <span class="ml-2"
                  >ПОДКЛЮЧИТЕ МОНИТОРИНГ, ЧТОБЫ ЗАВЕРШИТЬ ЗАКАЗ</span
                >
              </template>
              <template v-else>
                <button
                  class="v-btn bg-green-500 hover:bg-green-400 flex items-center"
                  @click="sendSms(), (this.s.popup = 'sms')"
                >
                  <svg class="fill-white w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#ico-sms"></use>
                  </svg>
                  <span class="v-btn_text">ВЫСЛАТЬ КОД</span>
                </button>
              </template>
            </template>

            <div v-if="sts == 5" class="p-5">
              <div class="text-center">
                <h3 class="text-green-500">Заказ принят клиентом</h3>
                <h3 class="text-orange-500">Заказ на проверке у логиста</h3>
              </div>
            </div>

            <div v-if="sts == 7" class="p-5">
              <div class="text-center">
                <h3 class="text-green-500">Заказ принят клиентом</h3>
                <h3 class="text-rose-500">Заказ отклонен логистом</h3>
                <button
                  :href="'/order/finish/' + order.iid"
                  @click="correct(order)"
                  class="v-btn bg-orange-500 hover:bg-orange-400 flex items-center"
                >
                  <svg class="fill-white w-4 h-4 mr-2">
                    <use xlink:href="@/assets/i.svg#status-7"></use>
                  </svg>
                  <span class="v-btn_text">ИСПРАВИТЬ</span>
                </button>
              </div>
            </div>

            <div v-if="sts == 6" class="p-5">
              <div class="text-center">
                <h3 class="text-green-500">Заказ принят клиентом</h3>
                <h3 class="text-green-500">Заказ принят логистом</h3>
                <h3 class="text-orange-500">Заказ на контроле качества</h3>
              </div>
            </div>

            <div v-if="sts == 8" class="p-5">
              <div class="text-center">
                <h3 class="text-green-500">Заказ принят клиентом</h3>
                <h3 class="text-green-500">Заказ принят логистом</h3>
                <h3 class="text-green-500">Заказ принят контролем качества</h3>
              </div>
            </div>
          </template>

          <template
            v-if="
              ['Manager', 'Logist', 'Studio'].includes(role) &&
              [1, 3, 4, 10].includes(sts) &&
              !otkaz
            "
          >
            <button
              class="v-btn v-btn--sm bg-gray-400 hover:bg-gray-300"
              @click="otkaz = true"
            >
              <span class="v-btn_text flex items-center">
                <svg class="fill-white w-4 h-4 mr-3">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <span>Отказ</span>
              </span>
            </button>
          </template>

          <!-- <template v-if="['Manager', 'Studio'].includes(role)">
                        <button v-if="sts < 6 || sts == 10" class="v-btn v-btn--sm bg-rose-500 hover:bg-rose-400" @click="delOrder()">
                            <span class="v-btn_text flex items-center">
                                <svg class="fill-white w-4 h-4 mr-2">
                                    <use xlink:href="@/assets/i.svg#ico-delete"></use>
                                </svg>
                                <span>Удалить</span>
                            </span>
                        </button>
                    </template> -->
        </div>
      </div>
    </div>

    <div
      v-if="s.popup == 'oper' && order.oper"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <OrderOper
        @close="close"
        :profile="profile"
        :order="order"
        :role="role"
        :saveOper="saveOper"
        :delImg="delImg"
      />
    </div>

    <div
      v-if="s.popup == 'task'"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <TaskEdit @close="close" />
    </div>

    <div
      v-if="s.popup == 'qcheck'"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <OrderQcheck @close="close" />
    </div>

    <div
      v-if="s.popup == 'activ'"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <ActivView @close="close" />
    </div>

    <div
      v-if="s.popup == 'sms'"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <a
        class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
        @click="s.popup = false"
      >
        <span class="text-white mr-2">Закрыть</span>
        <svg class="fill-white w-4 h-4">
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
      </a>
      <div class="block form edit-form tac pt50">
        <div class="flex text-center my-8 flex-col px-4">
          <h3 class="text-lg text-black">Для подтверждения введите 0000</h3>

          <div class="flex w-[260px] m-auto my-4">
            <input
              type="text"
              class="w-1/4 bg-slate-50 h-9 px-3 py-2 v-bgray rounded-sm text-2xl text-teal-700 mr-4"
              v-model="sms1"
            />
            <input
              type="text"
              class="w-1/4 bg-slate-50 h-9 px-3 py-2 v-bgray rounded-sm text-2xl text-teal-700 mr-4"
              v-model="sms2"
              ref="sms2"
            />
            <input
              type="text"
              class="w-1/4 bg-slate-50 h-9 px-3 py-2 v-bgray rounded-sm text-2xl text-teal-700 mr-4"
              v-model="sms3"
              ref="sms3"
            />
            <input
              type="text"
              class="w-1/4 bg-slate-50 h-9 px-3 py-2 v-bgray rounded-sm text-2xl text-teal-700 mr-4"
              v-model="sms4"
              ref="sms4"
            />
          </div>

          <button
            class="w-max v-btn bg-green-500 hover:bg-green-400 my-2 mx-auto"
            @click="checkSms(sms1 + sms2 + sms3 + sms4)"
          >
            <span class="v-btn_text text-white">Подтвердить</span>
          </button>
          <div
            v-if="sms_answ && sms_answ.length > 0"
            class="text-lg text-red-500"
          >
            {{ sms_answ }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="s.popup == 'price'"
      id="popnew"
      class="w-2/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <a
        class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150"
        @click="s.popup = false"
      >
        <span class="text-white mr-2">Закрыть</span>
        <svg class="fill-white w-4 h-4">
          <use xlink:href="@/assets/i.svg#ico-no"></use>
        </svg>
      </a>
      <div class="flex text-center my-8 flex-col px-4">
        <h3 class="text-lg text-black">Изменить стоимость услуги</h3>

        <div class="flex flex-col">
          <div class="flex flex-col items-center mt-6">
            <span class="text-gray-400 leading-none">Текущая стоимость</span>
            <span class="text-black text-lg"
              >{{ editPrice.sum }}&nbsp;&#8381;</span
            >
          </div>

          <div class="flex justify-around mt-4">
            <div class="flex flex-col items-center">
              <span class="text-gray-400 leading-none">Минимальная</span>
              <span class="text-rose-500 text-sm">{{
                editPrice.price.minprice
              }}</span>
            </div>
            <div class="flex flex-col items-center">
              <span class="text-gray-400 leading-none">Розничная</span>
              <span class="text-green-500 text-lg">{{
                editPrice.price.sum
              }}</span>
            </div>
            <div class="flex flex-col items-center">
              <span class="text-gray-400 leading-none">максимальная</span>
              <span class="text-sky-500 text-lg">{{
                editPrice.price.maxprice
              }}</span>
            </div>
          </div>

          <div class="flex flex-col my-5 items-center">
            <input
              type="text"
              class="w-1/2 bg-slate-50 h-9 px-3 py-2 v-bgray rounded-sm text-sm text-teal-700 mr-4"
              v-model="new_price"
              placeholder="Новая цена"
            />
          </div>
          <button
            class="w-max v-btn bg-green-500 hover:bg-green-400 my-2 mx-auto"
            @click="
              newOrderPrice({
                id: editPrice.id,
                price_id: editPrice.price.id,
                sum: new_price,
              }),
                (new_price = null),
                (s.popup = false)
            "
          >
            <span class="v-btn_text text-white">Подтвердить</span>
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="s.popup == 'cal'"
      id="popnew"
      class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
      <OrderCal @close="close" />
    </div>

    <div
      v-if="s.popup"
      @click="close"
      id="overlay"
      class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    ></div>
  </template>

  <div v-else class="loader opacity1 mt30">
    <div><img src="https://api.tahocrm.ru/images/loader.svg" width="44" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import API from "@/services/api";

// import DiffRomb from '@/components/report/DiffRomb.vue';
import OrderHistory from "../components/order/OrderHistory.vue";
import CommentList from "../components/CommentList.vue";
import OrderOper from "../components/order/OrderOper.vue";
import OrderQcheck from "../components/order/OrderQcheck.vue";
import OrderCal from "../components/order/OrderCal.vue";
import IcoPic from "../components/ui/IcoPic.vue";
import OrderStatus from "../components/ui/OrderStatus.vue";
import ActivView from "../components/ActivView.vue";

export default {
  name: "OrderEdit",

  components: {
    // DiffRomb,
    OrderHistory,
    CommentList,
    OrderOper,
    OrderQcheck,
    OrderCal,
    IcoPic,
    OrderStatus,
    ActivView,
  },

  data() {
    return {
      showTouches: false,
      showLeads: false,
      showZayavka: false,
      showSchet: false,
      showAnketa: false,
      showOrderLet: false,
      showGarland: false,

      penaltys: null,
      showPenaltys: false,
      // rombs: [
      //     'mintrans',
      //     'postponed',
      //     'appointed',
      //     'working',
      //     'completed',
      // ],
      // rombStyles: {
      //     mintrans: {
      //         ico: "ico-edit",
      //         back: "fill-status-12",
      //         label: "Минтранс",
      //     },
      //     postponed: {
      //         ico: "status-10",
      //         back: "fill-status-10",
      //         label: "Отложены",
      //     },
      //     appointed: {
      //         ico: "status-3",
      //         back: "fill-status-3",
      //         label: "Назначены",
      //     },
      //     working: {
      //         ico: "nav-masters",
      //         back: "fill-status-1",
      //         label: "В работе",
      //     },
      //     completed: {
      //         ico: "status-5",
      //         back: "fill-status-5",
      //         label: "Выполнено",
      //     },

      //     excess: {
      //         ico: "status-11",
      //         back: "fill-dark-red",
      //         label: "Цикл превышен",
      //     },
      //     checked: {
      //         ico: "ico-ok",
      //         back: "fill-status-6",
      //         label: "Проверены ТО",
      //     },
      //     canseled: {
      //         ico: "ico-no",
      //         back: "fill-status-7",
      //         label: "Отменено",
      //     },
      // },
      moment: moment,
      text_address: "",
      text_client: "",
      text_company: "",
      text_contact: "",
      text_ts: "",
      text_master: "",
      text_price: "",
      new_price: "",
      explaintext: "",
      cancel: {
        101: "Неактуально",
        102: "Другая услуга",
        103: "Другое",
      },
      otkaz: false,
      cancel_id: 0,
      editPrice: {},
      timer: { time: null, color: null },
      isBox: "",
      editAddressMode: false,
      companyList: [],
      contactList: [],
      tsList: [],
      masterList: [],
      priceList: [],
      slots: [],
      ico: {
        1: "status-1",
        3: "status-3",
        4: "status-4",
        5: "status-5",
        6: "status-6",
        7: "status-1",
        8: "status-8",
        10: "status-1",
        12: "doc",
        13: "doc",
        18: "status-1",
        20: "status-20",
      },
      sms1: "",
      sms2: "",
      sms3: "",
      sms4: "",
      sms_answ: "",
      trf: {
        1: "месяц",
        2: "месяц+",
        3: "год",
        4: "год+",
      },
      imagePrefix: API.IMG,
    };
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "cats",
      "role",
      "roles",
      "order",
      "OrderCal",
      "selectOrderClients",
      "selectOrderCompanies",
      "selectOrderContacts",
      "selectOrderTs",
      "selectWorkers",
      "selectManagers",
      "selectMasters",
      "selectPrices",
      "selectBoxes",
      "qcheck",
      "result",
      "selectZones",
      "newGarland",
    ]),
    canDelMaster() {
      if (this.order.status === 20) return false;
      return this.order.master_id && [1, 3, 10].includes(this.sts);
    },
    sts() {
      return this.order.status ? parseInt(this.order.status) : 0;
    },

    // услуга требует активацию тахографа или мониторинга
    anketa() {
      if (this.order.prices && this.selectPrices) {
        let pid = this.order.prices.map((p) => p.price_id);
        let prices = [];
        // тахограф
        prices = this.selectPrices.filter(
          (p) => pid.includes(p.id) && p.act == 1
        );
        if (prices[0]) {
          return 1;
        }
        // мониторинг
        prices = this.selectPrices.filter(
          (p) => pid.includes(p.id) && p.act == 2
        );
        if (prices[0]) {
          return 2;
        }
      }
      return 0;
    },

    statusSVG() {
      // return "<use xlink:href='@/assets/i.svg#" + this.ico[this.order.status] + "'></use>";
      return "@/assets/i.svg#" + this.ico[this.order.status];
    },

    // сколько времени нужно добавить на эти услуги
    needTime() {
      let need = 0;
      let time = 0;
      if (this.order.dates) {
        this.order.dates.forEach((e) => {
          let srt = moment(e.date + " " + e.start);
          let end = moment(e.date + " " + e.end);

          time = time + moment.duration(end - srt).asMinutes();
          // console.log("time: ", time);
        });
      }
      if (this.order.prices) {
        this.order.prices.forEach((e) => {
          need = need + parseInt(e.price?.time);
          // console.log("need: ", need);
        });
      }
      if (need && time) {
        return need - time;
      }
      return 0;
    },
    canSendOrderToOutsorsers() {
      return [
        ["Logist", "Studio"].includes(this.role),
        this.order.master_id == 1,
        this.order.status == 3,
        this.order.dates?.length,
        this.order.address,
        this.order.ts,
      ].every(Boolean);
    },
  },

  watch: {
    sms1() {
      if (this.sms1.length > 0) {
        this.$refs.sms2.focus();
      }
    },

    sms2() {
      if (this.sms2.length > 0) {
        this.$refs.sms3.focus();
      }
    },

    sms3() {
      if (this.sms3.length > 0) {
        this.$refs.sms4.focus();
      }
    },

    sms4() {
      if (this.sms4.length > 0) {
        this.checkSms(this.sms1 + this.sms2 + this.sms3 + this.sms4);
      }
    },

    result(value) {
      if (value == 1) {
        this.s.popup = false;
      } else {
        this.sms_answ = "Код не верный!";
      }
    },
  },

  methods: {
    ...mapActions([
      "getOrder",
      "setOrder",
      "setOrderCode",
      "setOrderCodebill",
      "copyOrder",
      "setBox",
      "setAddress",
      "setZone",
      "delAddress",
      "delDate",
      "findOrderClient",
      "setOrderClient",
      "delOrderClient",
      "clearOrderContactsCars",
      "setOrderCompany",
      "delOrderCompany",
      "setOrderContact",
      "delOrderContact",
      "setOrderTs",
      "delOrderTs",
      "setMaster",
      "delMaster",
      "setOrderPrice",
      "newOrderPrice",
      "delOrderPrice",
      "setOrderPay",
      "setStatus",
      "NewStatusOrder",
      "StartOrder",
      "StopOrder",
      "FinishOrder",
      "AcceptOrder",
      "DenyOrder",
      "ReassignOrder",
      "AnketaOrder",
      "cancelOrder",
      "sendExplain",
      "sendSms",
      "checkSms",
      "DelayOrder",
      "delOrder",
      "setClient",
      "setTab",
      "setClientContact",
      "claimCreate",
      "setClaimbyID",
      "showActiv",
      "showClient",
      "showContact",
      "setSettings",
      "getQckeck",
      "addTask",
      "showTask",
      "addLead",
      "onMonitoring",
      "showLeadById",
      "setOrderField",
      "saveOper",
      "delImg",
      "setOrderCRMNumber",
      "getNewGarland",

      "goToTouchFromLead",
      "goToLeadFromTouch",
      "goToBidNow",
      "goToInvoiceNow",
    ]),

    toggleBox(value) {
      console.log("toggleBox", value);
      this.isBox = value;
    },

    showClaim(iid) {
      console.log("showClaim");
      this.setClaimbyID(iid);
      this.$router.push("/claim/view");
    },

    showNum(n) {
      return Math.abs(+n || 0).toLocaleString(Intl.NumberFormat, {
        maximumFractionDigits: 1,
      });
    },
    showSign(n) {
      return Math.abs(+n || 0) < 0.05
        ? "null-diff"
        : +n > 0
        ? "positive-diff"
        : "negative-diff";
    },

    async getPenaltys() {
      let res = await API.GET("report/penalty?order_id=" + this.order.id);
      // console.log("RES", res);
      this.penaltys = Object.assign({}, res.data);

      // console.log("this.penaltys", this.penaltys);
      // console.log("this.penaltys.length", this.penaltys.length);
    },

    showContactfromOrder(contact) {
      this.s.back = "/order/edit";
      this.setSettings(this.s);
      this.showContact(contact);
    },

    findMaster() {
      // console.log("findMaster", this.selectMasters);
      if (this.order.dates.length > 0) {
        this.filterMasterbyDate();
      } else {
        this.masterList = this.selectMasters.filter(
          (item) =>
            item.name.toLowerCase().indexOf(this.text_master.toLowerCase()) !==
            -1
        );
      }
    },

    // список свободных мастеров в этих слотах
    filterMasterbyDate() {
      if (!this.order.dates) {
        this.masterList = this.selectMasters;
        return;
      }

      let slots = [];
      let free_masters = [];
      let count_slot = 0;

      this.order.dates.forEach((e) => {
        let slots_for_masters = [];
        // e.date;
        slots = this.reSlots(e);
        count_slot = count_slot + slots.length;
        // console.log("date: ", e.date);
        // console.log("slots: ", slots);

        slots_for_masters = this.OrderCal.filter(
          (c) =>
            c.date == e.date &&
            slots.includes(c.start) &&
            (c.status == 1 || (c.status == 2 && c.order_id == this.order.id))
        );
        // console.log("slots_for_masters: ", slots_for_masters);
        slots_for_masters.forEach((m) => {
          free_masters.push(m.uid);
        });
        // console.log("free_masters: ", free_masters);
      });

      // console.log("uids", uids);
      let res = free_masters.reduce((acc, el) => {
        acc[el] = (acc[el] || 0) + 1;
        return acc;
      }, {});

      // console.log("res", res);
      let u = Object.keys(res).filter((key) => res[key] === count_slot);
      // console.log("u", u);
      this.masterList = this.selectMasters.filter((m) =>
        u.includes(m.uid.toString())
      );
    },

    // получить слоты из времени заказа
    reSlots(date) {
      let slots = [];
      let s = moment(date.start, "HH:mm:ss");
      let e = moment(date.end, "HH:mm:ss").format("HH:mm:ss");
      while (s.format("HH:mm:ss") < e) {
        slots.push(s.format("HH:mm:ss"));
        s = s.add(15, "minutes");
      }
      return slots;
    },

    // reDates() {
    //     let dates = [];
    //     this.order.dates.forEach((element) => {
    //         dates.push(element.date);
    //     });
    //     console.log("redates: ", [...new Set(dates)]);
    //     return [...new Set(dates)];
    // },

    // КЛИЕНТ
    pickClient(item) {
      this.setOrderClient(item);
      this.text_client = "";
    },

    dClient() {
      this.contactList = null;
      this.tsList = null;
      this.delOrderClient();
    },

    toggleGarl(garlShow) {
      if (garlShow == "showTouches") {
        this.showTouches = !this.showTouches;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showLeads") {
        this.showTouches = false;
        this.showLeads = !this.showLeads;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showZayavka") {
        this.showTouches = false;
        this.showZayavka = !this.showZayavka;
        this.showSchet = false;
        this.showAnketa = false;
        this.showLeads = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showSchet") {
        this.showTouches = false;
        this.showSchet = !this.showSchet;
        this.showAnketa = false;
        this.showinvoice = false;
        this.showLeads = false;
        this.showZayavka = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showAnketa") {
        this.showTouches = false;
        this.showAnketa = !this.showAnketa;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showinvoice = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showinvoice") {
        this.showTouches = false;
        this.showinvoice = !this.showinvoice;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = false;
      }
      if (garlShow == "showOrderLet") {
        this.showTouches = false;
        this.showinvoice = false;
        this.showLeads = false;
        this.showZayavka = false;
        this.showSchet = false;
        this.showAnketa = false;
        this.showOrderLet = !this.showOrderLet;
      }
    },

    // КОМПАНИЯ
    findCompany() {
      // console.log("findCompany: ", this.text_company);
      if (this.text_company != "") {
        this.companyList = this.selectOrderCompanies.filter((item) => {
          console.log("item", item);
          let name = null;
          let full = null;
          if (item.name != null) {
            name =
              item.name
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (item.full != null) {
            full =
              item.full
                .toLowerCase()
                .indexOf(this.text_company.toLowerCase()) !== -1;
          }
          if (name || full) {
            return true;
          }
          return false;
        });
      } else {
        this.companyList = this.selectOrderCompanies;
      }
    },

    pickCompany(item) {
      this.setOrderCompany(item);
      this.text_contact = "";
    },

    // КОНТАКТ
    findContact() {
      // console.log("findContact: ", this.text_contact);
      if (this.text_contact != "") {
        this.contactList = this.selectOrderContacts.filter((item) => {
          console.log("item", item);
          let name = null;
          let secondname = null;
          if (item.name != null) {
            name =
              item.name
                .toLowerCase()
                .indexOf(this.text_contact.toLowerCase()) !== -1;
          }
          if (item.secondname != null) {
            secondname =
              item.secondname
                .toLowerCase()
                .indexOf(this.text_contact.toLowerCase()) !== -1;
          }
          if (name || secondname) {
            return true;
          }
          return false;
        });
      } else {
        this.contactList = this.selectOrderContacts;
      }
    },

    pickContact(item) {
      this.setOrderContact(item);
      this.text_contact = "";
    },

    showThisClient() {
      if (["Manager", "Logist", "Studio", "Finance"].includes(this.role)) {
        this.showClient(this.order.client);
      }
    },
    showThisContact() {
      if (["Manager", "Logist", "Studio", "Finance"].includes(this.role)) {
        this.showContactfromOrder(this.order.contact);
      }
    },

    // ТС
    findTs() {
      if (this.text_ts != "") {
        this.tsList = this.selectOrderTs.filter((item) => {
          console.log("item", item);
          let num = null;
          let mark = null;
          if (item.num != null) {
            num =
              item.num.toLowerCase().indexOf(this.text_ts.toLowerCase()) !== -1;
          }
          if (item.mark != null) {
            mark =
              item.mark.toLowerCase().indexOf(this.text_ts.toLowerCase()) !==
              -1;
          }
          if (num || mark) {
            return true;
          }
          return false;
        });
      } else {
        this.tsList = this.selectOrderTs;
      }
    },

    pickTs(item) {
      this.setOrderTs(item);
      this.text_ts = "";
    },

    close() {
      this.s.popup = null;
      this.setSettings(this.s);
    },

    // УСЛУГА
    findPrice() {
      // console.log("category_id ", this.cats);
      // this.priceList = this.selectPrices.filter(
      //     (item) => this.cats.includes(parseInt(item.category_id)) && item.name.toLowerCase().indexOf(this.text_price.toLowerCase()) !== -1
      // );
      this.priceList = this.selectPrices.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.text_price.toLowerCase()) !== -1
      );
    },

    pickPrice(item) {
      this.setOrderPrice(item);
      this.priceList = "";
      this.text_price = "";
      if (item.act == 1) {
        this.anketa = 1;
      }
      if (item.act == 2) {
        this.anketa = 2;
      }
    },

    showOper() {
      this.s.popup = "oper";
    },

    checkOrder() {
      this.s.popup = "qcheck";
      this.getQckeck();
    },

    showActivfromOrder(activ) {
      // this.s.back = "/order/edit";
      // this.setTab("ClientActive");
      // this.setClient(this.order.client);
      // this.$router.push("/client/view");
      this.s.popup = "activ";
      this.setSettings(this.s);
      this.showActiv(activ);
    },

    addOrderLead() {
      let formData = new FormData();
      formData.append("client_id", this.order.client_id);
      formData.append("order_id", this.order.id);
      this.addLead(formData);
    },

    // // выбрана ли услуга с активацией
    // Activate() {
    //     this.skzi = 0;
    //     if (this.order.prices && this.selectPrices) {
    //         let pid = this.order.prices.map((p) => p.price_id);
    //         let price = this.selectPrices.filter((p) => pid.includes(p.id) && p.act == 1);
    //         if (price[0]) {
    //             this.skzi = 1;
    //         }
    //     }
    // },

    Tmr() {
      if (this.order && this.order.timer) {
        if (
          this.role == "Master" &&
          [1, 2, 5, 6, 8, 10].includes(parseInt(this.order.status))
        ) {
          this.order.timer = null;
          return;
        }

        let date = moment(this.order.timer);
        let now = moment() - moment().utcOffset() * 60000 + 180 * 60000; // временная зона
        this.timer.time = moment.utc(date - now).format("HH:mm:ss");

        let t = date - now;

        if (t > 0) {
          this.timer.color = "1";
        } else {
          this.timer.color = "7";
          t = t * -1;
        }
        let hours = Math.floor(t / (1000 * 60 * 60));
        if (hours < 10) {
          hours = "0" + hours;
        }
        let minutes = Math.floor((t / (1000 * 60)) % 60);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        let seconds = Math.floor((t / 1000) % 60);
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        this.timer.time = hours + ":" + minutes + ":" + seconds;
      } else {
        this.timer.time = null;
      }
    },
    async correct() {
      if (prompt("введите номер заказа") != this.order.iid) {
        return alert("Это не тот заказ");
      }
      let res = await API.GET("order/correct?id=" + this.order.id);
      console.log("RES", res);
      if (res.data.alert.type == "success") {
        this.getOrder();
      }
    },
    async OrderToOutsorsers() {
      var res = await API.GET("order/to-outsoursers", { id: this.order.id });
      if (res?.data?.order) {
        this.setOrder(res.data.order);
      }
    },

    goToTouch(touch) {
      this.goToTouchFromLead(touch);
    },
    goToLead(lead) {
      this.goToLeadFromTouch(lead);
    },
    goToBid(bid) {
      this.goToBidNow(bid);
    },
    goToInvoice(invoice) {
      this.goToInvoiceNow(invoice);
    },
    goToOrder(order) {
      this.goToOrderNow(order);
    },
  },

  async mounted() {
    await this.getOrder();
    await this.getNewGarland({ id: this.order.id });

    setTimeout(() => {
      this.Tmr();
      this.showGarland = true;
    }, 500);

    window.DEBUG_ORDER = this;
  },

  unmounted() {
    this.clearOrderContactsCars();
  },
};
</script>

<style scoped>
.label-shit {
  color: #262d29;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 10px;
}
.sign-shit {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.sign-shit span {
  font-size: 10px;
}
.plan-shit {
  margin-top: 25px;
  color: #ababab;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.plan-shit span {
  font-size: 8px;
}
.height-penaltys {
  min-height: 250px;
}
</style>

import API from "../../services/api";
import moment from "moment";
// import router from "@/router/router";

export default {
    state: {
        moneys: [],
        filteredMoneys: [],
        countMoneys: [],
        money: {},


        invoiceMoney: {},
        invoiceMoneys: [],
        filteredinvoiceMoneys: [],
        countinvoiceMoneys: [],
    },

    mutations: {
        setMoneys: (state, moneys) => {
            state.moneys = moneys;
            state.filteredMoneys = moneys;
            state.countMoneys = moneys;
        },
        setInvoiceMoneys: (state, invoiceMoneys) => {
            state.invoiceMoneys = invoiceMoneys;
            state.filteredinvoiceMoneys = invoiceMoneys;
            state.countinvoiceMoneys = invoiceMoneys;
        },

        filteredMoneys: (state, moneys) => {
            state.filteredMoneys = moneys;
        },
        setMoney: (state, money) => {
            state.money = money;
        },
        setInvoiceMoney: (state, invoiceMoney) => {
            state.invoiceMoney = invoiceMoney;
        },
    },

    actions: {
        getMoneys(ctx) {
            API.GET("finance/moneys").then((res) => {
                localStorage.setItem("moneys", JSON.stringify(res.data.moneys));
                ctx.commit("setMoneys", res.data.moneys);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findMoney", ctx.rootState.s);
                });
            });
        },

        getInvoiceMoneys(ctx) {
            API.GET("finance/invoice-moneys").then((res) => {
                localStorage.setItem("invoiceMoneys", JSON.stringify(res.data.invoiceMoneys));
                ctx.commit("setInvoiceMoneys", res.data.invoiceMoneys);

                ctx.dispatch("loadSettings").then(() => {
                    ctx.dispatch("findInvoiceMoney", ctx.rootState.s);
                });
            });
        },

        findMoney(ctx, s) {
            let list = ctx.state.moneys;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let iid = null;
                    let client = null;
                    iid = item.iid.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.client) {
                        client = item.client.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    return (iid || client);
                });
            }

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.pay_status) == String(s.status));
            }

            if (list.length != ctx.state.moneys.length) {
                s.clear = true;
            }

            ctx.state.filteredMoneys = list;
            ctx.dispatch("setSettings", s);
        },

        findInvoiceMoney(ctx, s) {
            let list = ctx.state.invoiceMoneys;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let invoice_number = null;
                    let code = null;
                    let codebill = null;
                    let client = null;
                    invoice_number = item.invoice_number.toString().indexOf(s.findme.toLowerCase()) !== -1;
                    if (item.code) {
                        code = item.code.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.codebill) {
                        codebill = item.codebill.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    if (item.client) {
                        client = item.client.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    }
                    return (invoice_number || client || codebill ||code );
                });
            }

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => String(item.pay_status) == String(s.status));
            }

            if (list.length != ctx.state.invoiceMoneys.length) {
                s.clear = true;
            }

            ctx.state.filteredinvoiceMoneys = list;
            ctx.dispatch("setSettings", s);
        },

        setMoney(ctx, money) {
            ctx.commit("setMoney", money);
            localStorage.setItem("money", JSON.stringify(money));
        },

        setInvoiceMoney(ctx, invoiceMoney) {
            ctx.commit("setInvoiceMoney", invoiceMoney);
            localStorage.setItem("invoiceMoney", JSON.stringify(invoiceMoney));
        },

        showMoney(ctx, money) {
            ctx.dispatch("setMoney", money);
            // API.GET("ajax/comments", { params: { iid: money.iid } }).then((res) => {
            //     this.comments = res.data.comments;
            // });
        },

        showInvoiceMoney(ctx, invoiceMoney) {
            ctx.dispatch("setInvoiceMoney", invoiceMoney);
            // API.GET("ajax/comments", { params: { iid: money.iid } }).then((res) => {
            //     this.comments = res.data.comments;
            // });
        },

        doneMoney(ctx, status) {
            console.log("doneMoney");
            ctx.state.money.pay_status = status;
            ctx.dispatch("setMoney", ctx.state.money);

            let formData = new FormData();
            formData.append("iid", ctx.state.money.iid);
            formData.append("pay_status", status);
            formData.append("pay_date", moment(ctx.state.money.pay_date).format("YYYY-MM-DD HH:mm:ss"));
            API.POST("finance/done", formData);
        },
        doneInvoiceMoney(ctx, status) {
            console.log("doneInvoiceMoney");
            ctx.state.invoiceMoney.pay_status = status;
            ctx.dispatch("setInvoiceMoney", ctx.state.invoiceMoney);

            let formData = new FormData();
            formData.append("invoice_number", ctx.state.invoiceMoney.invoice_number);
            formData.append("pay_status", status);
            formData.append("pay_date", moment(ctx.state.invoiceMoney.pay_date).format("YYYY-MM-DD HH:mm:ss"));
            API.POST("finance/invoice-done", formData);
        },
    },

    getters: {
        MoneyList(state) {
            // state.filteredMoneys = state.filteredMoneys.filter((item) => parseInt(item.status) != 8);
            return state.filteredMoneys;
        },
        InvoiceMoneyList(state) {
            // state.filteredMoneys = state.filteredMoneys.filter((item) => parseInt(item.status) != 8);
            return state.filteredinvoiceMoneys;
        },

        MoneyCounter(state) {
            let count = [];
            if (state.countMoneys.length > 0) {
                count[1] = 0;
                count[6] = 0;
                state.countMoneys.forEach((elem) => {
                    if (elem.pay_status == 6) {
                        count[6]++;
                    } else {
                        count[1]++;
                    }
                });
            }
            return count;
        },
        InvoiceMoneyCounter(state) {
            let count = [];
            if (state.countinvoiceMoneys.length > 0) {
                count[1] = 0;
                count[6] = 0;
                state.countinvoiceMoneys.forEach((elem) => {
                    if (elem.pay_status == 6) {
                        count[6]++;
                    } else {
                        count[1]++;
                    }
                });
            }
            return count;
        },
        money(state) {
            return state.money;
        },
        invoiceMoney(state) {
            return state.invoiceMoney;
        },
    },
};

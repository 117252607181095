<template>
  <svg
    v-if="status == 1"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#status-1"></use>
  </svg>
  <svg
    v-if="status == 2"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#nav-masters"></use>
  </svg>
  <svg
    v-if="status == 3"
    class="w-full h-full fill-white"
    :class="'fill-status-6'"
  >
    <use xlink:href="@/assets/i.svg#ico-ok"></use>
  </svg>
  <svg
    v-if="status == 4"
    class="w-full h-full fill-white"
    :class="'fill-status-' + status"
  >
    <use xlink:href="@/assets/i.svg#ico-no"></use>
  </svg>
</template>

<script>
export default {
  name: "IcoBidStatus",
  props: {
    status: Number,
  },
};
</script>

<template>
    <div class="flex flex-wrap">
        <div class="bg-white rounded-lg mr-4 flex-1 h-max min-w-[250px] my-4">
            <template v-if="['Studio', 'Check', 'Finance'].includes(role)">
                <div class="block edit-form">
                    <div class="p-5 text-center font-latobold text-lg v-bbgray">
                        <h2>УСЛУГА</h2>
                    </div>
                    <div class="p-5 v-bbgray">
                        <div class="w-full flex flex-col px-2 mb-4">
                            <label>
                                <input
                                    type="text"
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    placeholder="Название"
                                    v-model="price.name"
                                    @input="savePrice(price)"
                                />
                            </label>
                        </div>

                        <div class="flex">
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Код</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="price.code"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Время на заказ (мин)</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="price.time"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                        </div>

                        <div class="w-full flex flex-col px-2 mb-4">
                            <label class="text-gray-400">Категория</label>
                            <select
                                class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                v-model="price.category_id"
                                @change="savePrice(price)"
                            >
                                <option disabled value="">Выберите категорию</option>
                                <template v-for="cat in priceCategories" :key="cat">
                                    <option :value="cat.id">{{ cat.name }}</option>
                                </template>
                            </select>
                        </div>

                        <div class="flex">
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label class="text-gray-400">Тип заказ-наряда</label>
                                <select
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    v-model="price.type"
                                    @change="savePrice(price)"
                                >
                                    <option value="0">Без заказ-наряда</option>
                                    <option value="1" :class="{ selected: price.type == 1 }">Тахографы</option>
                                    <option value="2" :class="{ selected: price.type == 2 }">Мониторинг</option>
                                    <option value="3" :class="{ selected: price.type == 3 }">Отопители</option>
                                </select>
                            </div>

                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label class="text-gray-400">Тип анкеты</label>
                                <select
                                    class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                    v-model="price.act"
                                    @change="savePrice(price)"
                                >
                                    <option value="0">Без анкеты</option>
                                    <option value="1" :class="{ selected: price.act == 1 }">Тахографы</option>
                                    <option value="2" :class="{ selected: price.act == 2 }">Мониторинг</option>
                                </select>
                            </div>
                        </div>

                        <div class="w-full flex flex-col mb-4 px-2 relative">
                            <label class="w-full">
                                <span class="text-gray-400">Описание</span>
                                <textarea
                                    class="min-h-[100px] bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                                    cols="30"
                                    rows="5"
                                    v-model="price.info"
                                    @change="savePrice(price)"
                                ></textarea>
                            </label>
                        </div>
                    </div>

                    <div class="p-5 v-bbgray">
                        <div class="flex">
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Розничная стоимость</span>
                                    <input
                                        type="text"
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        name="sum"
                                        v-model="price.sum"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Маржа</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        disabled
                                        :value="margin + '%'"
                                    />
                                </label>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Минимальная стоимость</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="price.minprice"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Максимальная стоимость</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="price.maxprice"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Себест-ть товара</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="price.cost"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                            <div class="w-1/2 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Себест-ть инженера</span>
                                    <input
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        type="text"
                                        v-model="price.fix"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="p-5 v-bbgray">
                        <h3 class="text-center font-latobold text-base mb-4">БОНУСЫ ИНЖЕНЕРУ</h3>
                        <div class="flex">
                            <div class="w-1/3 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Дисциплина</span>
                                    <input
                                        type="text"
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        name="bonus1"
                                        v-model="price.bonus1"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                            <div class="w-1/3 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Качество</span>
                                    <input
                                        type="text"
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        name="bonus2"
                                        v-model="price.bonus2"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                            <div class="w-1/3 flex flex-col mb-4 mx-2">
                                <label>
                                    <span class="text-gray-400">Надежность</span>
                                    <input
                                        type="text"
                                        class="bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
                                        name="bonus3"
                                        v-model="price.bonus3"
                                        @input="savePrice(price)"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="p-5 v-bbgray">
                        <div v-if="price.del == 0" class="flex justify-around" @click="delPrice(price)">
                            <button class="mx-auto mt-4 v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150">
                                <svg class="fill-white w-16px h-16px mr-3">
                                    <use xlink:href="@/assets/i.svg#ico-delete"></use>
                                </svg>
                                <span class="v-btn_text">Удалить</span>
                            </button>
                        </div>
                        <div v-else class="flex justify-around" @click="undelPrice(price)">
                            <button class="mx-auto mt-4 v-btn bg-green-500 hover:bg-green-400 transition-colors duration-150">
                                <svg class="fill-white w-16px h-16px mr-3">
                                    <use xlink:href="@/assets/i.svg#ico-delete"></use>
                                </svg>
                                <span class="v-btn_text">Восстановить</span>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="block">
                    <div class="p-5 v-bbgray">
                        <p class="text-center mb0">{{ price?.category?.name }}</p>
                        <div class="flx-clm flx-c text-center">
                            <h1 class="m0">{{ price.name }}</h1>
                        </div>
                    </div>
                    <div class="p-5 v-bbgray text-center">
                        <div class="row">
                            <div class="fgrp cox100 col50">
                                <div>
                                    Заказ-наряд
                                    <h2 class="mb10">{{ price.type }}</h2>
                                </div>
                            </div>
                            <div class="fgrp cox100 col50">
                                <div>
                                    Время на выполнение
                                    <h2 class="mb10">
                                        {{ price.time ? price.time + "мин" : "-" }}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-5 v-bbgray text-center">
                        <div class="row">
                            <div class="fgrp cox100 col50">
                                <div>
                                    Розничная стоимость
                                    <h2 class="mb10">
                                        {{ price.sum ? price.sum + " ₽" : "0 " }}
                                    </h2>
                                </div>
                            </div>
                            <div class="fgrp cox100 col50">
                                <div>
                                    Маржа
                                    <h2 class="mb10">
                                        {{ (price.sum * margin) / 100 + " ₽ " + "(" + margin + "%)" }}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-5 v-bbgray text-center">
                        <div class="row">
                            <div class="fgrp cox100 col50">
                                <div>
                                    Минимальная стоимость
                                    <h2 class="mb10">
                                        {{ price.minprice ? price.minprice + " ₽" : "0" }}
                                    </h2>
                                </div>
                            </div>
                            <div class="fgrp cox100 col50">
                                <div>
                                    Максимальная стоимость
                                    <h2 class="mb10">
                                        {{ price.maxprice ? price.maxprice + " ₽" : "0" }}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-5 v-bbgray text-center">
                        <div class="row">
                            <div class="fgrp cox100 col50">
                                <div>
                                    Себест-ть товара
                                    <h2 class="mb10">
                                        {{ price.cost ? price.cost + " ₽" : "0" }}
                                    </h2>
                                </div>
                            </div>
                            <div class="fgrp cox100 col50">
                                <div>
                                    Себест-ть инженера
                                    <h2 class="mb10">{{ work }} ₽</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-5 v-bbgray text-center">
                        <h3>БОНУСЫ ИНЖЕНЕРУ</h3>
                        <div class="row">
                            <div class="fgrp cox100 col33">
                                Дисциплина
                                <h2>{{ price.bonus1 }} ₽</h2>
                            </div>
                            <div class="fgrp cox100 col33">
                                Качество
                                <h2>{{ price.bonus2 }} ₽</h2>
                            </div>
                            <div class="fgrp cox100 col33">
                                Надежность
                                <h2>{{ price.bonus3 }} ₽</h2>
                            </div>
                        </div>
                    </div>

                    <div v-if="price.info" class="p-5 v-bbgray flex">
                        <svg class="w-5 h-5 mr-4">
                            <use xlink:href="@/assets/i.svg#ico-comment"></use>
                        </svg>
                        {{ price.info }}
                    </div>
                </div>
            </template>
        </div>

        <div class="bg-white rounded-lg mr-4 flex-1 h-max min-w-[250px] my-4">
            <div class="p-5 text-center font-latobold text-lg v-bbgray">
                <h2>ТОВАРЫ</h2>
            </div>
            <div class="p-5">
                <span class="text-gray-400">Описание</span>
                <textarea
                    class="min-h-[100px] bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    cols="30"
                    rows="5"
                    v-model="price.goods"
                    @change="savePrice(price)"
                ></textarea>
            </div>
        </div>

        <div class="bg-white rounded-lg flex-1 h-max min-w-[250px] my-4">
            <div class="p-5 text-center font-latobold text-lg v-bbgray">
                <h2>ОБУЧЕНИЕ</h2>
            </div>
            <div class="p-5">
                <span class="text-gray-400">Описание</span>
                <textarea
                    class="min-h-[100px] bg-slate-50 h-9 px-3 py-2 w-full box-border v-bgray rounded-sm text-sm text-teal-700"
                    cols="30"
                    rows="5"
                    v-model="price.training"
                    @change="savePrice(price)"
                ></textarea>

        
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "PriceView",
    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "priceCategories", "price"]),

        margin() {
            if (this.price.sum) {
                let flex = 0;
                if (Number(this.price.flex) > 0) {
                    flex = Number((Number(this.price.sum) / 100) * Number(this.price.flex));
                }
                let m = ((Number(this.price.sum) - Number(this.price.cost) - Number(this.price.fix) - flex) * 100) / this.price.sum;
                return m;
            }
            return null;
        },

        work() {
            return Number(this.price.fix) + Number((this.price.flex * this.price.sum) / 100);
        },
    },

    methods: {
        ...mapActions(["getPrice", "getPriceCategories", "savePrice", "delPrice", 'undelPrice']),
        ...mapMutations(["setPrice"]),
    },

    mounted() {
        this.getPrice();
        this.getPriceCategories();
    },

    unmounted() {
        this.setPrice({});
    },
};
</script>

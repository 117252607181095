<template>
    <div
        v-if="s.popup == 'edit'"
        id="popnew"
        class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-max overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
        <CarEdit v-if="car?.id" @close="close" />
    </div>

    <div
        v-if="s.popup == 'activation'"
        class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-max overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
    >
        <ActivView @close="close" />
    </div>

    <div v-if="s.popup" @click="close" id="overlay" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>

    <div class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px">
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(),filterByOwner(0)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-6"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="CarCounter">
                    {{ CarCounter.ownerless }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
            >
                Бесхозные
            </span>
        </div>

        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(1),filterByOwner()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-6"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="CarCounter">
                    {{ CarCounter[1] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >НОВЫЕ&nbsp;ПОДКЛЮЧЕНЫ</span
            >
        </div>
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(3),filterByOwner()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="CarCounter">
                    {{ CarCounter[3] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-pink-500 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >ОТКЛЮЧЕНЫ</span
            >
        </div>

        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(5),filterByOwner()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-10"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="CarCounter">
                    {{ CarCounter[5] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-10 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >ВОЗВРАТ</span
            >
        </div>

        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(2),filterByOwner()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-6"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="CarCounter">
                    {{ CarCounter[2] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-8 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >ВСЕ&nbsp;ПОДКЛЮЧЕНЫ</span
            >
        </div>

        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(4),filterByOwner()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="CarCounter">
                    {{ CarCounter[4] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-red-600 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >ВСЕ&nbsp;ОТКЛЮЧЕНЫ</span
            >
        </div>

        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(9),filterByOwner()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="CarCounter">
                    {{ CarCounter[9] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-20 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >НЕАКТИВНЫЕ</span
            >
        </div>
    </div>

    <div class="pb-4 w-full flex flex-wrap items-center">
        <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
            <span class="w-full text-lg leading-none text-teal-700">
                {{ this.CarList?.length }}
            </span>
        </h2>

        <div class="flex-2_0 mr-15px w-1/6 my-4">
            <input type="text" class="h-9 w-full px-3 py-2 text-sm bg-slate-50" placeholder="Название" v-model="s.findme" @input="findCar(s)" />
        </div>
        <div class="flex items-center mr-4">
            <div class="relative mx-5px group" @click="cause(1)">
                <svg class="duration-300 fill-gray-400 w-6 h-6 hover:fill-status-5 cursor-pointer">
                    <use xlink:href="@/assets/i.svg#bill-3"></use>
                </svg>
                <span
                    class="absolute -top-7 left-1/2 -translate-x-2/4 text-xs uppercase bg-slate-100 px-2 py-1 group-hover:visible group-hover:opacity-100 opacity-0 invisible duration-150 transition ease-linear"
                    >Неуплата</span
                >
            </div>

            <div class="relative mx-5px group" @click="cause(2)">
                <svg class="duration-300 fill-gray-400 w-6 h-6 hover:fill-status-5 cursor-pointer">
                    <use xlink:href="@/assets/i.svg#ico-avatar"></use>
                </svg>
                <span
                    class="absolute -top-7 left-1/2 -translate-x-2/4 text-xs uppercase bg-slate-100 px-2 py-1 group-hover:visible group-hover:opacity-100 opacity-0 invisible duration-150 transition ease-linear"
                    >Сам&nbsp;ушел</span
                >
            </div>
        </div>

        <input class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" type="date" v-model="s.start" @change="findCar(s)" />

        <input class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" type="date" v-model="s.end" @change="findCar(s)" />

        <select class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" @change="findCar(s)" v-model="s.type">
            <option value="0">Оператор</option>
            <option value="none">Без оператора</option>
            <option :value="'М2М'">М2М</option>
            <option :value="'БИЛАЙН'">БИЛАЙН</option>
            <option :value="'МТС'">МТС</option>
            <option :value="'МЕГАФОН'">МЕГАФОН</option>
        </select>

        <select class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" @change="findCar(s)" v-model="s.category">
            <option value="0">Тариф</option>
            <option value="none">Без тарифа</option>
            <option :value="1">месяц</option>
            <option :value="2">месяц+</option>
            <option :value="3">год</option>
            <option :value="4">год+</option>
        </select>

        <div class="flex py-4">
            <button class="v-btn bg-gray-500 hover:bg-gray-400 mr-4" v-if="s.clear">
                <span class="v-btn_text" @click="Clear">Сброс</span>
            </button>
        </div>
    </div>
    <select v-model="itemsInPage" style="padding: 7px;">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
    <Paginator :count="pageCount" v-model="page"/>

    <div v-if="CarList?.length > 0" class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto">
        <div class="relative h-10 min-w-full">
            <div class="grid grid-cols-14 gap-2 items-center h-10 v-bbgray px-4 uppercase absolute min-w-full">
                <div class="font-latobold text-gray-400 text-[11px]">№</div>
                <div class="font-latobold text-gray-400 text-[11px]">Начало</div>
                <div class="font-latobold text-gray-400 text-[11px]">Окончание</div>
                <div class="font-latobold text-gray-400 text-[11px] col-span-3">Клиент</div>
                <div class="font-latobold text-gray-400 text-[11px] col-span-2">Компания</div>
                <div class="font-latobold text-gray-400 text-[11px] col-span-2">Виалон</div>
                <div class="font-latobold text-gray-400 text-[11px]">Оператор</div>
                <div class="font-latobold text-gray-400 text-[11px]">Анкета</div>
                <div class="font-latobold text-gray-400 text-[11px]">Тариф</div>
                <div class="font-latobold text-gray-400 text-[11px]">Гос.номер</div>
            </div>
        </div>
        <template v-for="item in PaginatedCarList" :key="item">
            <a
                class="grid grid-cols-14 gap-2 px-4 py-2 v-bbgray items-center transition duration-300 bg-teal-light odd:bg-white cursor-pointer hover:shadow-v1 max-2xl:grid-cols-order_m max-2xl:auto-rows-[50px]"
                :class="'status-' + item.status"
            >
                <div class="flex">
                    <svg v-if="item.monitoring == 2" class="w-5 h-5 mr-2 fill-status-6">
                        <use xlink:href="@/assets/i.svg#status-6"></use>
                    </svg>
                    <svg v-else class="w-5 h-5 mr-2 fill-status-20">
                        <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                    {{ item.id }}
                </div>

                <div>
                    <div v-if="item.start">{{ moment(item.start).format("DD.MM.YYYY") }}</div>
                </div>

                <div>
                    <div v-if="item.end">{{ moment(item.end).format("DD.MM.YYYY") }}</div>
                </div>

                <div @click="getCarById(item.id), (this.s.popup = 'edit')" class="flex w-full h-full items-center col-span-3">
                    <span
                        class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
                        :class="item.year ? 'bg-status-1' : 'bg-status-7'"
                    >
                        {{ item.client?.slice(0, 1) }}
                    </span>
                    <span class="text-base font-semibold break-all max-h-10 leading-5">{{ item.client }}</span>
                </div>

                <div class="col-span-2">{{ item.company }}</div>

                <div class="col-span-2">{{ item.vialon }}</div>

                <div>{{ item.operator }}</div>

                <div class="flex justify-center">
                    <svg v-if="item.activation_id > 0" class="fill-status-6 w-5 h-5" @click="showActivById(item.activation_id)">
                        <use xlink:href="@/assets/i.svg#sputnik"></use>
                    </svg>
                    <div v-else>-</div>
                </div>

                <div>{{ trf[item.tariff] }}</div>

                <div class="min-w-[80px] v-gray font-latoblod-serif">
                    <span v-if="item.num" class="block text-center text-slate-600 w-full min-w-full rounded-sm v-tsnum px-2 py-1 text-xs uppercase">
                        {{ item.num }}
                    </span>
                </div>
            </a>
        </template>
    </div>
    <div v-else-if="s.loader" class="absolute top-60 left-0 flex-cc w-full"><img src="https://api.tahocrm.ru/images/loader.svg" /></div>
    <div v-else class="absolute top-60 left-0 flex-cc w-full text-slate-300 text-2xl text-center">Нет данных</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CarEdit from "../components/client/CarEdit";
import ActivView from "../components/ActivView";
import Paginator from "@/components/ui/Paginator";

export default {
    name: "CarPage",

    components: { CarEdit, ActivView, Paginator },

    data() {
        return {
            moment: moment,
            trf: {
                1: "месяц",
                2: "месяц+",
                3: "год",
                4: "год+",
            },
            page: 0,
            itemsInPage: 10,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "CarList", "CarCounter", "selectManagers", "car"]),
        pageCount(){
            return Math.ceil((this.CarList?.length ?? 0) / this.itemsInPage);
        },
        PaginatedCarList(){
            return this.CarList.slice(this.page * this.itemsInPage, (this.page + 1) * this.itemsInPage);
        },
    },

    methods: {
        ...mapActions([
            "getCars",
            "setOrder",
            "getCarById",
            "findCar",
            "setCar",
            "statusCar",
            "fillCars",
            "find",
            "setSettings",
            "clearSettings",
            "showActivById",
        ]),

        filterStatus(status) {
            this.s.status = status;
            this.setSettings(this.s);
            this.statusCar(this.s);
        },

        filterByOwner(owner){
            this.s.client = owner;
            this.setSettings(this.s);
            this.statusCar(this.s);
        },

        cause(cause) {
            this.s.pay = cause;
            this.setSettings(this.s);
            this.findCar(this.s);
        },

        Clear() {
            console.log("Clear");
            this.clearSettings();
            this.fillCars();
        },

        close() {
            this.s.popup = false;
            this.setSettings(this.s);
            this.setCar(null);
            localStorage.removeItem("car");
        },
    },

    mounted() {
        this.getCars();
        window.DEBUG_CAR = this;
    },
};
</script>
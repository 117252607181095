import { createStore } from "vuex";
import API from "../services/api";
import moment from "moment";

import router from "@/router/router";
import calendar from "./modules/calendar";
import car from "./modules/car";
import check from "./modules/check";
import claim from "./modules/claim";
import client from "./modules/client";
import lead from "./modules/lead";
import money from "./modules/money";
import price from "./modules/price";
import profile from "./modules/profile";
import order from "./modules/order";
import salary from "./modules/salary";
import statistic from "./modules/statistic";
import task from "./modules/task";
import worker from "./modules/worker";

export default createStore({
    modules: {
        calendar,
        car,
        check,
        claim,
        client,
        lead,
        money,
        order,
        price,
        profile,
        salary,
        statistic,
        task,
        worker,
    },

    state: {
        alerts: [{ msg: "У вас нет доступа", color: "red" }],
        comments: [],
        s: {
            creator: "",
            act: null,
            status: null,
            noact: false,
            claim: null,
            parent_id: '',
            doc: null,
            findme: null,
            trans: null,
            category: null,
            type: null,
            pay: null,
            pay_status: null,
            manager: null,
            master: null,
            worker: null,
            month: moment().startOf("month").format("YYYY-MM-DD"),
            start: null,
            end: null,
            createStart: null,
            createEnd: null,
            tab: null,
            calendar: 1,
            popup: null,
            back: null,
            backpop: null,
            clear: null,
            loader: null,
            delay: "",
            client: null,
            activity: "all",
            carRange: "0",
            page_in_client_arhive: 0,
            page_size_in_client_arhive: 10,
            box: "",
            order_in_agregator: false,
            active_start: null,
            active_end: null,
            flag_no_active: null,
        },
        roles: ["", "Менеджер", "Логист", "Директор", "Инженер", "КК", "Клиент", "", "Admin", "Финансист", "Ассистент", "Бухгалтер"],
        roleNames: {
            Assistant: "Помощник",
            Manager: "Менеджер",
            Logist: "Логист",
            Studio: "Директор",
            Master: "Мастер",
            Check: "Контроль",
            Client: "Клиент",
            Buh: "Бухгалтер",
            Finance: "Финансист",
            Outsourcer: "Подрядчик",
        },
        select_workers: "",
        select_managers: "",
        select_masters: "",
        select_boxes: "",
        select_prices: "",
        select_zones: [],
        new_tahoban_front_link: "javascript:alert('Не укзано')",
        select_month: ["", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        select_monthMin: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
        select_weekday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        popup: false,
        loadForm: true,
        progressBar: "",
        result: "",
        activity: {},
    },

    mutations: {
        setAlert: (state, alert) => {
            state.alerts.unshift(alert);
        },
        setComments: (state, comments) => {
            state.comments = comments;
        },
        settings: (state, s) => {
            if(!state.s){
                state.s = s;
            }else{
                Object.keys(s).forEach(k=>state.s[k] = s[k]);
            }
        },
        setSelectWorkers: (state, workers) => {
            state.select_workers = workers;
        },
        setSelectManagers: (state, managers) => {
            state.select_managers = managers;

        },
        setSelectMasters: (state, masters) => {
            state.select_masters = masters;
        },
        setSelectBoxes: (state, boxes) => {
            state.select_boxes = boxes;
        },
        setSelectPrices: (state, prices) => {
            state.select_prices = prices;
        },
        setSelectZones: (state, zones) => {
            state.select_zones = zones;
        },
        setProgressBar: (state, progressBar) => {
            state.progressBar = progressBar;
        },
        setResult: (state, result) => {
            state.result = result;
        },
        setNewTahobanFrontLink: (state, link) => {
            state.new_tahoban_front_link = link;
        },
    },

    actions: {
        getSelects(ctx) {
            console.log("getSelects");
            API.GET("profile/selects").then((res) => {
                ctx.commit("setSelectWorkers", res.data.workers);
                ctx.commit("setSelectManagers", res.data.managers);
                ctx.commit("setSelectMasters", res.data.masters);
                ctx.commit("setSelectBoxes", res.data.boxes);
                ctx.commit("setSelectPrices", res.data.prices);
                ctx.commit("setSelectZones", res.data.zones);
                ctx.commit("setNewTahobanFrontLink", res.data.new_tahoban_front_link);
            });
        },

        setSettings(ctx, s) {            
            localStorage.setItem("s", JSON.stringify(s));
            ctx.commit("settings", s);
        },

        loadSettings(ctx) {
            // console.log("loadSettings", performance.now());
            let s = localStorage.getItem("s");
            if (s) {
                ctx.commit("settings", JSON.parse(s));
            }
        },

        clearSettings(ctx) {
            localStorage.removeItem("s");
            let s = {
                creator: "",
                act: null,
                status: null,
                noact: false,
                parent_id: '',
                claim: null,
                doc: null,
                findme: null,
                trans: null,
                category: "",
                type: null,
                pay: null,
                pay_status: null,
                dep: "",
                manager: "",
                master: null,
                worker: "",
                month: moment().format("YYYY-MM-DD"),
                start: null,
                end: null,
                createStart: null,
                createEnd: null,
                tab: null,
                calendar: 1,
                popup: null,
                back: null,
                backpop: null,
                clear: null,
                loader: null,
                delay: "",
                client: null,
                activity: "all",
                carRange: "0",
                page_in_client_arhive: 0,
                page_size_in_client_arhive: 10,
                box: "",
                order_in_agregator: false,
                active_start: null,
                active_end: null,
                flag_no_active: null,
            };
            ctx.dispatch("setSettings", s);
        },

        setLoader(ctx, loader) {
            // console.log("setLoader", performance.now());
            ctx.state.s.loader = loader;
            ctx.dispatch("setSettings", ctx.state.s);
        },

        showPopup(ctx, popup = false) {
            if (popup) {
                ctx.state.s.popup = popup;
            } else {
                ctx.state.s.popup = true;
            }
            ctx.dispatch("setSettings", ctx.state.s);
        },

        closePopup(ctx) {
            ctx.state.s.popup = null;
            ctx.dispatch("setSettings", ctx.state.s);
        },

        // вернуть обратно и открыть popup если нужно (из backpop)
        goBack(ctx) {
            if (ctx.state.s.back) {
                ctx.state.s.popup = ctx.state.s.backpop;
                router.push(ctx.state.s.back);
                ctx.state.s.back = null;
                ctx.state.s.backpop = null;
                ctx.dispatch("setSettings", ctx.state.s);
            } else {
                ctx.state.s.popup = null;
                ctx.dispatch("setSettings", ctx.state.s);
            }
        },

        pluralize(n, words) {
            let idx;
            if (n % 10 === 1 && n % 100 !== 11) {
                idx = 0; // many
            } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
                idx = 1; // few
            } else {
                idx = 2; // one
            }
            return words[idx] || "";
        },

        pickMonth(ctx, date) {
            ctx.state.s.month = moment(date).format("YYYY-MM-DD");
            ctx.commit("settings", ctx.state.s);
        },

        setTab(ctx, tab) {
            ctx.state.s.tab = tab;
            localStorage.setItem("s", JSON.stringify(ctx.state.s));
            ctx.commit("settings", ctx.state.s);
        },

        getComments(ctx, formData) {
            API.GET("/ajax/comments", formData).then((res) => {
                let key = formData.type + formData.id;
                ctx.state.comments[key] = res.data;
                ctx.commit("setComments", ctx.state.comments);
            });
        },

        addComment(ctx, data) {
            console.log("formData key:", data.key);
            API.POST("/ajax/add-comment", data.formData).then((res) => {
                ctx.state.comments[data.key] = res.data;
                ctx.commit("setComments", ctx.state.comments);
            });
        },

        delComment(ctx, data) {
            ctx.state.comments[data.key] = ctx.state.comments[data.key].filter((t) => t !== data.comment);
            API.POST("/ajax/del-comment", data.formData);
        },

        Upload(ctx, data) {
            ctx.state.loadForm = false;
            ctx.commit("setResult", null);
            // console.log(data);
            let formData = new FormData();
            for (var i = 0; i < data.e.target.files.length; i++) {
                let file = data.e.target.files[i];

                let ext = "не определилось";
                let parts = file.name.split(".");
                if (parts.length > 1) {
                    ext = parts.pop();
                }
                if (!["jpeg", "jpg", "png", "bmp", "pdf"].includes(ext)) {
                    ctx.commit("setResult", "Неправильный формат. Загрузите .jpg .png .pdf");
                    ctx.state.loadForm = true;
                    return;
                }

                formData.append("picture[" + i + "]", file);
            }

            formData.append("objectName", data.type);
            formData.append(data.type, data.id);
            formData.append("status", data.status);

            return API.POST("ajax/img", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: function (progressEvent) {
                    ctx.state.progressBar = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                }.bind(ctx),
            })
                .then((response) => {
                    console.log(response);

                    console.log("data.item ", data.item);
                    if (data.item == "profile") {
                        console.log("picture ", response.data.picture[0].img);
                        console.log("profile ", ctx.rootState.profile.profile);
                        ctx.rootState.profile.profile.picture = response.data.picture[0].img;
                        ctx.commit("profile", ctx.rootState.profile.profile);
                        return;
                    }

                    if (!data.item.images) {
                        data.item.images = [];
                    }
                    response.data.picture.forEach((elem) => {
                        data.item.images.push(elem);
                    });

                    ctx.state.loadForm = true;
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    throw error;
                });
        },

        delImg(ctx, data) {
            console.log("delImg " + data.img);
            if(data?.item?.images instanceof Array){
                data.item.images = data.item.images.filter((t) => t !== data.img);
            }
            let formData = new FormData();
            formData.append("id", data.img.id);
            formData.append("oid", data.img.oid);
            API.POST("ajax/del-img", formData);
        },
    },

    getters: {
        alerts(state) {
            return state.alerts;
        },
        s(state) {
            return state.s;
        },
        roles(state) {
            return state.roles;
        },
        roleNames(state) {
            return state.roleNames;
        },
        comments(state) {
            return state.comments;
        },
        selectWorkers(state) {
            return state.select_workers;
        },
        selectManagers(state) {
            return state.select_managers;
        },
        allMasters(state) {
            return state.select_masters;
        },
        selectMasters(state) {
            if (state.select_masters) {
                return state.select_masters.filter((m) => parseInt(m.status) > 0);
            }
        },
        
        selectBoxes(state) {
            return state.select_boxes;
        },
        selectPrices(state) {
            return state.select_prices;
        },
        selectZones(state) {
            return state.select_zones;
        },
        selectMonth(state) {
            return state.select_month;
        },
        selectMonthMin(state) {
            return state.select_monthMin;
        },
        selectWeekDay(state) {
            return state.select_weekday;
        },
        popup(state) {
            return state.popup;
        },
        loadForm(state) {
            return state.loadForm;
        },
        progressBar(state) {
            return state.progressBar;
        },
        result(state) {
            return state.result;
        },
        newTahobanFrontLink(state){
            return state.new_tahoban_front_link;
        }

    },
});

<template>
  <div
    class="mt-4 bg-white rounded-lg flex flex-wrap justify-around items-center"
  >
    <a
      :class="{ 'text-green-500 font-semibold': tabReport == 1 }"
      class="link p-4 uppercase"
      @click="tabReport = 1"
    >
      Длина сделки
    </a>
    <a
      :class="{ 'text-green-500 font-semibold': tabReport == 2 }"
      class="link p-4 uppercase"
      @click="tabReport = 2"
    >
      Календарь менеджеров
    </a>
    <a
      :class="{ 'text-green-500 font-semibold': tabReport == 3 }"
      class="link p-4 uppercase"
      @click="tabReport = 3"
    >
      Активность менеджера
    </a>
  </div>

  <h2 class="help-for-monke" v-if="tabReport == null">Выберите отчет</h2>

  <ReportLength v-if="tabReport == 1" />
  <ReportCalendar v-if="tabReport == 2" />
  <ReportActivityManager v-if="tabReport == 3" />
  <!-- <ReportPlanTable v-if="tabReport == 4" /> -->
</template>

<script>
import ReportLength from "@/components/reports/ReportLength.vue";
import ReportCalendar from "@/components/reports/ReportCalendar.vue";
import ReportActivityManager from "@/components/reports/ReportActivityManager.vue";
// import ReportPlanTable from "@/components/reports/ReportPlanTable.vue";
export default {
  name: "ReportPage",
  components: {
    ReportLength,
    ReportCalendar,
    ReportActivityManager,
    // ReportPlanTable,
  },
  data() {
    return {
      tabReport: null,
    };
  },
};
</script>

<style scoped>
.help-for-monke {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #b5b5b5;
  font-weight: 600;
  font-size: 40px;
  line-height: normal;
  user-select: none;
}
</style>

<template>
  <div
    v-if="s.popup == 'clientedit'"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <ClientEdit @close="close" />
  </div>
  <div
    v-if="s.popup == 'clientedit'"
    @click="close"
    id="overlay"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
  ></div>

  <div
    class="mt-4 bg-white rounded-lg flex flex-wrap justify-around items-center"
  >
    <a class="link p-4 uppercase" @click="Tab('ClientInfo')"> Инфо </a>
    <a class="link p-4 uppercase" @click="Tab('ClientCompany')">
      Компании <sup>{{ ClientTabsCounts?.companies }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientContact')">
      Контакты <sup>{{ ClientTabsCounts?.contacts }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientCar')">
      ТС <sup>{{ ClientTabsCounts?.cars }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientOrder')">
      Заказы <sup>{{ ClientTabsCounts?.orders }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientActive')">
      Активации <sup>{{ ClientTabsCounts?.activs }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientClaim')">
      Рекламации <sup>{{ ClientTabsCounts?.claims }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientTask')">
      Задачи <sup>{{ ClientTabsCounts?.tasks }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientDoc')">
      Договоры <sup>{{ ClientTabsCounts?.docs }}</sup>
    </a>

    <a class="link p-4 uppercase" @click="Tab('ClientTouches')">
      Касания <sup>{{ ClientTabsCounts?.touches }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientLeads')">
      Лиды <sup>{{ ClientTabsCounts?.leads }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientBids')">
      Заявки <sup>{{ ClientTabsCounts?.bids }}</sup>
    </a>
    <a class="link p-4 uppercase" @click="Tab('ClientInvoices')">
      Счета <sup>{{ ClientTabsCounts?.invoices }}</sup>
    </a>
    <!-- <a v-if="['Studio'].includes(role)" class="link p-4 uppercase" @click="Tab('ClientManager')">Менеджеры</a> -->
    <!-- <a class="link p-4 uppercase" @click="Tab('ClientHistory')">История</a> -->
  </div>

  <template v-if="client?.id">
    <div class="flex max-xl:flex-col justify-center">
      <div class="w-[400px] my-4 max-xl:mx-auto max-xl:max-w-full">
        <div class="bg-white rounded-lg">
          <div class="p-5 v-bbgray">
            <div class="flex items-center relative">
              <span
                v-if="ClientUnfilled && ClientUnfilled != null"
                class="min-w-[4rem] w-16 h-16 overflow-hidden relative mr-4 bg-status-7 flex items-center justify-center"
              >
                <span class="avatar overflow-hidden">
                  <svg class="fill-white w-8 h-8">
                    <use xlink:href="@/assets/i.svg#status-11"></use>
                  </svg>
                </span>
              </span>
              <template v-else>
                <div
                  class="rounded-full h-11 min-w-[2.75rem] w-11 bg-green-500 flex items-center justify-center mr-4"
                >
                  <span class="w-6 h-6 flex-cc">
                    <IcoPic :pic="client.picture" :name="client.name" />
                  </span>
                </div>
              </template>
              <div class="flex flex-col">
                <span
                  class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                  >{{ client.code }}</span
                >
                <span class="w-90p font-latobold text-base flex-wrap">{{
                  client.name
                }}</span>
                <span v-if="client.terms == 1" class="text-green-500"
                  >Стандарт</span
                >
                <span v-if="client.terms == 2" class="text-sky-500"
                  >Спецусловия</span
                >
                <span v-if="client.terms == 3" class="text-rose-500"
                  >ГосКонтракт</span
                >
              </div>
              <div
                v-if="client.vip"
                class="absolute top-1 -right-5 h-14 overflow-hidden max-w-[50%] px-6 py-3"
              >
                <span
                  id="timer"
                  class="text-white z-20 relative text-lg font-latobold"
                  >VIP</span
                >
                <svg class="absolute top-0 -left-8 h-full fill-status-6">
                  <use xlink:href="@/assets/bg.svg#bg-timer"></use>
                </svg>
              </div>
            </div>
          </div>

          <div
            v-if="ClientUnfilled && ClientUnfilled != null"
            class="p-5 v-bbgray"
          >
            <h4
              v-if="ClientUnfilled.client"
              class="my-2 text-gray-400 uppercase font-latobold"
            >
              ЗАПОЛНИТЬ У СЕБЯ
            </h4>
            <div v-if="ClientUnfilled.client != null">
              <div
                v-if="ClientUnfilled.client.full"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Полное имя</span>
              </div>

              <div
                v-if="ClientUnfilled.client.address"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Адрес</span>
              </div>

              <div
                v-if="ClientUnfilled.client.phone"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Телефон</span>
              </div>

              <div
                v-if="ClientUnfilled.client.email"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Электронная почта</span>
              </div>

              <div
                v-if="ClientUnfilled.client.utm"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Канал привлечения</span>
              </div>
            </div>

            <h4
              v-if="ClientUnfilled.no_firm"
              class="my-2 text-gray-400 uppercase font-latobold"
            >
              Компания
            </h4>
            <div v-if="ClientUnfilled.no_firm" class="flex items-center my-1">
              <span
                class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
              >
                <svg class="w-4 h-4 fill-white">
                  <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                </svg>
              </span>
              <span class="font-latobold">Нет компаний</span>
            </div>

            <div v-if="ClientUnfilled.yur_face">
              <div
                v-if="
                  ClientUnfilled.yur_face.name ||
                  ClientUnfilled.yur_face.full ||
                  ClientUnfilled.yur_face.director ||
                  ClientUnfilled.yur_face.osnovanie ||
                  ClientUnfilled.yur_face.uraddress ||
                  ClientUnfilled.yur_face.address ||
                  ClientUnfilled.yur_face.inn ||
                  ClientUnfilled.yur_face.kpp ||
                  ClientUnfilled.yur_face.okpo ||
                  ClientUnfilled.yur_face.ogrn
                "
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Реквизиты Юр. Лица</span>
              </div>
            </div>

            <div v-if="ClientUnfilled.phys_face">
              <div
                v-if="
                  ClientUnfilled.phys_face.passserial ||
                  ClientUnfilled.phys_face.passnum ||
                  ClientUnfilled.phys_face.passdate ||
                  ClientUnfilled.phys_face.passwho ||
                  ClientUnfilled.phys_face.passcode
                "
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Реквизиты Физ. Лица</span>
              </div>
            </div>

            <div v-if="ClientUnfilled.IP">
              <div
                v-if="
                  ClientUnfilled.IP.name ||
                  ClientUnfilled.IP.full ||
                  ClientUnfilled.IP.director ||
                  ClientUnfilled.IP.osnovanie ||
                  ClientUnfilled.IP.uraddress ||
                  ClientUnfilled.IP.address ||
                  ClientUnfilled.IP.inn ||
                  ClientUnfilled.IP.ogrn
                "
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center rounded-full items-center overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Реквизиты Физ. Лица</span>
              </div>
            </div>

            <h4
              v-if="ClientUnfilled.contact"
              class="text-gray-400 uppercase font-latobold my-2"
            >
              Контакты
            </h4>
            <div v-if="ClientUnfilled.contact">
              <div
                v-if="ClientUnfilled.contact.responsible_ts"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center items-center rounded-full overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Ответственный за тс</span>
              </div>
              <div
                v-if="ClientUnfilled.contact.accounting"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center items-center rounded-full overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">Бухгалтерия</span>
              </div>
            </div>

            <!-- <div v-if="CheckLk < 1" class="flex items-center my-1">
              <span
                class="w-6 h-6 bg-status-7 flex justify-center items-center rounded-full overflow-hidden mr-2"
              >
                <svg class="w-4 h-4 fill-white">
                  <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                </svg>
              </span>
              <span class="font-latobold">Кабинет клиента</span>
            </div> -->

            <div v-if="ClientUnfilled.client">
              <h4
                v-if="ClientUnfilled.client.ts"
                class="my-2 text-gray-400 uppercase font-latobold"
              >
                ТС
              </h4>
              <div
                v-if="ClientUnfilled.client.ts"
                class="flex items-center my-1"
              >
                <span
                  class="w-6 h-6 bg-status-7 flex justify-center items-center rounded-full overflow-hidden mr-2"
                >
                  <svg class="w-4 h-4 fill-white">
                    <use xlink:href="@/assets/i.svg#ico-no-line"></use>
                  </svg>
                </span>
                <span class="font-latobold">ТС Клиента</span>
              </div>
            </div>
          </div>

          <div
            v-if="client.address || client.phone"
            class="p-5 flex flex-col v-bbgray"
          >
            <div v-if="client.address" class="mb-4">
              <div class="flex">
                <svg class="w-5 h-5 fill-gray-400 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-address"></use>
                </svg>
                <p class="pr15">
                  <b>Адрес: </b>{{ client?.city_id }}, {{ client.address }}
                </p>
              </div>
            </div>
            <div v-if="client.phone" class="">
              <div class="flex">
                <svg class="w-5 h-5 fill-gray-400 mr-2">
                  <use xlink:href="@/assets/i.svg#ico-phone"></use>
                </svg>
                <p class="pr15"><b>Телефон: </b>{{ client.phone }}</p>
              </div>
            </div>
          </div>

          <div class="p-5 v-bbgray">
            <template v-if="client.manager_id">
              <div class="flex group relative">
                <a class="flex items-center cursor-pointer group">
                  <template v-for="m in selectManagers" :key="m">
                    <template v-if="m.uid == client.manager_id">
                      <div
                        class="min-w-[1.5rem] w-11 h-11 rounded-full mr-3 overflow-hidden bg-green-500"
                      >
                        <IcoPic :pic="m.picture" :name="m.name" />
                      </div>
                      <div
                        class="flex flex-col justify-center overflow-hidden pr-8"
                      >
                        <span
                          class="text-[10px] text-gray-400 leading-none uppercase mb-1"
                          >Менеджер</span
                        >
                        <span
                          class="font-latobold text-base group-hover:text-green-500 transition-colors duration-300"
                          >{{ m.secondname }} {{ m.name }}</span
                        >
                      </div>
                    </template>
                  </template>
                </a>
                <div
                  v-if="role == 'Studio'"
                  class="absolute top-1/2 -translate-y-1/2 right-1 opacity-0 group-hover:opacity-100 transition-all duration-150"
                >
                  <a class="cursor-pointer" @click="delClientManager">
                    <svg
                      class="fill-gray-400 w-4 h-4 cursor-pointer hover:fill-rose-500 transition-colors duration-150"
                    >
                      <use xlink:href="@/assets/i.svg#ico-no"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="b cred">Менеджер не назначен!</p>
            </template>
          </div>

          <div class="flex p-5 justify-between">
            <!-- {{ profile.uid }} -->
            <!-- {{ client.manager_id }} -->
            <template
              v-if="
                profile.uid == client.manager_id ||
                ['Assistant', 'Studio'].includes(role)
              "
            >
              <a
                class="v-btn bg-sky-500 hover:bg-sky-400 transition-colors duration-150"
                @click="editClient, (s.popup = 'clientedit')"
              >
                <svg class="fill-white w-4 h-4 mr-3">
                  <use xlink:href="@/assets/i.svg#ico-edit"></use>
                </svg>
                <span class="v-btn_text">ИЗМЕНИТЬ</span>
              </a>
            </template>
            <a
              v-if="
                (profile.uid == client.manager_id || role == 'Studio') &&
                !client.del
              "
              @click="delClient(client)"
              class="v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
            >
              <svg class="fill-white w-4 h-4 mr-3">
                <use xlink:href="@/assets/i.svg#ico-delete"></use>
              </svg>
              <span class="v-btn_text">УДАЛИТЬ</span>
            </a>
            <a
              v-if="
                (profile.uid == client.manager_id || role == 'Studio') &&
                client.del
              "
              @click="undelClient(client)"
              class="v-btn bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
            >
              <svg class="fill-white w-4 h-4 mr-3">
                <use xlink:href="@/assets/i.svg#ico-delete"></use>
              </svg>
              <span class="v-btn_text">ВЕРНУТЬ</span>
            </a>
            <a
              v-if="
                (profile.uid == client.manager_id || role == 'Studio') &&
                (client.activity == 'passive' || client.activity == '')
              "
              @click="dieClient(client)"
              class="v-btn bg-black hover:bg-rose-400 transition-colors duration-150"
            >
              <svg class="fill-white w-4 h-4 mr-3">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
              <span class="v-btn_text">В МЁРТВЫЕ</span>
            </a>
            <a
              v-if="
                (profile.uid == client.manager_id || role == 'Studio') &&
                client.activity == 'dead'
              "
              @click="undieClient(client)"
              class="v-btn bg-green-500 hover:bg-rose-400 transition-colors duration-150"
            >
              <svg class="fill-white w-4 h-4 mr-3">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
              </svg>
              <span class="v-btn_text">ВОСТАНОВИТЬ</span>
            </a>
          </div>
        </div>

        <div class="p-4 mt-4 v-bbgray bg-white rounded-lg">
          <div class="flex items-center pt-0 pb-0 justify-between">
            <template v-if="['Manager', 'Studio', 'Check'].includes(role)">
              <a
                class="v-btn bg-green-500 hover:bg-green-400 mx-auto"
                @click="NewTouch()"
              >
                <svg class="fill-white w-16px h-16px mr-3 rotate-45">
                  <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <span class="v-btn_text">Новое касание</span>
              </a>
            </template>
          </div>
        </div>

        <div class="p-5 mt-4 v-bbgray bg-white rounded-lg">
          <h3 class="text-base font-latobold mb-2">Комментарии</h3>
          <CommentList
            :id="parseInt(client.id)"
            :iid="parseInt(client.iid)"
            type="client"
          />
        </div>
      </div>

      <div class="flex-1 my-4 ml-4 max-xl:ml-0">
        <div>
          <component :is="activeComponent"></component>
        </div>
      </div>
    </div>
  </template>

  <div v-else class="py-2 flex justify-center">
    <img src="https://api.tahocrm.ru/images/loader.svg" width="44" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CommentList from "../components/CommentList.vue";
import ClientInfo from "../components/ClientInfo";
import ClientEdit from "../components/client/ClientEdit";
import ClientCompany from "../components/ClientCompany";
import ClientContact from "../components/ClientContact";
import ClientCar from "../components/ClientCar";
import ClientOrder from "../components/ClientOrder";
import ClientActive from "../components/ClientActive";
import ClientClaim from "../components/ClientClaim";
import ClientDoc from "../components/ClientDoc";
import ClientInvoices from "../components/ClientInvoices";
import ClientTouches from "../components/ClientTouches";
import ClientLeads from "../components/ClientLeads";
import ClientBids from "../components/ClientBids";

import ClientTask from "../components/ClientTask";
import IcoPic from "../components/ui/IcoPic.vue";

export default {
  name: "ClientView",

  components: {
    CommentList,
    ClientInfo,
    ClientEdit,
    ClientCompany,
    ClientContact,
    ClientCar,
    ClientOrder,
    ClientActive,
    ClientClaim,
    ClientDoc,
    ClientInvoices,
    ClientTouches,
    ClientLeads,
    ClientTask,
    ClientBids,
    IcoPic,
  },

  computed: {
    ...mapGetters([
      "s",
      "profile",
      "role",
      "roles",
      "client",
      "selectManagers",
      "ClientCompanies",
      "ClientContacts",
      "ClientCars",
      "ClientOrders",
      "ClientClaims",
      "ClientUnfilled",
      "ClientActivs",
      "ClientTasks",
      "ClientTabsCounts",
    ]),

    activeComponent() {
      if (this.s.tab) {
        return this.s.tab;
      }
      return "ClientInfo";
    },

    CheckClient() {
      let b = 0;
      if (
        this.client.name &&
        this.client.full &&
        this.client.business &&
        this.client.ts &&
        this.client.terms &&
        this.client.nds &&
        this.client.address &&
        this.client.phone &&
        this.client.email &&
        this.client.utm
      ) {
        b = 1;
      }
      return b;
    },

    CheckTsCount() {
      let b = 0;
      if (this.client.ts) {
        b = 1;
      }
      return b;
    },

    CheckCompany() {
      let b = 0;
      if (this.ClientCompanies) {
        this.ClientCompanies.forEach((e) => {
          console.log(e.id);
          if (
            e.firm == 1 &&
            e.name &&
            e.full &&
            e.director &&
            e.osnovanie &&
            e.uraddress &&
            e.address &&
            e.inn &&
            e.kpp &&
            e.okpo &&
            e.ogrn &&
            e.banks.length > 0
          ) {
            b = 1;
          }
          if (
            e.firm == 3 &&
            e.name &&
            e.full &&
            e.director &&
            e.osnovanie &&
            e.uraddress &&
            e.address &&
            e.inn &&
            e.ogrn &&
            e.banks.length > 0
          ) {
            b = 1;
          }
          if (
            e.firm == 2 &&
            e.passserial &&
            e.passnum &&
            e.passdate &&
            e.passwho &&
            e.passcode
          ) {
            b = 1;
          }
        });
      }
      return b;
    },

    CheckContactTs() {
      let b = 0;
      if (this.ClientContacts) {
        this.ClientContacts.forEach((e) => {
          if ([1, 2, 4].includes(parseInt(e.role)) && e.phone && e.email) {
            b = 1;
          }
        });
      }
      return b;
    },

    CheckContactBuh() {
      let b = 0;
      if (this.ClientContacts) {
        this.ClientContacts.forEach((e) => {
          if (e.role == 6 && e.phone && e.email) {
            b = 1;
          }
        });
      }
      return b;
    },

    CheckLk() {
      let b = 1;
      if (this.ClientContacts) {
        this.ClientContacts.forEach((e) => {
          if (e.lk?.uid) {
            b = 1;
          }
        });
      }
      return b;
    },

    CheckTs() {
      let b = 0;
      if (this.ClientCars) {
        this.ClientCars.forEach((e) => {
          if (e.mark && e.num) {
            b = 1;
          }
        });
      }
      return b;
    },
  },

  methods: {
    ...mapActions([
      "getClient",
      "addTouch",
      "setClient",
      "clearClientTabs",
      "setTab",
      "delClient",
      "undelClient",
      "delClientManager",
      "dieClient",
      "undieClient",
    ]),

    NewTouch() {
      if (this.client.manager_id == null) {
        alert("Нельзя создать касание, если у клиента нет менеджера!");
      } else {
        let formData = new FormData();
        formData.append("client_id", this.client.id);
        this.addTouch(formData);
      }
    },
    Tab(t) {
      this.setTab(t);
    },

    close() {
      this.s.popup = false;
    },
  },

  mounted() {
    window.DEBUG_CLIENT_VIEW = this;
    this.getClient();
  },

  unmounted() {
    // this.setClient(null);
    // this.clearClientTabs();
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

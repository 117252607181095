<template>
    <div
        class="report-orders-modal w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen max-l:w-11/12 max-l:max-w-11/12"
    >
        <a
            class="flex items-center bg-gray-500 px-4 py-2 absolute top-0 right-0 rounded-close cursor-pointer hover:bg-gray-400 transition-colors duration-150 z-30"
            @click="$emit('close')"
        >
            <span class="text-white mr-2">Закрыть</span>
            <svg class="fill-white w-4 h-4">
                <use xlink:href="@/assets/i.svg#ico-no"></use>
            </svg>
        </a>
        <slot></slot>
    </div>
    <div @click="$emit('close')" class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>
<script>
export default {
    name: "ReportModal",
}
</script>

<style scoped>
.report-orders-modal {
    max-height: 95vh;
    overflow-y: auto;
}
</style>

<template>
  <h1 class="mb-8 mt-6 font-latobold text-xl text-black">Активность</h1>

  <div
    v-if="showActivity"
    class="flex flex-wrap-report justify-between items-center"
  >
    <div
      class="flex bg-white w-fit py-3 px-8 relative rounded-[8px] items-center"
    >
      <p class="todayDateLabel">сегодня</p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '01'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} января
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '02'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} февраля
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '03'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} марта
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '04'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} апреля
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '05'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} мая
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '06'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} июня
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '07'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} июля
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '08'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} августа
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '09'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} сентября
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '10'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} октября
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '11'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} ноября
      </p>
      <p
        v-if="managerTasks.data_date.current_date.substr(5, 2) == '12'"
        class="todayDate mr-12 ml-16"
      >
        {{ managerTasks.data_date.current_date.substr(8, 2) }} декабря
      </p>
      <p class="workDays">
        <span>{{ managerTasks.data_date.passed_working_days }}</span> раб. дн из
        <span>{{ managerTasks.data_date.count_working_days }}</span>
      </p>
    </div>
  </div>

  <!-- ЗАДАЧИ -->

  <div
    v-if="showActivity"
    class="flex bg-white justify-between w-full py-4 px-8 mt-4 relative rounded-[8px] items-center"
  >
    <h2 class="titleReport">Задачи</h2>

    <div class="flex textTasksInner">
      <div class="relative flex items-center">
        <p
          @click="
            clearStats();
            showAllStats = !showAllStats;
          "
          class="cursor-pointer text-center mr-20"
        >
          <span class="text-gray-500">{{ managerTasks.other_stats.all }}</span>
          всего
        </p>
        <div
          v-if="showAllStats && managerTasks.other_stats.all_id.length > 0"
          class="absolute-list"
        >
          <p class="font-latobold text-center text-base text-gray-700">
            Все задачи
          </p>
          <a
            v-for="all_id in managerTasks.other_stats.all_id"
            :key="all_id"
            @click="
              showTask({ id: all_id });
              showAllStats = !showAllStats;
            "
            >№{{ all_id }}</a
          >
        </div>
      </div>
      <div class="relative flex items-center">
        <p
          @click="
            clearStats();
            showDoneStats = !showDoneStats;
          "
          class="cursor-pointer text-center mr-20"
        >
          <span class="text-green-600">{{
            managerTasks.other_stats.done
          }}</span>
          выполнено
        </p>
        <div
          v-if="showDoneStats && managerTasks.other_stats.done_id.length > 0"
          class="absolute-list"
        >
          <p class="font-latobold text-base text-gray-700">
            Выполненные задачи
          </p>
          <a
            v-for="done_id in managerTasks.other_stats.done_id"
            :key="done_id"
            @click="
              showTask({ id: done_id });
              showDoneStats = !showDoneStats;
            "
            >№{{ done_id }}</a
          >
        </div>
      </div>
      <div class="relative flex items-center">
        <p
          @click="
            clearStats();
            showCurrentStats = !showCurrentStats;
          "
          class="cursor-pointer text-center mr-20"
        >
          <span class="text-yellow-600">{{
            managerTasks.other_stats.current
          }}</span>
          на сегодня
        </p>
        <div
          v-if="
            showCurrentStats && managerTasks.other_stats.current_id.length > 0
          "
          class="absolute-list"
        >
          <p class="font-latobold text-base text-gray-700">Задачи на сегодня</p>
          <a
            v-for="current_id in managerTasks.other_stats.current_id"
            :key="current_id"
            @click="
              showTask({ id: current_id });
              showCurrentStats = !showCurrentStats;
            "
            >№{{ current_id }}</a
          >
        </div>
      </div>
      <div class="relative flex items-center">
        <p
          @click="
            clearStats();
            showPenaltyStats = !showPenaltyStats;
          "
          class="cursor-pointer"
        >
          <span class="text-rose-600">{{
            Number(managerTasks.other_stats.penalty)
          }}</span>
          просроченные
        </p>
        <div
          v-if="
            showPenaltyStats && managerTasks.other_stats.penalty_id.length > 0
          "
          class="absolute-list"
        >
          <p class="font-latobold text-base text-gray-700">
            Просроченые задачи
          </p>
          <a
            v-for="penalty_id in managerTasks.other_stats.penalty_id"
            :key="penalty_id"
            @click="
              showTask({ id: penalty_id });
              showPenaltyStats = !showPenaltyStats;
            "
            >№{{ penalty_id }}</a
          >
        </div>
      </div>
    </div>
  </div>

  <div v-if="showActivity" class="task-wrap">
    <div
      v-for="(task, indexTask) in managerTasks.activity"
      :key="task"
      class="bg-white py-4 rounded-[8px] text-center px-8 task-window w-full mb-[23px]"
    >
      <p class="w-full task-window-title">{{ task.title }}</p>
      <div class="flex text-task-block w-full justify-between items-center">
        <div class="relative w-1/3">
          <p
            @click="
              clearStats();
              taskBools[indexTask * 3].show = !taskBools[indexTask * 3].show;
            "
            class="cursor-pointer innerTextTB"
          >
            <span class="text-gray-500">{{ task.all }}</span>
            <br />
            всего
          </p>
          <div
            v-if="taskBools[indexTask * 3].show && task.all_id.length > 0"
            class="absolute-list"
          >
            <p class="font-latobold text-base text-gray-700">
              {{ task.title }} (всего)
            </p>
            <a
              v-for="all_id in task.all_id"
              :key="all_id"
              @click="
                showTask({ id: all_id });
                taskBools[indexTask * 3].show = !taskBools[indexTask * 3].show;
              "
              >№{{ all_id }}</a
            >
          </div>
        </div>
        <div class="relative w-1/3">
          <p
            @click="
              clearStats();
              taskBools[indexTask * 3 + 1].show =
                !taskBools[indexTask * 3 + 1].show;
            "
            class="cursor-pointer innerTextTB"
          >
            <span class="text-yellow-600">{{ task.current }}</span>
            <br />
            на сегодня
          </p>
          <div
            v-if="
              taskBools[indexTask * 3 + 1].show && task.current_id.length > 0
            "
            class="absolute-list"
          >
            <p class="font-latobold text-base text-gray-700">
              {{ task.title }} (на сегодня)
            </p>
            <a
              v-for="current_id in task.current_id"
              :key="current_id"
              @click="
                showTask({ id: current_id });
                taskBools[indexTask * 3 + 1].show =
                  !taskBools[indexTask * 3 + 1].show;
              "
              >№{{ current_id }}</a
            >
          </div>
        </div>
        <div class="relative w-1/3">
          <p
            @click="
              clearStats();
              taskBools[indexTask * 3 + 2].show =
                !taskBools[indexTask * 3 + 2].show;
            "
            class="cursor-pointer innerTextTB"
          >
            <span class="text-rose-600">{{ task.penalty }}</span>
            <br />
            просрочено
          </p>
          <div
            v-if="
              taskBools[indexTask * 3 + 2].show && task.penalty_id.length > 0
            "
            class="absolute-list"
          >
            <p class="font-latobold text-base text-gray-700">
              {{ task.title }} (просроченые)
            </p>
            <a
              v-for="penalty_id in task.penalty_id"
              :key="penalty_id"
              @click="
                showTask({ id: penalty_id });
                taskBools[indexTask * 3 + 2].show =
                  !taskBools[indexTask * 3 + 2].show;
              "
              >№{{ penalty_id }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ЗАДАЧИ -->
  <div
    v-if="s.popup == 'task'"
    id="popnew"
    class="w-3/4 bg-white fixed left-1/2 -translate-x-1/2 z-20 rounded-lg top-4 bottom-4 max-h-screen overflow-hidden max-l:w-11/12 max-l:max-w-11/12"
  >
    <TaskEdit @close="close" />
  </div>
  <div
    v-if="s.popup"
    @click="close"
    class="fixed bg-cyan-900 z-10 w-screen h-screen top-0 left-0 opacity-80"
    id="overlay"
  ></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TaskEdit from "@/components/TaskEdit";

export default {
  name: "ActivityPage",
  components: {
    TaskEdit,
  },
  data() {
    return {
      text_manager: "",
      choosenManager: {},
      managerList: [],

      sumOfTasks: {
        all: 0,
        done: 0,
        current: 0,
        penalty: 0,
      },

      showAllStats: false,
      showCurrentStats: false,
      showDoneStats: false,
      showPenaltyStats: false,

      taskBools: [
        {
          id: 1,
          show: false,
        },
        {
          id: 2,
          show: false,
        },
        {
          id: 3,
          show: false,
        },

        {
          id: 4,
          show: false,
        },
        {
          id: 5,
          show: false,
        },
        {
          id: 6,
          show: false,
        },

        {
          id: 7,
          show: false,
        },
        {
          id: 8,
          show: false,
        },
        {
          id: 9,
          show: false,
        },

        {
          id: 10,
          show: false,
        },
        {
          id: 11,
          show: false,
        },
        {
          id: 12,
          show: false,
        },

        {
          id: 13,
          show: false,
        },
        {
          id: 14,
          show: false,
        },
        {
          id: 15,
          show: false,
        },

        {
          id: 16,
          show: false,
        },
        {
          id: 17,
          show: false,
        },
        {
          id: 18,
          show: false,
        },

        {
          id: 19,
          show: false,
        },
        {
          id: 20,
          show: false,
        },
        {
          id: 21,
          show: false,
        },

        {
          id: 22,
          show: false,
        },
        {
          id: 23,
          show: false,
        },
        {
          id: 24,
          show: false,
        },
      ],

      indexTask: 0,

      dateFrom: null,
      dateTo: null,
      valueProgress: 100,
      showActivity: false,

      dataCall: {
        labels: [">5 мин", "1-5 мин.", "< 1 мин."],
        datasets: [
          {
            backgroundColor: ["#c6dfe4", "#90d2e0", "#90b0e0"],
            data: [20, 30, 50],
          },
        ],
      },
      dataCallToClient: {
        labels: ["Потерянным", "Вторичным", "Новым"],
        datasets: [
          {
            backgroundColor: ["#a9a9a9", "#90d2e0", "#90b0e0"],
            data: [520, 530, 1050],
          },
        ],
      },
      options1: {
        responsive: true,
        maintainAspectRatio: false,
      },
      options2: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    ...mapGetters(["s", "profile", "selectManagers", "managerTasks"]),
  },
  methods: {
    ...mapActions(["getActivityProfile", "setTask", "showTask", "closePopup"]),

    findManager() {
      console.log("findMaster", this.selectManagers);
      this.managerList = this.selectManagers.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.text_manager.toLowerCase()) !==
          -1
      );
    },
    clearStats() {
      this.showAllStats = false;
      this.showCurrentStats = false;
      this.showDoneStats = false;
      this.showPenaltyStats = false;

      this.taskBools = [
        {
          id: 1,
          show: false,
        },
        {
          id: 2,
          show: false,
        },
        {
          id: 3,
          show: false,
        },

        {
          id: 4,
          show: false,
        },
        {
          id: 5,
          show: false,
        },
        {
          id: 6,
          show: false,
        },

        {
          id: 7,
          show: false,
        },
        {
          id: 8,
          show: false,
        },
        {
          id: 9,
          show: false,
        },

        {
          id: 10,
          show: false,
        },
        {
          id: 11,
          show: false,
        },
        {
          id: 12,
          show: false,
        },

        {
          id: 13,
          show: false,
        },
        {
          id: 14,
          show: false,
        },
        {
          id: 15,
          show: false,
        },

        {
          id: 16,
          show: false,
        },
        {
          id: 17,
          show: false,
        },
        {
          id: 18,
          show: false,
        },

        {
          id: 19,
          show: false,
        },
        {
          id: 20,
          show: false,
        },
        {
          id: 21,
          show: false,
        },

        {
          id: 22,
          show: false,
        },
        {
          id: 23,
          show: false,
        },
        {
          id: 24,
          show: false,
        },
      ];
    },
    close() {
      this.closePopup();
    },
    setManager(manager) {
      this.choosenManager = manager;
    },
    formActivity() {
      this.showActivity = false;
      this.getActivityProfile({
        manager_id: this.choosenManager.id,
        date: this.dateFrom,
      });
      setTimeout(() => {
        this.showActivity = true;
        this.sumOfTasks.all = 0;
        this.sumOfTasks.done = 0;
        this.sumOfTasks.current = 0;
        this.sumOfTasks.penalty = 0;
        console.log(this.managerTasks.activity);
        for (let i = 0; i < this.managerTasks.activity.length; i++) {
          this.sumOfTasks.all += Number(this.managerTasks.activity[i].all);
          this.sumOfTasks.done += Number(this.managerTasks.activity[i].done);
          this.sumOfTasks.current += Number(
            this.managerTasks.activity[i].current
          );
          this.sumOfTasks.penalty += Number(
            this.managerTasks.activity[i].penalty
          );
        }
      }, 1000);
    },
  },
  async mounted() {
    var currentYear = new Date().getFullYear();
    var lastMonth = new Date().getMonth();
    var currentMonth = lastMonth + 1;

    if (currentMonth < 10) {
      this.dateFrom = "" + currentYear + "-0" + lastMonth;
    } else {
      this.dateFrom = "" + currentYear + "-" + lastMonth;
    }
    if (currentMonth < 10) {
      this.dateTo = "" + currentYear + "-0" + currentMonth;
    } else {
      this.dateTo = "" + currentYear + "-" + currentMonth;
    }
    this.formActivity();
  },
};
</script>

<style scoped>
@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

.todayDateLabel {
  color: rgb(125, 125, 125);

  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.todayDate {
  color: rgb(38, 45, 41);

  font-size: 23px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.workDays {
  color: rgb(125, 125, 125);

  font-size: 14px;
  line-height: 34px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
.workDays > span {
  font-size: 24px;
}
.numberOrders {
  color: rgb(142, 142, 142);

  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.labelOrders {
  color: rgb(125, 125, 125);
  font-size: 15px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}
.labelOrdersToday {
  color: rgb(38, 45, 41);
  font-size: 12.8px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}

.numberToday {
  color: rgb(142, 142, 142);
  font-size: 36.57px;
  font-weight: 900;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.numberLabelToday {
  color: rgb(38, 45, 41);

  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.planText {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  color: rgb(171, 171, 171);
}
.planText > span {
  color: rgb(80, 80, 80);
}

.titleReport {
  color: rgb(38, 45, 41);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #369;
  --secondary: rgb(232, 232, 232);
  --size: 220px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.green[role="progressbar"] {
  --primary: rgb(34, 197, 94);
}
.yellow[role="progressbar"] {
  --primary: rgb(254, 171, 26);
}
.red[role="progressbar"] {
  --primary: rgb(250, 52, 76);
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 0.75turn at 50% 100%,
    var(--primary) calc((var(--percentage) - 25) * 1% / 2),
    var(--secondary) calc((var(--percentage) - 25) * 1% / 2 + 0.1%)
  );
  mask: radial-gradient(at 50% 100%, white 55%, transparent 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 55%, #000 55.5%);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  counter-reset: percentage calc(var(--value));
  content: counter(percentage) "%";
  font-family: Helvetica, Arial, sans-serif;
  font-size: 45px;
  color: var(--primary);
  line-height: 55%;
  font-weight: bolder;
}

.month-report-top {
  padding: 12px 15px;
  border-radius: 4px;
}

.textTasks > p,
.textTasksInner p {
  color: rgb(38, 45, 41);
  font-size: 12.8px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  display: inherit;
}

.textTasks > p > span,
.textTasksInner p > span {
  font-size: 33px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: uppercase;
  margin-right: 15px;
}

.task-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-top: 12px;
}

.task-window-title {
  color: rgb(38, 45, 41);
  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.text-task-block .innerTextTB {
  font-size: 12.8px;
  font-weight: 700;
  line-height: 15px;
  color: rgb(38, 45, 41);
  text-transform: uppercase;
}
.text-task-block .innerTextTB > span {
  font-size: 36.57px;
  font-weight: 900;
  line-height: 54px;
  text-transform: uppercase;
}
.line100 {
  position: absolute;
  border: 1.09px solid rgb(0, 0, 0);
  width: 25px;
  top: 39px;
  right: 29px;
  z-index: 5;
  display: block;
  transform: rotate(-45deg);
}
.chart-title {
  color: rgb(38, 45, 41);
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.innerTextPie {
  bottom: 30%;
  right: 50%;
  height: 66px;
  transform: translateY(0%) translateX(50%);
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
}
.phoneAbsolute {
  bottom: 33%;
}
.innerValue {
  color: rgb(61, 61, 61);
  font-size: 10.33px;
  font-weight: 900;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.innerValue > span {
  font-size: 20px;
}
.innerValueMini {
  color: rgb(61, 61, 61);
  font-size: 12px;
  font-weight: 900;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.innerCaption {
  color: rgb(61, 61, 61);
  font-size: 7px;
  font-weight: 900;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
}
.button-form-report {
  border-radius: 8px;
  background: #22c55e;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 13.5px;
  text-transform: uppercase;
  padding: 15px 88px;
}

.absolute-list {
  right: 0px;
  box-shadow: 0px 0px 5px #00000060;
  max-height: 200px;
  overflow-y: auto;
}
.textTasksInner p.font-latobold {
  text-align: center;
}
</style>

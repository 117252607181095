<template>
    <div class="mb-9 mt-4 grid grid-cols-order__top place-content-center gap-x-14px gap-y-24px">
        <!-- Rhomb #1 -->
        <div
            v-if="['Manager', 'Studio', 'Check'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(1)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-1"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count[1] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-1 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
            >
                Новые
            </span>
        </div>

        <!-- Rhomb #2 -->
        <div
            v-if="['Logist', 'Studio', 'Check'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterClaim()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-19"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count["claim"] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-black group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Рекламация</span
            >
        </div>

        <!-- Rhomb #3 -->
        <div
            v-if="['Manager', 'Client'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterDoc()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#doc"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count["doc"] }}
                </h2>
                <div v-if="OrderCounter.vio['doc']" class="w-4 h-4 z-2 relative mx-auto">
                    <svg class="w-full h-full fill-white"><use xlink:href="@/assets/i.svg#status-11"></use></svg>
                </div>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-13 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Документы
            </span>
        </div>

        <!-- Rhomb #3 -->
        <div
            v-if="['Manager', 'Logist', 'Studio', 'Client'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterTrans()"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-edit"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count["trans"] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-12 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >МинТранс</span
            >
        </div>

        <!-- Rhomb #4 -->
        <!-- <div 
			class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
			@click="filterPayStatus"
		>
			<div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
				<svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
					<use xlink:href="@/assets/i.svg#bill-6"></use>
				</svg>
				<h2 
					class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" 
					v-if="OrderCounter">{{ OrderCounter.count[13] }}
				</h2>
				<svg class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-13 group-hover:w-full group-hover:h-full transition-all duration-300">
					<use xlink:href="@/assets/i.svg#bg-romb"></use>
				</svg>
			</div>
			<span class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold">Оплата</span>
		</div> -->

        <!-- Rhomb #5 -->
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(10)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-10"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count[10] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-10 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Отложены</span
            >
        </div>

        <!-- Rhomb #6 -->
        <div
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(3)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-3"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count[3] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-2 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Назначены</span
            >
        </div>

        <!-- Rhomb #7 -->
        <div
            v-if="['Logist', 'Studio', 'Master', 'Client'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(5)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#status-5"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count[5] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-5 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Завершены</span
            >
        </div>

        <div
            v-if="['Manager', 'Logist', 'Studio', 'Check', 'Finance'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(11)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-no"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count[11] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-7 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >ОТКАЗ</span
            >
        </div>

        <!-- Rhomb #8 -->
        <div
            v-if="['Logist', 'Master', 'Studio', 'Client', 'Check'].includes(role)"
            class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
            @click="filterStatus(6)"
        >
            <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                    <use xlink:href="@/assets/i.svg#ico-ok"></use>
                </svg>
                <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                    {{ OrderCounter.count[6] }}
                </h2>
                <svg
                    class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
                >
                    <use xlink:href="@/assets/i.svg#bg-romb"></use>
                </svg>
            </div>
            <span
                v-if="['Check'].includes(role)"
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Ждут КК</span
            >
            <span
                v-else
                class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                >Проверены ТО</span
            >
        </div>

        <!-- Rhomb #9 -->
        <template v-if="['Logist', 'Master', 'Client', 'Check'].includes(role)">
            <div
                class="relative cursor-pointer text-center uppercase group w-28 h-28 m-auto transition-all duration-300 ease-in-out"
                @click="filterStatus(8)"
            >
                <div class="overflow-hidden w-full h-full transition duration-300 ease-in-out">
                    <svg class="z-2 relative mt-5 w-5 h-5 fill-white mx-auto group-hover:w-6 group-hover:h-6 transition-all duration-300">
                        <use xlink:href="@/assets/i.svg#status-8"></use>
                    </svg>
                    <h2 class="text-2xl z-2 relative mt-1 text-white group-hover:text-3xl transition-all duration-300" v-if="OrderCounter">
                        {{ OrderCounter.count[8] }}
                    </h2>
                    <svg
                        class="absolute top-0 left-1/2 -translate-x-1/2 w-24 h-24 fill-status-6 group-hover:w-full group-hover:h-full transition-all duration-300"
                    >
                        <use xlink:href="@/assets/i.svg#bg-romb"></use>
                    </svg>
                </div>
                <span
                    class="duration-300 group-hover:bottom-0 group-hover:opacity-0 absolute left-0 w-full -bottom-4 opacity-100 text-status-20 font-latobold"
                    >KK</span
                >
            </div>
        </template>

        <!-- 
		<div v-if="role != 'Client'" class="round f_red btn show" @click="filterStatus(19)">
			<div class="item">
				<svg class="ico"><use xlink:href="@/assets/i.svg#status-11"></use></svg>
				<h2 v-if="OrderCounter">{{ OrderCounter.count[19] }}</h2>
				<svg class="romb"><use xlink:href="@/assets/i.svg#bg-romb"></use></svg>
			</div>
			<span class="name">Нарушения</span>
		</div>
	-->
    </div>

    <div class="pb-4 w-full flex flex-wrap items-center">
        <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
            <span class="w-full text-lg leading-none text-teal-700">
                {{ this.OrderList?.length }}
            </span>
        </h2>

        <div class="flex-2_0 mr-15px w-1/6 my-4">
            <input type="text" class="h-9 w-full px-3 py-2 text-sm bg-slate-50" placeholder="Название" v-model="s.findme" @input="findOrder(s)" />
        </div>

        <div class="flex items-center mr-4">
            <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
                <div class="relative mx-5px group" @click="filterPay([1, 2])">
                    <svg class="duration-300 fill-gray-400 w-6 h-6 hover:fill-status-5 cursor-pointer">
                        <use xlink:href="@/assets/i.svg#pay-1"></use>
                    </svg>
                    <span
                        class="absolute -top-7 left-1/2 -translate-x-2/4 text-xs uppercase bg-slate-100 px-2 py-1 group-hover:visible group-hover:opacity-100 opacity-0 invisible duration-150 transition ease-linear"
                        >Нал</span
                    >
                </div>
                <div class="relative mx-5px group" @click="filterPay([4, 7])">
                    <svg class="duration-300 fill-gray-400 w-6 h-6 hover:fill-status-5 cursor-pointer">
                        <use xlink:href="@/assets/i.svg#pay-2"></use>
                    </svg>
                    <span
                        class="absolute -top-7 left-1/2 -translate-x-2/4 text-xs uppercase bg-slate-100 px-2 py-1 group-hover:visible group-hover:opacity-100 opacity-0 invisible duration-150 transition ease-linear"
                        >Безнал</span
                    >
                </div>
            </template>

            <div class="relative mx-5px group" @click="filterCategory(1)">
                <svg class="duration-300 fill-gray-400 w-6 h-6 hover:fill-status-5 cursor-pointer">
                    <use xlink:href="@/assets/i.svg#nav-service"></use>
                </svg>
                <span
                    class="absolute -top-7 left-1/2 -translate-x-2/4 text-xs uppercase bg-slate-100 px-2 py-1 group-hover:visible group-hover:opacity-100 opacity-0 invisible duration-150 transition ease-linear"
                    >Тахограф</span
                >
            </div>
            <div class="relative mx-5px group" @click="filterCategory(2)">
                <svg class="duration-300 fill-gray-400 w-6 h-6 hover:fill-status-5 cursor-pointer">
                    <use xlink:href="@/assets/i.svg#ico-time"></use>
                </svg>
                <span
                    class="absolute -top-7 left-1/2 -translate-x-2/4 text-xs uppercase bg-slate-100 px-2 py-1 group-hover:visible group-hover:opacity-100 opacity-0 invisible duration-150 transition ease-linear"
                    >Мониторинг</span
                >
            </div>
        </div>

        <input class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" type="date" v-model="s.start" @change="findOrder(s)" />

        <input class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" type="date" v-model="s.end" @change="findOrder(s)" />

        <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
            <select class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" @change="findOrder(s)" v-model="s.manager">
                <option value="0" disabled>Менеджер</option>
                <template v-for="m in selectManagers" :key="m">
                    <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
                </template>
            </select>

            <select class="h-9 px-3 my-2 bg-slate-50 w-1/8 mr-4" @change="findOrder(s)" v-model="s.master">
                <option value="0" disabled>Инженер</option>
                <template v-for="m in selectMasters" :key="m">
                    <option :value="m.uid">{{ m.secondname }} {{ m.name }}</option>
                </template>
            </select>
        </template>

        <div class="flex py-4">
            <button class="v-btn bg-gray-500 hover:bg-gray-400 mr-4" v-if="s.clear">
                <span class="v-btn_text" @click="Clear">Сброс</span>
            </button>
            <!-- <template v-if="['Manager', 'Logist', 'Studio', 'Check'].includes(role)">
                <button class="v-btn bg-green-500 hover:bg-green-400">
                    <span class="v-btn_text flex items-center" @click="addOrder(null)">
                        <svg class="fill-white w-16px h-16px mr-3 rotate-45 group-hover:-rotate-45 duration-100 transition ease-linear">
                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                        Создать
                    </span>
                </button>
            </template> -->
        </div>
    </div>
    <select v-model="itemsInPage" style="padding: 7px;">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
    <Paginator :count="pageCount" v-model="page"/>

    <div v-if="OrderList?.length > 0" class="w-full bg-white v-bgray2 mb-8 overflow-hidden rounded-xl relative overflow-x-auto">
        <template v-for="item in PaginatedOrderList" :key="item">
            <a
                class="grid grid-cols-order px-4 py-2 gap-2 v-bbgray bg-teal-light odd:bg-white items-center transition duration-300 cursor-pointer hover:shadow-v1 max-2xl:grid-cols-order_m max-2xl:auto-rows-[50px]"
                :class="'status-' + item.status"
                @click="showOrder(item)"
            >
                <div class="flex w-16">
                    <template v-if="item.violate">
                        <div class="w-5 h-5">
                            <svg class="w-full h-full ml-2 fill-status-11"><use xlink:href="@/assets/i.svg#status-11"></use></svg>
                        </div>
                    </template>
                    <template v-else>
                        <div class="w-5 h-5 mr-2"><LineIco :status="parseInt(item.status)" /></div>
                    </template>

                    <template v-if="item.r_id > 0">
                        <div class="w-5 h-5">
                            <svg class="w-full h-full ml-2 fill-black"><use xlink:href="@/assets/i.svg#status-19"></use></svg>
                        </div>
                    </template>

                    <template v-if="item.act == 3">
                        <div class="w-5 h-5">
                            <svg class="w-full h-full ml-2 fill-status-6"><use xlink:href="@/assets/i.svg#status-6"></use></svg>
                        </div>
                    </template>
                    <template v-if="item.act == 4">
                        <div class="w-5 h-5">
                            <svg class="w-full h-full ml-2 fill-status-4"><use xlink:href="@/assets/i.svg#ico-mail"></use></svg>
                        </div>
                    </template>
                    <template v-if="item.act == 7">
                        <div class="w-5 h-5">
                            <svg class="w-full h-full ml-2 fill-status-7"><use xlink:href="@/assets/i.svg#status-7"></use></svg>
                        </div>
                    </template>
                    <template v-if="item.act == 8">
                        <div class="w-5 h-5">
                            <svg class="w-full h-full ml-2 fill-status-8"><use xlink:href="@/assets/i.svg#status-8"></use></svg>
                        </div>
                    </template>
                </div>

                <div class="mr-4 text-black w-10">#{{ item.iid }}</div>

                <template v-if="['Master'].includes(role)">
                    <div v-if="item.dates[0]?.date" class="flex items-center col-span-2 max-m:col-span-2">
                        <svg class="fill-status-6 min-w-[1rem] w-4 h-4 mr-2">
                            <use xlink:href="@/assets/i.svg#ico-time"></use>
                        </svg>
                        <div class="text-black text-sm">
                            {{ moment(item.dates[0]?.date).format("DD.MM") }}
                            {{ item.dates[0]?.start?.slice(0, 5) }}
                        </div>
                    </div>
                </template>

                <div v-else class="items-center col-span-2 max-m:col-span-2">
                    <div v-if="item.dates && item.dates[0]?.date" class="flex items-center mb-1">
                        <svg class="fill-status-6 min-w-[1rem] w-4 h-4 mr-2">
                            <use xlink:href="@/assets/i.svg#ico-time"></use>
                        </svg>
                        <div class="text-black text-sm">
                            {{ moment(item.dates[0]?.date).format("DD.MM") }}
                            {{ item.dates[0]?.start?.slice(0, 5) }}
                        </div>
                    </div>
                    <div class="flex items-center ml-2">
                        <svg class="fill-gray-400 w-2 h-2 mr-1 rotate-45">
                            <use xlink:href="@/assets/i.svg#ico-no"></use>
                        </svg>
                        <div class="text-gray-400 text-xs">
                            {{ moment(item.create_at).format("DD.MM.YYYY") }}
                        </div>
                    </div>
                </div>

                <template v-if="role == 'Client'">
                    <div class="items-center col-span-4">
                        <div class="mr-4 flex w-full h-full items-center v-gray font-latoblod-serif">
                            <span
                                class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
                                :class="'bg-status-' + item.status"
                                >{{ item.mark?.slice(0, 1) }}</span
                            >
                            {{ item.mark }}
                            <span v-if="item.num" class="text-slate-600 w-auto rounded-sm v-tsnum ml-2 px-2 py-1 text-base">
                                {{ item.num }}
                            </span>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <div v-if="item.status == 10" class="text-black mr-4 flex w-full h-full items-center col-span-4 max-m:col-span-4">
                        <span
                            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px] bg-status-20"
                            :class="{ '!bg-status-7': item.delay == 1, '!bg-status-10': item.delay == 2, '!bg-status-5': item.delay == 3 }"
                        >
                            {{ item.cname?.slice(0, 1) }}
                        </span>
                        <div class="flex flex-col justify-center overflow-hidden pr-4">
                            <span
                                class="text-[10px] leading-none"
                                :class="{ '!text-red-500': item.delay == 1, ' text-yellow-500': item.delay == 2, 'text-green-500': item.delay == 3 }"
                                >{{ delay[item.delay] }}</span
                            >
                            <span class="text-base font-semibold break-all max-h-10 leading-5">{{ item.cname }}</span>
                        </div>
                    </div>
                    <div v-else class="text-black mr-4 flex w-full h-full items-center col-span-4 max-m:col-span-4">
                        <span
                            class="text-white w-11 h-11 mr-4 text-xl leading-none rounded-full flex items-center justify-center min-w-[44px]"
                            :class="'bg-status-' + item.status"
                        >
                            {{ item.cname?.slice(0, 1) }}
                        </span>
                        <div class="flex flex-col justify-center overflow-hidden pr-4">
                            <!-- <span class="text-[10px] text-gray-400 leading-none">КЛИЕНТ</span> -->
                            <span class="text-base font-semibold break-all max-h-10 leading-5">{{ item.cname }}</span>
                        </div>
                    </div>
                </template>

                <div
                    v-if="['Manager', 'Logist', 'Master'].includes(role)"
                    class="text-black text-xs tex-left max-h-16 px-4 col-span-2 max-m:col-span-4"
                >
                    <span v-if="item.box">Бокс</span>
                    <span v-else>{{ item.address }}</span>
                </div>

                <template v-if="!['Manager', 'Logist'].includes(role)">
                    <div
                        v-if="item.mname"
                        class="font-latoblod-serif font-[16px] text-v-gray relative flex justify-start items-center col-span-2 max-m:col-span-2"
                    >
                        <div class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden" :class="'bg-status-' + item.status">
                            <IcoPic :pic="item.mpicture" :name="item.mname" />
                        </div>
                        <div class="flex flex-col justify-center overflow-hidden">
                            <span class="text-[10px] text-gray-400 leading-none uppercase">Менеджер</span>
                            <span class="text-black overflow-hidden">{{ item.mname }}</span>
                        </div>
                    </div>
                    <div v-else class="flex justify-start items-center col-span-2 max-m:col-span-2">
                        <span class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2">
                            <svg class="w-4 h-4 fill-white"><use xlink:href="@/assets/i.svg#ico-avatar"></use></svg>
                        </span>
                    </div>
                </template>

                <template v-if="role != 'Master'">
                    <div v-if="item.wname" class="font-latoblod-serif font-[16px] flex justify-start items-center col-span-2 max-m:col-span-2">
                        <div class="min-w-[1.5rem] rounded-full mr-3 overflow-hidden" :class="'bg-status-' + item.status">
                            <IcoPic :pic="item.wpicture" :name="item.wname" />
                        </div>
                        <div class="flex flex-col justify-center overflow-hidden">
                            <span class="text-[10px] text-gray-400 leading-none uppercase">Инженер</span>
                            <span class="text-black">{{ item.wname }}</span>
                        </div>
                    </div>
                    <div v-else class="flex justify-start items-center col-span-2 max-m:col-span-2">
                        <span class="w-11 h-11 bg-slate-200 flex justify-center rounded-full items-center overflow-hidden mr-2">
                            <svg class="w-4 h-4 fill-white"><use xlink:href="@/assets/i.svg#ico-avatar"></use></svg>
                        </span>
                    </div>
                </template>

                <template v-if="role != 'Client'">
                    <div class="min-w-[90px] v-gray font-latoblod-serif col-span-2 max-m:col-span-2">
                        <span
                            v-if="item.num"
                            class="block text-center text-slate-600 w-full min-w-full rounded-sm v-tsnum px-2 py-1 text-xs uppercase"
                        >
                            {{ item.num }}
                        </span>
                    </div>
                </template>
            </a>
        </template>
    </div>
    <div v-else-if="s.loader" class="absolute top-60 left-0 flex-cc w-full"><img src="https://api.tahocrm.ru/images/loader.svg" /></div>
    <div v-else class="absolute top-60 left-0 flex-cc w-full text-slate-300 text-2xl text-center">Нет данных</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import LineIco from "../components/ui/LineIco.vue";
import Paginator from "@/components/ui/Paginator";

export default {
    name: "OrderList",

    components: { LineIco, Paginator },

    data() {
        return {
            moment: moment,
            delay: { 1: "СРОЧНО", 2: "МЕНЕДЖЕР", 3: "КЛИЕНТ" },
            page: 0,
            itemsInPage: 10,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "OrderList", "OrderCounter", "countOrders", "selectManagers", "selectMasters"]),
        pageCount(){
            return Math.ceil((this.OrderList?.length ?? 0) / this.itemsInPage);
        },
        PaginatedOrderList(){
            return this.OrderList.slice(this.page * this.itemsInPage, (this.page + 1) * this.itemsInPage);
        },
    },

    methods: {
        ...mapActions(["getOrderArhive", "setOrder", "showOrder", "findOrder", "statusOrder", "find", "setSettings", "clearSettings", "fillOrders"]),

        filterStatus(status) {
            this.s.status = status;
            this.s.claim = "";
            this.s.doc = "";
            this.s.trans = "";
            this.setSettings(this.s);
            this.statusOrder(this.s);
        },

        filterPayStatus() {
            this.s.pay_status = 1;
            this.findOrder(this.s);
        },

        filterClaim() {
            this.s.status = "";
            this.s.claim = 1;
            this.setSettings(this.s);
            this.statusOrder(this.s);
        },

        filterDoc() {
            this.s.status = "";
            this.s.doc = 1;
            this.setSettings(this.s);
            this.statusOrder(this.s);
        },

        filterTrans() {
            this.s.status = "";
            this.s.trans = 1;
            this.setSettings(this.s);
            this.statusOrder(this.s);
        },

        filterPay(pay) {
            this.s.pay = pay;
            this.setSettings(this.s);
            this.findOrder(this.s);
        },

        filterCategory(category) {
            this.s.category = category;
            this.setSettings(this.s);
            this.findOrder(this.s);
        },

        Clear() {
            this.clearSettings();
            this.fillOrders();
        },
    },

    mounted() {
        this.getOrderArhive();
    },
};
</script>

<template>
    <div class="pb-4 w-full flex flex-wrap items-center">
        <h2 class="mr-15px h-9 min-w-[2.25rem] border border-teal-700 rounded-lg p-2 text-center my-4">
            <span class="w-full text-lg leading-none text-teal-700">
                {{ this.WorkerList?.length }}
            </span>
        </h2>

        <div class="add-wrap">
            <input type="text" class="add-input h-9 w-full px-3 py-2 text-sm bg-slate-50" placeholder="Название" v-model="s.findme" @input="findWorker(s)" />
            <button class="add-btn" id="modal-btn" v-if="hasAddUser" @click="startWorkerAdd=true">Добавить пользователя</button>
        </div>

        <div class="modal" v-show="startWorkerAdd">
  <div class="modal-content">
    <span class="close-btn" @click="startWorkerAdd=false">&times;</span>
   <div class="form">
      <div class="input-container ic2">
        <input
          id="firstname"
          class="input bg-slate-50 h-9 px-3 py-2 w-full v-bgray rounded-sm text-sm text-teal-700"
          type="text"
          :class="{ 'empty': !newWorkerData.secondname }"
          placeholder=" " v-model="newWorkerData.secondname"
        />
        <div class="cut"></div>
        <label for="firstname" class="placeholder">Фамилия</label>
      </div>
      <div class="input-container ic2">
        <input
          id="firstname"
          class="input"
          :class="{ 'empty': !newWorkerData.name }"
          type="text"
          placeholder=" "
          v-model="newWorkerData.name"
        />
        <div class="cut"></div>
        <label for="firstname" class="placeholder">Имя</label>
      </div>
     <div class="input-container ic2">
        <input
          id="otche"
          class="input"
          :class="{ 'empty': !newWorkerData.middlename }"
          type="text"
          placeholder=" "
          v-model="newWorkerData.middlename"
        />
        <div class="cut"></div>
        <label for="otche" class="placeholder" >Отчество</label>
      </div>
     <div class="input-container ic2">
        <input
          id="pseudo"
          class="input"
          :class="{ 'empty': !newWorkerData.username }"
          type="text"
          placeholder=" "
          v-model="newWorkerData.username"
        />
        <div class="cut"></div>
        <label for="pseudo" class="placeholder">Логин</label>
      </div>
      <div class="input-container ic2">
        <input
          id="lastname"
          class="input"
          :class="{ 'empty': !newWorkerData.phone }"
          type="text"
          placeholder=" "
          v-model="newWorkerData.phone"
        />
        <div class="cut"></div>
        <label for="lastname" class="placeholder">Телефон</label>
      </div>
      <div class="input-container ic2">
        <input
          id="email"
          class="input"
          :class="{ 'empty': !newWorkerData.email }"
          type="text"
          placeholder=" "
          v-model="newWorkerData.email"
        />
        <div class="cut cut-short"></div>
        <label for="email" class="placeholder">Email</label>
      </div>
     
     <div class="input-container ic2">
       <select id="role" class="input" :class="{ 'empty': !newWorkerData.role }" v-model="newWorkerData.role" >
         <option value="1">Менеджер</option>
         <option value="2">Логист</option>
         <option value="4">Инженер</option>
         <option value="5">Контроль качества</option>
         <option value="8">Бухгалтер</option>
       </select>
        <div class="cut cut-short"></div>
        <label for="role" class="placeholder">Роль</label>
      </div>
        
      <button :disabled="!isNewWorkerDataValid" type="text" class="submit" @click="tryAddNewWorker">Добавить</button>
    </div>
  </div>
  
</div></div>



    <div v-for="item in WorkerList" :key="item" class="grid grid-cols-worker-table px-4 py-2 v-bbgray items-center transition duration-300 cursor-pointer hover:shadow-v1 bg-teal-light odd:bg-white status-8" @click="showWorker(item)">
        <div class="flex items-center">
            <div class="min-w-[2.75rem] w-11 h-11 rounded-full overflow-hidden bg-green-500 text-lg text-white flex justify-center items-center mr-4">
                <img v-if="item.picture" class="w-full h-full object-cover" :src="'https://api.tahocrm.ru/uploads/users/' + item.picture" />
                <span v-else>{{ item.secondname.slice(0, 1) }}</span>
            </div>
            <span class="font-latobold text-base">{{ item.secondname }} {{ item.name }}</span>
        </div>

        <div>{{ item.phone }}</div>
        <span class="px-4 break-words">{{ item.email }}</span>

        <div>{{ item.raiting }}</div>
        <div>{{ item.a1 }}</div>
        <div>{{ item.a2 }}</div>
        <div>{{ item.a3 }}</div>

        <!-- <div v-for="ro in item.roles" :key="ro">
            {{ r[ro.item_name] }}
        </div> -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import API from "../services/api";
import authHeader from "../services/header";

export default {
    name: "PageWorker",

    data() {
        return {
            r: {
                Manager: '<svg class="mr10"><use xlink:href="@/assets/i.svg#ico-phone"></use></svg>Менеджер',
                Logist: '<svg class="mr10"><use xlink:href="@/assets/i.svg#status-3"></use></svg>Логист',
                Studio: '<svg class="mr10"><use xlink:href="@/assets/i.svg#nav-branches"></use></svg>Директор',
                Master: '<svg class="mr10"><use xlink:href="@/assets/i.svg#nav-masters"></use></svg>Инженер',
                Check: '<svg class="mr10"><use xlink:href="@/assets/i.svg#status-8"></use></svg>Контроль качества',
                Finance: '<svg class="mr10"><use xlink:href="@/assets/i.svg#stat-2"></use></svg>Финансист',
                Assistant: '<svg class="mr10"><use xlink:href="@/assets/i.svg#ico-avatar"></use></svg>Асистент',
                Buh: '<svg class="mr10"><use xlink:href="@/assets/i.svg#pay-2"></use></svg>Бухгалтер',
            },
            startWorkerAdd: false,
            newWorkerData: {},
            hasAddUser: true,
        };
    },

    computed: {
        ...mapGetters(["s", "profile", "role", "roles", "WorkerList"]),
        isNewWorkerDataValid(){
          return [
            this.newWorkerData.secondname,
            this.newWorkerData.name,
            this.newWorkerData.middlename,
            this.newWorkerData.username,
            this.newWorkerData.phone,
            this.newWorkerData.email,
            this.newWorkerData.role,

          ].every(Boolean);
        },
    },

    methods: {
        ...mapActions(["getWorkers", "showWorker", "findWorker", "addWorker"]),

        Clear() {
            this.clearSettings();
            this.findWorker(this.s);
        },
        showAddWorkerDialog(){
            //alert("Диалог");
            this.startWorkerAdd = true;
        },
        async tryAddNewWorker(){
            var formData = new FormData;
            formData.append('email', this.newWorkerData.email ?? '');
            formData.append('username', this.newWorkerData.username ?? '');
            formData.append('name', this.newWorkerData.name ?? '');
            formData.append('secondname', this.newWorkerData.secondname ?? '');
            formData.append('middlename', this.newWorkerData.middlename ?? '');
            formData.append('phone', this.newWorkerData.phone ?? '');
            formData.append('studio_id', 10);
            formData.append('role', this.newWorkerData.role);
            var data = await (await fetch(API.URL+'worker/create', {
                method: 'POST',
                body: formData,
                headers: authHeader()})).text();
            console.log(data);
            this.startWorkerAdd = false;
            this.getWorkers();
        }
    },

    mounted() {
        this.getWorkers();
        window.DEBUG_WORKER = this;
    },
};
</script>

<style scoped>
[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

[disabled]:active {
  background-color: #22c55e !important;
}
.add-wrap {
    display: flex;
    width: 100%;
}

.add-input {
    width: 100%;
    margin-right: 15px;
}

.add-btn {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
    height: 2.25rem;
    border: 1px solid #22c55e;
    color: #22c55e;
    min-width: 200px;
}

.modal {
  /*display: none;*/
  position: fixed; 
  overflow-y: auto;
  padding-top: 50px;
  padding-bottom: 100px;
  left: 0; 
  top: 0;
  width: 100%;
  height: 100%; 
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  position: relative; 
  background-color: transparent;
  padding: 20px; 
  margin: auto; 
  width: 320px;  
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  display: flex;
  justify-content: center;
}
.close-btn { 
  position: absolute;
  right: 0;
  top: 0;
  color: lightgray; 
  font-size: 24px;  
  font-weight: bold;
  cursor: pointer;
}
.close-btn:hover {
  color: darkgray;
}
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}
@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.form {
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  height: auto;
  padding: 20px;
  width: 320px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #22c55e;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}
.empty.input {border: 1px solid #ff2244 !important;}
.cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: auto;
}

.cut-short {
  width: auto;
}

.input {
  color: #000;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-40px) translateX(-15px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.submit {
  background-color: #22c55e;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #06b;
}

.modal .form select {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #22c55e;
    box-sizing: border-box;
    color: #65657b;
    font-size: 13px;
    height: 100%;
    outline: 0;
    padding: 4px 12px 4px;
    width: 100%;
}

.modal .form select option {

}

.modal .form select:placeholder {
    color: #eee;
}

</style>